import { schema } from 'normalizr';

export const reportCategory = new schema.Entity('category');
export const budget = new schema.Entity('budgets');
export const treasury = new schema.Entity('treasuries');
export const parcel = new schema.Entity('parcels');
export const identity = new schema.Entity('identities');
export const contract = new schema.Entity('contracts', {
   parcels: [parcel],
   identity,
});
export const accountEntry = new schema.Entity('accountEntries');
export const transaction = new schema.Entity('transactions');
export const contractDocument = new schema.Entity('contractDocuments');
export const contractFolder = new schema.Entity('contractFolders', {
   contractDocuments: [contractDocument],
});

export const coownershipDocument = new schema.Entity('coownershipDocuments');
export const coownershipFolder = new schema.Entity('coownershipFolders', {
   coownershipDocuments: [coownershipDocument],
});
export const user = new schema.Entity('users');
export const reportEvent = new schema.Entity('reportEvents', {
   author: user,
});
export const reportResolution = new schema.Entity('reportResolutions');
export const report = new schema.Entity('reports', {
   author: user,
   category: reportCategory,
   reportEvents: [reportEvent],
   reportResolution,
   witnesses: [user],
});
export const equipment = new schema.Entity('equipments');
export const place = new schema.Entity('places', {
   defaultIdentity: identity,
});
export const accountPlace = new schema.Entity('accountPlaces');
export const accountPlaceCode = new schema.Entity('accountPlaceCodes');
export const accountCode = new schema.Entity('accountCodes');
export const fiscalYear = new schema.Entity('fiscalYears');
export const agency = new schema.Entity('agencies', {
   places: [place],
});
export const conversation = new schema.Entity('conversations', {
   members: [user],
   agency,
});
export const message = new schema.Entity('messages', {
   author: user,
});
export const maintenanceContractDocument = new schema.Entity('maintenanceContractDocuments');
export const maintenanceContract = new schema.Entity('maintenanceContracts', {
   maintenanceContractDocuments: [maintenanceContractDocument],
});
export const maintenanceContractsCategory = new schema.Entity('maintenanceContractsCategories');
export const accountPlaceEntry = new schema.Entity('accountPlaceEntries');
export const providerAccountPlaceEntry = new schema.Entity('providerAccountPlaceEntries', {
   accountPlaceEntries: [accountPlaceEntry],
});
export const provider = new schema.Entity('providers');
export const repartitionKey = new schema.Entity('repartitionKeys');
export const accountCodeAccountPlaceEntry = new schema.Entity('accountCodeAccountPlaceEntries', {
   accountPlaceEntries: [accountPlaceEntry],
});
export const invoice = new schema.Entity('invoices');
export const invoiceGroup = new schema.Entity('invoicesGroups', {
   invoices: [invoice],
});
export const coownerAmount = new schema.Entity('coownerAmounts');
export const feedChunk = new schema.Entity(
   'feedChunks',
   {},
   {
      idAttribute: ({ data, dataType }) => `${dataType}-${data.id}${data.state ? `-${data.state}` : ''}`,
   }
);
export const notification = new schema.Entity('notifications');

export const zendeskTicket = new schema.Entity('zendeskTickets');
export const zendeskTicketComment = new schema.Entity('zendeskTicketComments');
export const moneyOrder = new schema.Entity('moneyOrder');
export const distributionList = new schema.Entity('distributionLists');

export const topicCategory = new schema.Entity('topicCategories');
export const topic = new schema.Entity('topics', {
   author: user,
   category: topicCategory,
});
export const post = new schema.Entity('posts');
export const termsVersion = new schema.Entity('termsVersions');
export const rssFeed = new schema.Entity('rssFeeds');
export const rssFeedChunk = new schema.Entity('rssFeedChunks');
export const vatCode = new schema.Entity('vatCodes', {}, { idAttribute: 'code' });
export const inseeCity = new schema.Entity('inseeCities', {}, { idAttribute: 'inseeCode' });
export const inseeWay = new schema.Entity('inseeWays', {}, { idAttribute: 'inseeCode' });
