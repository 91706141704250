import { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Route, Switch } from 'react-router-dom';
import { Container as RawContainer, spacing } from 'ui';
import { BoxSuffix, LinkButton } from 'ui/auth';
import { login, refreshToken, signup } from 'actions/user';
import { history } from 'store';
import { getParams } from 'utils';
import { ActivateForm, CheckYourMailBis, LoginForm, LostPasswordForm, RedirectionWarning } from 'components/AuthForms';
import { Sidebar, Wrapper as SecondWrapper } from 'ui/sidebar';
import logoSyndicOne from 'images/logo-syndic-one-colored.svg';
import logoSergic from 'images/logo-sergic.svg';
import logoEcopro from 'images/logo-ecopro-colored.svg';
import logoNeuf from 'images/logo-neuf-colored.svg';
import logoTradition from 'images/logo-tradition.svg';
import { SERGIC_OFFER } from '../constants';
import backgroundEseis from 'images/background-eseis.jpg';
import backgroundViva from 'images/background-viva.jpg';
import backgroundSyndicOne from 'images/background-syndic-one.jpg';
import backgroundEcopro from 'images/background-ecopro.jpg';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { checkEmail, register, sendForgottenPasswordEmail } from 'api/user';
import { withTranslation } from 'react-i18next';
import { LanguageSelector } from 'components/LanguageSelector';
import withSnacks from 'user/src/components/withSnacks';

export const Content = styled.div`
   flex: 1;
`;

export const Container = styled(RawContainer)`
   padding: ${spacing(3)};
   flex-direction: column;
   display: flex;
`;

export const Wrapper = styled.div`
   background-color: ${({ theme }) => theme.background};
   color: ${({ theme }) => theme.text};
   min-height: 100vh;
   position: relative;
   z-index: 1;
`;

export const Background = styled.div`
   background-image: url(${SERGIC_OFFER === 'VSY' || SERGIC_OFFER === 'VQC'
      ? backgroundViva
      : SERGIC_OFFER === 'DIR'
      ? backgroundSyndicOne
      : SERGIC_OFFER === 'ECO'
      ? backgroundEcopro
      : backgroundEseis});
   flex: 1;
   background-size: cover;
   background-position: center center;
`;

const LanguageContainer = styled.div`
   display: flex;
`;

export const getLogo = () => {
   switch (SERGIC_OFFER) {
      case 'ESE':
         return logoSergic;
      case 'VSY':
         return logoSergic;
      case 'VQC':
         return logoTradition;
      case 'ECO':
         return logoEcopro;
      case 'DIR':
         return logoSyndicOne;
      default:
         return logoNeuf;
   }
};

const getLogoAlternativeText = () => {
   switch (SERGIC_OFFER) {
      case 'ESE':
         return 'Sergic';
      case 'VSY':
      case 'VQC':
         return 'Sergic';
      case 'ECO':
         return 'e-copro';
      case 'DIR':
      default:
         return 'Syndic One';
   }
};

export const Logo = () => (
   <img src={getLogo()} alt={getLogoAlternativeText()} style={{ marginBottom: spacing(2), width: 180 }} />
);

class Auth extends Component {
   state = {
      loginPasswordType: 'text',
   };

   componentDidMount() {
      this.initialize();
   }

   componentDidUpdate() {
      if (this.props.user.isAuthenticated) {
         history.replace('/');
      }
   }

   initialize() {
      const { location, initialize } = this.props;
      const params = getParams(location);

      if (Boolean(params.email)) {
         initialize({ username: params.email, email: params.email });
      } else {
         setTimeout(() => {
            this.props.reset();
            this.setState({ loginPasswordType: 'password' });
         }, 0);
      }
   }

   handleSubmitSignupForm = e => {
      e.preventDefault();

      const { username } = get(this.props, 'form.values', {});
      this.props.signup(username).then(
         () =>
            history.push({
               pathname: `/email-envoye`,
               state: { email: username },
            }),
         err => console.error(err)
      );
   };

   handleSubmitResetForm = async (e, recaptchaToken) => {
      e.preventDefault();
      const { t } = this.props;
      try {
         const { username } = this.props.form.values;
         await sendForgottenPasswordEmail(username, recaptchaToken);
         history.push({
            pathname: `/email-envoye`,
            state: { email: username },
         });
      } catch (e) {
         this.props.sendSnack({
            type: 'error',
            duration: 3000,
            message: t('error'),
         });
      }
   };

   handleSubmitActivateForm = async e => {
      e.preventDefault();
      const { t } = this.props;
      const { username, ...values } = this.props.form.values;

      try {
         await register({ ...values, email: username });
         this.setState({ currentAction: 'CHECK_MAIL' });
      } catch (err) {
         this.setState({
            error: t('error'),
         });
         if (!err || !err.status) {
            return;
         }
      }
   };

   handleSubmitPasswordForm = async e => {
      e.preventDefault();

      const { syncErrors } = this.props.form;

      if (isEmpty(syncErrors)) {
         this.setState({ currentAction: 'REGISTER' });
      }
   };

   handleSubmitLoginForm = async e => {
      e.preventDefault();

      const { form, login } = this.props;
      const { values } = form;

      if (Boolean(values) && Boolean(values.password)) {
         login(values);
         return;
      }

      try {
         const { error } = await checkEmail(values);
         const code = get(error, 'code');

         if (code === 202) {
            this.setState({ currentAction: 'CHECK_MAIL' });
         }
      } catch (err) {
         let error = get(err, 'error') || err;
         if (typeof error === 'string') {
            error = err;
         }
         const status = get(error, 'code') || get(error, 'status');

         if (!error || !status) {
            return;
         }

         if (status === 404) {
            this.setState({ currentAction: 'REGISTER' });
         }

         if (status === 401) {
            this.setState({ currentAction: 'LOGIN' });
         }

         if (status === 403) {
            const allowedOffer = get(error, 'message[0].allowed_offer');
            this.setState({ currentAction: 'REDIRECT_TO_OFFER', allowedOffer });
         }
      }
   };

   render() {
      const { loginPasswordType, currentAction, error, allowedOffer } = this.state;
      const { user, form, t } = this.props;
      const { values } = form || {};

      return (
         <Wrapper>
            <SecondWrapper style={{ minHeight: '100vh' }}>
               <Sidebar style={{ flex: '0 0 600px' }}>
                  <Container hasVerticalPadding style={{ minHeight: '100vh' }}>
                     <Logo />

                     <Content>
                        <Switch>
                           <Route
                              path='/mot-de-passe-oublie'
                              render={() => <LostPasswordForm onSubmit={this.handleSubmitResetForm} />}
                           />

                           <Route
                              render={() =>
                                 currentAction === 'REDIRECT_TO_OFFER' ? (
                                    <RedirectionWarning allowedOffer={allowedOffer} />
                                 ) : currentAction === 'CHECK_MAIL' ? (
                                    <CheckYourMailBis
                                       {...values}
                                       onConfirm={() => {
                                          this.setState({ currentAction: '' });
                                       }}
                                       error={error}
                                    />
                                 ) : currentAction === 'REGISTER' ? (
                                    <ActivateForm onSubmit={this.handleSubmitActivateForm} error={error} />
                                 ) : (
                                    <LoginForm
                                       type={loginPasswordType}
                                       onSubmit={this.handleSubmitLoginForm}
                                       shouldShowExtraFields={currentAction === 'LOGIN'}
                                       error={error || user.error}
                                    />
                                 )
                              }
                           />
                        </Switch>
                        <Route
                           path='/mot-de-passe-oublie'
                           render={() => (
                              <BoxSuffix>
                                 <LinkButton to='/connexion'>{t('misc:back')}</LinkButton>
                              </BoxSuffix>
                           )}
                        />
                     </Content>

                     <LanguageContainer>
                        <LanguageSelector top />
                     </LanguageContainer>
                  </Container>
               </Sidebar>

               <Background />
            </SecondWrapper>
         </Wrapper>
      );
   }
}

const mapStateToProps = state => ({
   form: state.form.auth,
   user: state.user,
});

const mapDispatchToProps = dispatch => ({
   login: payload => dispatch(login(payload)),
   refreshToken: payload => dispatch(refreshToken(payload)),
   signup: payload => dispatch(signup(payload)),
});

export default reduxForm({
   form: 'auth',
})(connect(mapStateToProps, mapDispatchToProps)(withTranslation('auth')(withSnacks(Auth))));
