import trim from 'lodash/trim';

export const isEmail = value => value && !isValidEmail(value) && 'invalidEmail';

export const isValidEmail = value =>
   value &&
   // eslint-disable-next-line no-useless-escape
   /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
      value
   );

export const validatePassword = password => {
   if (!Boolean(password)) return 'required';
   if (!isLongerThanTwelve(password)) return 'mustBeLongerThanTwelve';
   if (!hasOneUpperLetter(password)) return 'musHaveOneUpperLetter';
   if (!hasOneLowerLetter(password)) return 'musHaveOneLowerLetter';
   if (!hasOneDigit(password)) return 'musHaveOneDigit';
   if (!hasOneSpecialCharacter(password)) return 'musHaveOneSpecialCharacter';
   return null;
};

export const isRequired = value => !Boolean(value) && 'required';
export const isLongerThanTwelve = value => value?.length >= 12;
export const hasOneDigit = input => input?.match(/\d+/);
export const hasOneUpperLetter = input => input?.match(/[A-Z]+/);
export const hasOneLowerLetter = input => input?.match(/[a-z]+/);
export const hasOneSpecialCharacter = input =>
   input?.match(/(?=[^!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~]*[!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~])/);

export const isValidAmount = value => {
   return (
      (isNaN(Number((`${value}` || '').replace(/,/g, '.'))) ||
         !Boolean(Number((`${value}` || '').replace(/,/g, '.')))) &&
      'invalidAmount'
   );
};

export const isBic = value => !value.match(/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/) && 'invalid';

export const isSiret = value => {
   return value && value.replace(/ /g, '').length !== 14 && 'invalid';
};

export const isIban = value => {
   return value && value.replace(/ /g, '').length !== 27 && 'invalid';
};
export const normalizeDate = (value, previousValue) => {
   if (!Boolean(value)) {
      return value;
   }

   const digits = value.replace(/[^\d]/g, '');

   const day = digits.slice(0, 2);
   const month = digits.slice(2, 4);
   const year = digits.slice(4, 8);

   if (!previousValue || value.length > previousValue.length) {
      if (digits.length === 2) {
         return Boolean(Number(digits)) ? (Number(digits) < 32 ? `${digits}/` : `0${digits[0]}/${digits[1]}`) : '0';
      }

      if (digits.length === 4) {
         return Boolean(Number(month))
            ? Number(month) < 13
               ? `${day}/${month}/`
               : `${day}/0${month[0]}/${month[1]}`
            : `${day}/0`;
      }
   }

   if (digits.length <= 2) {
      return digits;
   }

   if (digits.length <= 4) {
      return `${day}/${month}`;
   }

   return `${day}/${month}/${year}`;
};

/**
 * Format IBAN:
 *
 * [1]: Remove all white spaces (will be added later on)
 *      fr12 3456 => fr123456
 * [2]: Set all characters to toUpperCase
 *      fr123456 => FR123456
 * [3]: Replace all non-digit/uppercase characters to nil
 *      FR123456É => FR123456
 * [4]: Add spaces every 4 characters
 *      FR123456 => FR12 3456
 * [5]: Remove trailing whitespace
 *      'FR12 3456 ' => 'FR12 3456'
 */
export const normalizeIBAN = (value = '', previousValue) => {
   if (value.length < 2) {
      return 'FR';
   }

   return trim(
      value
         .replace(/ /g, '') // [1]
         .substring(0, 27)
         .toUpperCase() // [2]
         .replace(/[^\dA-Z]/g, '') // [3]
         .replace(/(.{4})/g, '$1 ') // [4] ,
   ); // [5]
};

export const normalizeSiret = (value = '') => {
   const normalizedValue = value
      .replace(/ /g, '')
      .substring(0, 14)
      .replace(/[^\d]/g, '');

   let output = normalizedValue.slice(0, 3);
   if (value.length > 3) {
      output += ' ';
   }
   output += normalizedValue.slice(3, 6);
   if (value.length > 7) {
      output += ' ';
   }
   output += normalizedValue.slice(6, 9);
   if (value.length > 11) {
      output += ' ';
   }
   output += normalizedValue.slice(9, 14);

   return output;
};

export const normalizeAmount = (value = '') =>
   value
      .replace(/^\./, '')
      .replace(/[^\d,.]/g, '')
      .replace(/\.+/, ',')
      .replace(/[e]/g, '');

export const normalizeUpperCase = (value = '') => value.toUpperCase();

export const minLength = min => value =>
   value && value.length < min ? `Ce champ doit faire au moins ${min} caractères` : undefined;

export const isLowerThanTwoHundred = value =>
   value && parseFloat(value) > 200 ? `mustBeLowerThanTwoHundred` : undefined;

export const isLowerThanOneMillion = value =>
   value && parseFloat(value) > 1000000 ? `mustBeLowerThanOneMillion` : undefined;

export const normalizePhone = (value = '') =>
   trim(
      value
         .replace(/([+]{0,1})([0-9\s]+).?/g, '$1$2')
         .replace(/\s+/g, '')
         .replace(/\//g, '')
         .replace(/[a-zA-Z]/g, '')
         .replace(/\+\+/g, '+')
         .replace(/(\d{2})/g, '$1 ')
   );
