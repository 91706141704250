import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TokenSenderContext } from '../contexts';

const TokenSender = ({ children, destination }) => {
  const [isActive, setIsActive] = useState();

  const frameRef = useRef();
  const resolveRef = useRef();

  const { user, placeId } = useSelector(state => ({
    user: state.user,
    placeId: state.ui.selectedPlaceId,
  }));

  const handleTokenLogin = () =>
    new Promise(resolve => {
      resolveRef.current = resolve;
      setIsActive(true);
    });

  useEffect(() => {
    const handleMessage = e => {
      const { origin, data = {} } = e;
      if (origin !== destination) {
        return;
      }

      if (data.type === 'auth' && data.action === 'ready') {
        frameRef.current.contentWindow.postMessage(
          { type: 'auth', user, placeId },
          destination,
        );
      }

      if (data.type === 'auth' && data.action === 'done') {
        setIsActive(false);
        if (resolveRef.current) {
          resolveRef.current();
        }
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [user, placeId, destination]);

  return (
    <>
      <TokenSenderContext.Provider value={{ onTokenLogin: handleTokenLogin }}>
        {children}
      </TokenSenderContext.Provider>
      {isActive && (
        <iframe
          ref={frameRef}
          style={{ display: 'none' }}
          title="TokenSender"
          aria-hidden="true"
          src={`${destination}/token-login`}
        />
      )}
    </>
  );
};

export default TokenSender;
