import styled, { css } from 'styled-components';
import { withRouter } from 'react-router';
import { Route, Link, Switch } from 'react-router-dom';
import { camelCase, capitalize } from 'lodash';
import { NavLink, spacing, size, InlineList, media } from '../ui';
import { getPathSlug } from '../utils';
import { MdChevronLeft } from 'react-icons/md';
import { MdChevronRight } from 'react-icons/md';
import Helmet from 'react-helmet';
import withSizes from 'react-sizes';
import { useTranslation } from 'react-i18next';

const getCrumbName = ({ crumb, url, customTranslations = {}, t }) => {
  const pathSlug = getPathSlug(url);
  const customTranslation = customTranslations[pathSlug];
  const customCrumbTranslation = customTranslations[crumb];
  const pathTranslation = pathSlug !== t(pathSlug) && t(pathSlug);
  const crumbTranslation =
    camelCase(crumb) !== t(camelCase(crumb)) && t(camelCase(crumb));

  // If full path has specific translation, use it.
  // If not, return the crumb translation.
  // If it does not exist, return the crumb slug.
  return customTranslation
    ? customTranslation
    : pathTranslation
    ? pathTranslation
    : customCrumbTranslation
    ? customCrumbTranslation
    : crumbTranslation
    ? crumbTranslation
    : capitalize(crumb);
};

const Title = styled.h1`
  font-size: ${({ theme }) => theme.size(1)};
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
  font-weight: normal;
  color: #fff;
  font-weight: 500;
  line-height: 24px;

  ${media.tablet`
    line-height: ${({ theme }) => theme.spacing(2.25)};
    font-size: ${({ theme }) => theme.size(3)};
    color: inherit;
  `};

  &:hover {
    text-decoration: underline;
  }
`;

const BackLink = styled.li`
  color: #fff;
  position: absolute;
  left: ${({ theme }) => theme.spacing()};
  top: 50%;
  transform: translateY(-50%);
  font-size: ${({ theme }) => theme.size(3)};

  ${media.tablet`display: none;`};

  > a {
    color: inherit;
  }
`;

const HiddenOnMobileListItem = styled.li`
  display: none;
  vertical-align: middle;
  vertical-align: baseline;

  ${media.tablet`
    display: inline-block;
  `};
`;

const Li = styled.li`
  display: block;
  text-align: center;
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(2)};
  line-height: ${({ theme }) => theme.spacing(1.5)};

  ${media.tablet`
    display: inline-block;
    text-align: initial;
    padding-left: 0;
    padding-right: 0;
  `};
`;

const CrumbLink = styled(NavLink)`
  color: ${({ theme }) => theme.text};

  &:hover {
    text-decoration: underline;
  }
`;

const CrumbLast = styled.span`
  color: #fff;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;

  ${media.tablet`
    color: ${({ theme }) => theme.textLight};
    font-weight: normal;
    display: inline;
  `};
`;

const Wrapper = styled.div`
  align-items: baseline;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing()};

  ${media.tablet`
    padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) =>
    theme.spacing(2)} ${({ theme }) => theme.spacing()};
    display: flex;
  `};

  ${props =>
    props.bareHorizontal &&
    css`
      padding-left: 0;
      padding-right: 0;
    `};

  ${props =>
    props.bareVertical &&
    css`
      padding-top: ${({ theme }) => theme.spacing(0.5)};
      padding-bottom: ${({ theme }) => theme.spacing(0.5)};
    `};

  ${props =>
    props.narrow &&
    css`
      max-width: 800px;
    `};

  ${props =>
    props.shouldBeHiddenOnLargeViewports &&
    css`
      ${media.tablet`
        display: none;
      `};
    `};
`;

const BreadcrumbContent = styled(InlineList)`
  font-size: ${({ theme }) => theme.size(1)};
  display: block;

  ${media.tablet`
    > li + li:before {
      content: '›';
      margin-left: ${({ theme }) => theme.spacing(0.5)};
      margin-right: ${({ theme }) => theme.spacing(0.5)};
    }
  `};
`;

const BreadcrumbAction = styled.span`
  display: none;
  padding-left: ${({ theme }) => theme.spacing()};
  font-size: ${({ theme }) => theme.size()};

  ${media.tablet`
    display: inline-block;
  `};
`;

const BreadcrumbSubtitle = styled.span`
  font-size: ${({ theme }) => theme.size()};
  padding-top: ${({ theme }) => theme.spacing()};
  color: #fff;

  ${media.tablet`
  display: none;
`};
`;

export const BreadcrumbButton = styled(Link)`
  text-decoration: none;
  display: inline-block;
  font-size: ${({ theme }) => theme.size(-1)};
  background-color: ${({ theme }) => theme.primary};
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing(0.5)};
  color: #fff;
  border-radius: 999px;
  vertical-align: middle;
`;

const Crumb = withSizes(({ width }) => ({ width }))(props => {
  const {
    location,
    match,
    customTranslations,
    isRoot,
    backLink,
    width,
  } = props;
  const { pathname } = location;
  const { url, params } = match;
  const { crumb } = params;
  const isLastCrumb = pathname === url;
  const { t } = useTranslation('breadcrumbs');

  if (isLastCrumb) {
    return (
      <>
        <Helmet
          title={`${getCrumbName({
            crumb,
            url,
            customTranslations,
            t,
          })}`}
        />

        {backLink && (
          <BackLink>
            <Link to={backLink.url}>
              <MdChevronLeft />
            </Link>
          </BackLink>
        )}

        {isRoot ? (
          <Li>
            <Title>{getCrumbName({ crumb, url, customTranslations, t })}</Title>
          </Li>
        ) : (
          <Li>
            <CrumbLast>
              {getCrumbName({ crumb, url, customTranslations, t })}
            </CrumbLast>
          </Li>
        )}
      </>
    );
  }

  return (
    <>
      <HiddenOnMobileListItem>
        <CrumbLink to={url}>
          {isRoot ? (
            <Title>{getCrumbName({ crumb, url, customTranslations, t })}</Title>
          ) : (
            getCrumbName({ crumb, url, customTranslations, t })
          )}
        </CrumbLink>
      </HiddenOnMobileListItem>

      <Route
        path={`${url}/:crumb`}
        render={() => (
          <ConnectedCrumb
            customTranslations={customTranslations}
            backLink={
              backLink || {
                url,
                label: getCrumbName({ crumb, url, customTranslations, t }),
              }
            }
          />
        )}
      />
    </>
  );
});

const ConnectedCrumb = withRouter(Crumb);

const Breadcrumb = ({
  customTranslations,
  children,
  bareHorizontal,
  narrow,
  backLink,
  shouldBeHiddenOnLargeViewports,
  subtitle,
  style,
}) => (
  <Wrapper
    bareHorizontal={bareHorizontal}
    narrow={narrow}
    shouldBeHiddenOnLargeViewports={shouldBeHiddenOnLargeViewports}
    bareVertical={subtitle}
    style={style}
  >
    <BreadcrumbContent>
      <Switch>
        <Route
          path="/:crumb"
          render={() => (
            <ConnectedCrumb
              isRoot
              customTranslations={customTranslations}
              backLink={backLink}
            />
          )}
        />

        <Route
          render={() => (
            <ConnectedCrumb isRoot customTranslations={customTranslations} />
          )}
        />
      </Switch>
    </BreadcrumbContent>

    {children && <BreadcrumbAction>{children}</BreadcrumbAction>}
  </Wrapper>
);

export const Crumbs = ({
  customTranslations,
  children,
  bareHorizontal,
  narrow,
  backLink,
  shouldBeHiddenOnLargeViewports,
  subtitle,
  style,
  path,
}) => {
  const { t } = useTranslation('breadcrumbs');

  const r = /\/([^\/]+)/g;
  const crumbs = Array.from(path.matchAll(r)).reduce((obj, item, index) => {
    const previous = index > 0 ? obj[index - 1]?.url : '';
    return [...obj, { crumb: item[1], url: `${previous}${item[0]}` }];
  }, []);

  return (
    <Wrapper
      bareHorizontal={bareHorizontal}
      narrow={narrow}
      shouldBeHiddenOnLargeViewports={shouldBeHiddenOnLargeViewports}
      bareVertical={subtitle}
      style={style}
    >
      <BreadcrumbContent>
        {crumbs.map(({ url, crumb }, index) => (
          <Li>
            <CrumbLink to={url}>
              {index === 0 ? (
                <Title>
                  {getCrumbName({ crumb, url, customTranslations, t })}
                </Title>
              ) : (
                getCrumbName({ crumb, url, customTranslations, t })
              )}
            </CrumbLink>
          </Li>
        ))}
      </BreadcrumbContent>
    </Wrapper>
  );
};

export default withRouter(Breadcrumb);
