import { css } from 'styled-components';
import round from 'lodash/round';

export const base = 16;
export const spacing = (input = 1) => `${input * base}px`;

export const size = (input = 0) =>
  `${round(Math.sqrt(Math.sqrt(2)) ** input, 3)}rem`;

export const viewportSizes = {
  desktop: 1366,
  medium: 1100,
  tablet: 800,
  tabletMini: 500,
  all: 0,
};

const mediaQuery = (...query) => (...rules) =>
  css`
    @media ${css(...query)} {
      ${css(...rules)};
    }
  `;

export const media = {
  tabletMini: mediaQuery`(min-width: ${viewportSizes.tabletMini / 16}em)`,
  tablet: mediaQuery`(min-width: ${viewportSizes.tablet / 16}em)`,
  medium: mediaQuery`(min-width: ${viewportSizes.medium / 16}em)`,
  desktop: mediaQuery`(min-width: ${viewportSizes.desktop / 16}em)`,
};
