import { MdInfoOutline } from 'react-icons/md';
import styled from 'styled-components';
import { Stack } from '@tymate/margaret';

const Wrapper = styled(Stack)`
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.primary};
  border-radius: 8px;
`;

const Icon = styled(Stack)`
  height: ${({ theme }) => theme.spacing(1.5)};
  align-items: center;
`;

const Info = ({ children, ...props }) => (
  <Wrapper
    paddingHorizontal={1}
    paddingVertical={0.75}
    gutterSize={0.5}
    marginVertical={1}
    {...props}
  >
    <Icon>
      <MdInfoOutline />
    </Icon>
    <div>{children}</div>
  </Wrapper>
);

export default Info;
