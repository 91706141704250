import get from 'lodash/get';
import merge from 'lodash/merge';
import { generatePagination } from 'utils/pagination';

const initialState = {
  userById: {},
  contactsPagination: {},
};

const users = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case 'UPDATE_ME_SUCCESS':
    case 'ME_SUCCESS':
      return {
        ...state,
        userById: {
          ...state.userById,
          [payload.result]: {
            ...(get(state, `userById.${payload.result}`) || {}),
            ...get(payload, `entities.users.${payload.result}`),
          },
        },
      };

    case 'CONTACT_SUCCESS':
    case 'CONVERSATION_SUCCESS':
    case 'UPDATE_CONVERSATION_SUCCESS':
    case 'REPORT_SUCCESS':
    case 'TOPIC_SUCCESS':
      return {
        ...state,
        userById: merge(state.userById, get(payload, 'entities.users')),
      };

    case 'PLACE_REPORTS_SUCCESS':
    case 'PLACE_CONVERSATIONS_SUCCESS':
    case 'CONVERSATION_MESSAGES_SUCCESS':
    case 'REPORT_WITNESSES_SUCCESS':
    case 'TOPICS_SUCCESS':
    case 'TOPIC_POSTS_SUCCESS':
      return {
        ...state,
        userById: merge(state.userById, get(payload, 'data.entities.users')),
      };

    case 'PLACE_CONTACTS_SUCCESS':
      return {
        ...state,
        userById: merge(state.userById, get(payload, 'data.entities.users')),
        contactsPagination: {
          ...state.contactsPagination,
          [payload.placeId]: merge(
            get(state, `contactsPagination[${payload.placeId}]`),
            generatePagination(
              state.contactsPagination,
              payload.placeId,
              action,
            ),
          ),
        },
      };

    case 'LOGOUT':
      return initialState;

    default:
      return state;
  }
};

export default users;
