import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { spacing, List, ButtonReset, size } from './';

export const PopoverContainer = styled.div`
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.2), 0 4px 24px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 10;
  left: 0;
  top: 100%;
  top: calc(100% + ${spacing(0.25)});
  border-radius: ${({ theme }) => theme.borderRadius};
  overflow: hidden;
  background-color: #fff;
  min-width: 14em;

  ${props =>
    props.top &&
    css`
      top: auto;
      bottom: calc(100% + ${spacing(0.25)});
    `};

  ${props =>
    props.right &&
    css`
      left: auto;
      right: 0;
    `};

  ${props =>
    (props.bare || props.hasNoMinimumWidth) &&
    css`
      min-width: 0;
    `};

  ${props =>
    props.size === 'big' &&
    css`
      min-width: initial;
      width: 25em;
      max-width: calc(100vw - ${spacing(2)});
    `};
`;

export const PopoverMenu = styled(List)`
  padding: ${spacing(0.5)} 0;

  ${props =>
    props.variant === 'bare' &&
    css`
      padding-top: 0;
      padding-bottom: 0;
    `};
`;

export const PopoverItem = styled.li`
  position: relative;

  + li:before {
    position: absolute;
    top: 0;
    left: ${spacing()};
    right: 0;
    content: '';
    display: block;
  }
`;

export const PopoverItemButton = styled(ButtonReset)`
  padding: ${spacing(0.5)} ${spacing()};
  cursor: pointer;
  width: 100%;
  color: inherit;
  text-decoration: none;
  font-size: ${size()};
  display: flex;
  align-items: center;

  > svg {
    margin-right: ${spacing(0.5)};
  }

  &:hover {
    background-color: ${({ theme }) => theme.background};
  }

  ${props =>
    props.alignedRight &&
    css`
      text-align: right;
    `};

  ${props =>
    props.isSelected &&
    css`
      font-weight: 600;
    `};

  ${props =>
    props.isHighlighted &&
    css`
      background-color: #f3f4f8;
    `};
`;

export const PopoverItemLink = PopoverItemButton.withComponent(Link);

export const PopoverInner = styled.div`
  margin: ${({ theme }) => theme.spacing()};
`;
