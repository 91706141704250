import { Component } from 'react';
import styled, { withTheme } from 'styled-components';
import { camelizeKeys } from 'humps';
import { Buttons, Modal } from '@tymate/margaret';
import { List, ButtonNew, ActionButton as RawActionButton } from '../../ui';
import { MdAttachFile } from 'react-icons/md';
import AttachmentNew from './AttachmentNew';
import MagnifiableAttachment from './MagnifiableAttachment';
import TextField from '../TextField';
import {
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalTitle,
} from '../../ui/modal';
import { withTranslation } from 'react-i18next';
import { Stack } from '@tymate/margaret';

const ActionButton = styled(RawActionButton)`
  display: flex;

  &:disabled {
    cursor: not-allowed;
  }
`;

const Label = styled.label`
  cursor: pointer;
  font-weight: 600;
  color: ${({ theme }) => theme.primary};
  height: 48px;
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: 4px;
`;

class Attachments extends Component {
  static defaultProps = {
    single: false,
    shouldShowAttachments: true,
    canAddAttachments: true,
  };

  state = {
    isFetching: false,
    newAttachment: null,
    newAttachmentName: null,
  };

  componentDidMount() {
    this._isMounted = true;
  }

  handleAddAttachment = e => {
    e.preventDefault();

    const reader = new FileReader();
    const file = e.target.files[0];
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.setState({
        newAttachment: {
          fileUrl: reader.result,
          sourceContentType: file.type,
          sourceFileSize: file.size,
        },
        newAttachmentName: file.name,
      });
    };
  };

  handleSendAttachment = mutation => {
    const { newAttachment, newAttachmentName } = this.state;
    this.setState({ isFetching: true });

    this.props
      .mutation({
        attachment: {
          image_base: newAttachment.fileUrl,
          source_file_name: newAttachmentName,
        },
      })
      .then(
        result => {
          if (this._isMounted) {
            this.setState({
              isFetching: false,
              newAttachment: null,
              newAttachmentName: null,
            });
            this.props.onAdd(camelizeKeys(result));
          }
        },
        err => console.error(err),
      );
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      attachments,
      onDelete,
      single,
      shouldShowAttachments,
      canAddAttachments,
      canBeRemoved,
      shouldAllHavePreview,
      shouldAllHavePreviewIfAllAreImages,
      canBeMagnified,
      hasSmallTopMargin,
      attachmentVariant,
      imagesPreviewSize,
      isPDF,
      disabled,
      t,
      theme,
      listProps,
      mutation,
    } = this.props;
    const { isFetching, newAttachment, newAttachmentName } = this.state;

    const shouldHavePreview =
      shouldAllHavePreview ||
      (shouldAllHavePreviewIfAllAreImages &&
        (attachments || []).filter(
          ({ sourceContentType }) => sourceContentType.indexOf('image') === -1,
        ).length === 0);

    return (
      <div style={{ marginTop: 0, marginBottom: 16 }}>
        <Modal
          isOpen={Boolean(newAttachment)}
          onRequestClose={() =>
            this.setState({ newAttachment: null, newAttachmentName: '' })
          }
        >
          <ModalContent>
            <ModalHeader>
              <ModalTitle small>{t('new')}</ModalTitle>
            </ModalHeader>

            <ModalBody>
              <div style={{ marginLeft: -16, marginBottom: 16 }}>
                <AttachmentNew
                  {...newAttachment}
                  canBeRemoved={false}
                  shouldHavePreview={false}
                />
              </div>

              <TextField
                label="Nom du fichier"
                value={newAttachmentName}
                onChange={e =>
                  this.setState({ newAttachmentName: e.target.value })
                }
              />
              <Buttons>
                <ButtonNew
                  type="button"
                  onClick={() => this.handleSendAttachment(mutation)}
                >
                  {t('send')}
                </ButtonNew>
              </Buttons>
            </ModalBody>
          </ModalContent>
        </Modal>

        {canAddAttachments && isFetching && (
          <Label hasSmallTopMargin={hasSmallTopMargin}>{t('loading')}</Label>
        )}

        {canAddAttachments &&
          !isFetching &&
          (!single || attachments.filter(Boolean).length === 0) && (
            <Stack
              as={Label}
              hasSmallTopMargin={hasSmallTopMargin}
              alignY="center"
              alignX="space-between"
              paddingVertical={0.25}
              paddingHorizontal={0.75}
              marginTop={1}
            >
              <div>{isPDF ? t('ribPDF') : t('add')}</div>
              <input
                id="profilePicture"
                type="file"
                onChange={this.handleAddAttachment}
                accept={isPDF ? 'application/pdf' : 'image/*,application/pdf'}
                style={{ display: 'none' }}
                disabled={disabled}
              />
              <ActionButton as="div" disabled={disabled}>
                <MdAttachFile />
              </ActionButton>
            </Stack>
          )}

        {shouldShowAttachments &&
          (canBeMagnified ? (
            <List
              {...listProps}
              style={{
                marginLeft: theme.spacing(-1),
                marginTop:
                  imagesPreviewSize === 'small' ? theme.spacing(-1) : 0,
                display: shouldHavePreview ? 'flex' : 'block',
              }}
            >
              {(attachments || []).filter(Boolean).map(attachment => (
                <li key={attachment.id}>
                  <MagnifiableAttachment {...attachment}>
                    {handleOpen => (
                      <AttachmentNew
                        {...attachment}
                        canBeRemoved={canBeRemoved}
                        shouldHavePreview={shouldHavePreview}
                        onRemove={() => onDelete(attachment.id)}
                        onClick={handleOpen}
                        variant={attachmentVariant}
                        size={imagesPreviewSize}
                      />
                    )}
                  </MagnifiableAttachment>
                </li>
              ))}
            </List>
          ) : (
            <List {...listProps} style={{ marginLeft: theme.spacing(-1) }}>
              {(attachments || []).filter(Boolean).map(attachment => {
                const attachmentData = Boolean(attachment?.id)
                  ? attachment
                  : attachment?.entities?.attachments[attachment?.result];
                return (
                  <li key={attachmentData?.id}>
                    <AttachmentNew
                      {...attachmentData}
                      canBeRemoved={canBeRemoved}
                      shouldHavePreview={shouldHavePreview}
                      onRemove={() => onDelete(attachmentData?.id)}
                      variant={attachmentVariant}
                    />
                  </li>
                );
              })}
            </List>
          ))}
      </div>
    );
  }
}

export default withTranslation('attachment')(withTheme(Attachments));
