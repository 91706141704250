export let API_BASE = process.env.REACT_APP_API_BASE;
export let CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export let SERGIC_OFFER = process.env.REACT_APP_SERGIC_OFFER;
export let ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export let SIGNATURE_UI = process.env.REACT_APP_SIGNATURE_UI;
export const SO_WEB_APP = process.env.REACT_APP_SO_WEB_APP;
export const SERGIC_AGENCY = process.env.REACT_APP_SERGIC_AGENCY;
export const NODE_ENV = process.env.NODE_ENV;

export * from './resolutionKinds';

if (ENVIRONMENT === 'development') {
  try {
    const debugStorage = JSON.parse(localStorage.getItem('debug'));

    API_BASE = debugStorage.API_BASE || API_BASE;
    SERGIC_OFFER = debugStorage.SERGIC_OFFER || SERGIC_OFFER;
  } catch (e) {}
}

export const FIELD_ALIASES = {
  fiscalYear: ['fiscalYearId'],
};
