import Icon from 'react-icon-base';

const IcSettings = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        d="M0 19h22L11 0 0 19zm12-3h-2v-2h2v2zm0-4h-2V8h2v4z"
        id="awarning"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M-1-2h24v24H-1z" />
      <g>
        <mask id="bwarning" fill="#fff">
          <use xlinkHref="#awarning" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#awarning" />
        <g mask="url(#bwarning)" fill="#FF9819">
          <path d="M-13-14h48v48h-48z" />
        </g>
      </g>
    </g>
  </Icon>
);

export default IcSettings;
