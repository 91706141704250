import { SERGIC_OFFER } from '../constants';
import { size, spacing } from './utils';

const getBrandPalette = () => {
   switch (SERGIC_OFFER) {
      case 'ESE':
      case 'ECO':
      case 'NEUF':
      case 'VSY':
         return {
            primary: '#0062ab',
            primaryLight: '#009de0',
            primaryUltraLight: '#dff3fb',
            primaryGradientOne: '#009ae1',
            primaryGradientTwo: '#295fcc',
            primaryButtonGradientOne: '#0062ab',
            primaryButtonGradientTwo: '#0062ab',
            primaryButtonIconColor: '#fff',
            primaryButtonTextColor: '#fff',
            primaryButtonBoxShadowHover: '0 2px 8px 0 #9fb8c7',
            primaryButtonBoxShadow: 'none',
            sidebarGradientOne: '#009ae1',
            sidebarGradientTwo: '#295fcc',
            sidebarLinkColor: '#fff',
            sidebarActiveLinkColor: '#fff',
            sidebarActiveLinkSvgColor: '#fff',
            sidebarActiveLinkBackgroundColor: 'rgba(0, 0, 0, 0.12)',
            sidebarLinkPadding: '16px',
            sidebarLinkMargin: '0',
            sidebarLinkBorderRadius: '0',
            sidebarSubmenuBackgroundColor: 'rgba(0, 0, 0, 0.12)',
            sidebarMenuActiveLinkColor: '#fff',
            sidebarMenuActiveLinkBackgroundColor: 'rgba(0, 0, 0, 0.08)',
            sidebarMenuActiveLinkInnerBackgroundColor: 'transparent',
            sidebarMenuActiveLinkInnerVerticalPadding: '12px',
            sidebarMenuActiveLinkFontWeight: '400',
            background: '#F2F4F7',
            backgroundLight: '#f4fafe',
            default: '#0062ab',
            medium: '#285fcc',
            dark: '#00364d',
            vibrant: '#009de0',
            light: '#78b2d8',
            separator: '#E1E3E6',
            lightGrey: '#999999',
            darkGrey: 'rgba(0, 0, 0, 0.12)',
            white: '#fff',
         };
      case 'VQC':
         return {
            primary: '#ae0f0a',
            primaryLight: '#ae0f0a',
            primaryUltraLight: '#fee8e7',
            primaryGradientOne: '#ae0f0a',
            primaryGradientTwo: '#ae0f0a',
            primaryButtonGradientOne: '#fff',
            primaryButtonGradientTwo: '#f6f7f7',
            primaryButtonIconColor: '#ae0f0a',
            primaryButtonTextColor: '#212121',
            primaryButtonBoxShadowHover: '0 2px 4px 0 rgba(0, 0, 0, 0.12), inset 0 0 0 1px rgba(0, 0, 0, 0.06)',
            primaryButtonBoxShadow: 'inset 0 0 0 1px rgba(0, 0, 0, 0.06)',
            sidebarGradientOne: '#fff',
            sidebarGradientTwo: '#fff',
            sidebarLinkColor: 'rgba(0, 0, 0, 0.6)',
            sidebarActiveLinkBackgroundColor: 'rgba(0, 0, 0, 0.04)',
            sidebarActiveLinkColor: '#212121',
            sidebarActiveLinkSvgColor: '#ae0f0a',
            sidebarLinkPadding: '8px',
            sidebarLinkMargin: '8px',
            sidebarLinkBorderRadius: '6px',
            sidebarSubmenuBackgroundColor: 'transparent',
            sidebarMenuActiveLinkColor: '#212121',
            sidebarMenuActiveLinkBackgroundColor: 'transparent',
            sidebarMenuActiveLinkInnerBackgroundColor: 'rgba(0, 0, 0, 0.04)',
            sidebarMenuActiveLinkInnerVerticalPadding: '8px',
            sidebarMenuActiveLinkFontWeight: '500',
            background: '#F0f0f0',
            backgroundLight: '#fbfbfb',
            default: '#ae0f0a',
            medium: '#285fcc',
            dark: '#00364d',
            vibrant: '#ae0f0a',
            light: '#f2c3c1',
         };
      case 'DIR':
      default:
         return {
            primary: '#49a99c',
            primaryLight: '#49a99c',
            primaryUltraLight: '#DEEDEB',
            primaryGradientOne: '#49a99c',
            primaryGradientTwo: '#49a99c',
            primaryButtonGradientOne: '#49a99c',
            primaryButtonGradientTwo: '#49a99c',
            primaryButtonIconColor: '#fff',
            primaryButtonTextColor: '#fff',
            primaryButtonBoxShadowHover: '0 2px 8px 0 #9fb8c7',
            primaryButtonBoxShadow: 'none',
            sidebarGradientOne: '#49a99c',
            sidebarGradientTwo: '#49a99c',
            sidebarLinkColor: '#fff',
            sidebarActiveLinkColor: '#fff',
            sidebarActiveLinkSvgColor: '#fff',
            sidebarActiveLinkBackgroundColor: 'rgba(0, 0, 0, 0.12)',
            sidebarLinkPadding: '16px',
            sidebarLinkMargin: '0',
            sidebarLinkBorderRadius: '0',
            sidebarSubmenuBackgroundColor: 'rgba(0, 0, 0, 0.12)',
            sidebarMenuActiveLinkColor: '#fff',
            sidebarMenuActiveLinkBackgroundColor: 'rgba(0, 0, 0, 0.08)',
            sidebarMenuActiveLinkInnerBackgroundColor: 'transparent',
            sidebarMenuActiveLinkInnerVerticalPadding: '12px',
            sidebarMenuActiveLinkFontWeight: '400',
            background: '#EBF3F5',
            backgroundLight: '#f4fafe',
            default: '#49a99c',
            medium: '#419890',
            dark: '#00364d',
            vibrant: '#30c0ad',
            light: '#addcd6',
         };
   }
};

const colors = {
   brand: getBrandPalette(),
   black: {
      default: '#212121',
      secondary: '#707070',
      tertiary: '#9e9e9e',
      quaternary: '#cccccc',
   },
   red: {
      default: '#e22a2a',
      medium: '#FF4350',
      dark: '#bc021a',
   },
   grey: {
      default: '#f6f7f7',
      medium: '#dadede',
      light: '#fafafa',
      dark: '#bbb',
   },
   green: {
      default: '#4AB543',
   },
   orange: {
      default: '#ff9819',
      dark: '#e66322',
   },
   yellow: {
      default: '#F2B230',
   },
   blue: {
      default: '#0062ab',
   },
};

const color = (name, shade = 'default') => {
   return colors[name] && colors[name][shade];
};

const colorsUi = {
   text: color('black'),
   textSecondary: color('black', 'secondary'),
   textLight: color('black', 'tertiary'),
   textLighter: color('black', 'quaternary'),
   textBlueLight: color('blue'),
   background: '#F0F8FA',
   backgroundLight: '#F0F8FA',
   primary: color('brand'),
   primaryVibrant: color('brand', 'vibrant'),
   primaryLight: color('brand', 'light'),
   disabled: color('grey', 'medium'),
   success: color('green'),
   error: color('red', 'medium'),
   warning: color('orange', 'dark'),
   separator: 'rgba(0, 0, 0, 0.10)',
   separatorLight: 'rgba(0, 0, 0, 0.05)',
   imageShadow: 'rgba(2, 24, 43, .25)',
   blueShadow: 'rgba(89, 149, 179, .15)',
};

const ui = name => colorsUi[name];

export { color, ui };

export const getOfferGradient = () => {
   switch (SERGIC_OFFER) {
      case 'DIR':
         return 'linear-gradient(to right, hsl(172, 40%, 67%), hsl(172, 40%, 47%))';
      case 'VSY':
         return 'linear-gradient(to right, rgb(0, 154, 225), rgb(41, 95, 204))';
      case 'ESE':
      case 'ECO':
      default:
         return 'linear-gradient(to right, rgb(0, 154, 225), rgb(41, 95, 204))';
   }
};

export const theme = {
   text: 'rgba(0, 0, 0, 0.87)',
   textLight: 'rgba(0, 0, 0, 0.6)',
   textLighter: 'rgba(0, 0, 0, 0.38)',
   blue: '#0080c8',
   primaryGradient: `linear-gradient(135deg, ${getBrandPalette().primaryGradientOne} 0%, ${
      getBrandPalette().primaryGradientTwo
   } 100%)`,
   sidebarGradient: `linear-gradient(135deg, ${getBrandPalette().sidebarGradientOne} 0%, ${
      getBrandPalette().sidebarGradientTwo
   } 100%)`,
   disabledBackground: 'rgba(0, 0, 0, 0.12)',
   disabledBackgroundLight: 'rgba(0, 0, 0, 0.06)',
   disabledText: 'rgba(0, 0, 0, 0.26)',
   separator: 'rgba(0, 0, 0, 0.12)',
   separatorLight: 'rgba(0, 0, 0, 0.08)',
   success: '#4AB543',
   error: '#FF4350',
   warning: '#ff9819',
   borderRadius: '6px',

   ...getBrandPalette(),

   spacing,
   size,
};

export const careTakerTheme = {
   primary: '#5A52FF',
   primaryButtonGradientOne: '#5A52FF',
   primaryButtonGradientTwo: '#5A52FF',
   primaryButtonBoxShadow: 'hsl(243, 100%, 66%, 0.5)',
};

export const serviceAnytimeTheme = {
    primary: '#003a5e',
    primaryLight: '#009de0',
    text: '#003a5e',
};
