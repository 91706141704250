import styled from 'styled-components';
import tinycolor from 'tinycolor2';

export const Tag = styled.span`
  color: ${({ theme }) => theme.primary};
  background-color: ${({ theme }) =>
    tinycolor(theme.primary)
      .setAlpha(0.12)
      .toString()};
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing(0.5)};
  display: inline-block;
  border-radius: ${({ theme }) => theme.borderRadius};
  font-size: 14px;
`;
