import v4 from 'uuid/v4';

export const clearSnacks = () => ({
  type: 'SNACK_CLEAR_ALL',
});

export const sendSnack = snack => ({
  type: 'SNACK_QUEUE',
  payload: {
    ...snack,
    id: v4(),
  },
});

export const sendImportantSnack = snack => ({
  type: 'SNACK_POP_IMPORTANT',
  payload: {
    ...snack,
    id: v4(),
  },
});

export const dismissSnack = snackId => ({
  type: 'SNACK_DISMISS',
  payload: snackId,
});
