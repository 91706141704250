import get from 'lodash/get';
import find from 'lodash/find';

export const initialState = {
   selectedPlace: null,
   contactsSearch: '',
   selectedFiscalYears: {},
   newConversation: null,
   conversationsSearch: '',
   topicsSort: '-updatedAt',
   postsSortByTopicId: {},
   hasSkippedCheck: false,
   reportsSorting: '-updatedAt',
};

const ui = (state = initialState, action) => {
   const { type, payload } = action;
   switch (type) {
      case 'SELECT_PLACE':
         return {
            ...state,
            selectedPlaceId: payload,
         };

      case 'PLACES_SUCCESS':
         return {
            ...state,
            selectedPlaceId: state.selectedPlaceId || payload.result[0],
         };

      case 'IMPERSONATE_USER_SUCCESS':
         return {
            ...state,
            selectedPlaceId: null,
         };

      case 'UPDATE_CONTACTS_SEARCH':
         return {
            ...state,
            contactsSearch: payload,
         };

      case 'UPDATE_REPORTS_SORTING':
         return {
            ...state,
            reportsSorting: payload,
         };

      case 'PLACE_FISCAL_YEARS_SUCCESS':
         const placeActiveFiscalYear =
            get(state, `selectedFiscalYears[${payload.placeId}]`) ||
            get(
               find(
                  payload.data.result.map(id => payload.data.entities.fiscalYears[id]),
                  ({ current }) => current
               ),
               'id'
            );

         return {
            ...state,
            selectedFiscalYears: {
               ...state.selectedFiscalYears,
               [payload.placeId]: placeActiveFiscalYear,
            },
         };

      case 'TOGGLE_ONLY_SHOW_WORK_ORDERS':
         return {
            ...state,
            onlyShowWorkOrders: !state.onlyShowWorkOrders,
         };

      case 'SELECT_PLACE_FISCAL_YEAR':
         return {
            ...state,
            selectedFiscalYears: {
               ...state.selectedFiscalYears,
               [payload.placeId]: payload.fiscalYearId,
            },
         };

      case 'CREATE_NEW_CONVERSATION_PLACEHOLDER':
         return {
            ...state,
            newConversation: {
               members: [],
               distributionLists: [],
               entities: [],
               body: '',
            },
         };

      case 'UPDATE_NEW_CONVERSATION_PLACEHOLDER_MEMBERS':
      case 'UPDATE_NEW_CONVERSATION_PLACEHOLDER_DISTRIBUTION_LISTS':
      case 'UPDATE_NEW_CONVERSATION_PLACEHOLDER_ENTITIES':
      case 'UPDATE_NEW_CONVERSATION_PLACEHOLDER_BODY':
         return {
            ...state,
            newConversation: {
               ...state.newConversation,
               ...payload,
            },
         };

      case 'DESTROY_NEW_CONVERSATION_PLACEHOLDER':
         return {
            ...state,
            newConversation: initialState.newConversation,
         };

      case 'UPDATE_CONVERSATIONS_SEARCH':
         return {
            ...state,
            conversationsSearch: payload,
         };

      case 'UPDATE_TOPICS_SORTING':
         return {
            ...state,
            topicsSort: payload,
         };

      case 'UPDATE_TOPICS_POSTS_SORTING':
         return {
            ...state,
            postsSortByTopicId: {
               ...state.postsSortByTopicId,
               [payload.topicId]: payload.value,
            },
         };

      case 'SKIP_CHECK':
         return {
            ...state,
            hasSkippedCheck: true,
         };

      case 'OPEN_PLACE_SWITCHER':
         return {
            ...state,
            placeSwitcherIsOpen: true,
         };

      case 'CLOSE_PLACE_SWITCHER':
         return {
            ...state,
            placeSwitcherIsOpen: false,
         };

      case 'LOGOUT':
         return initialState;

      default:
         return state;
   }
};

export default ui;
