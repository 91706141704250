import { merge, addLocalPaginationToGlobalState } from 'utils';
import get from 'lodash/get';

const initialState = {
  contractById: {},
  contractsIds: [],
  parcelById: {},
  contractDocumentById: {},
  contractFolderById: {},
  contractDocumentsFoldersIdByContractId: {},
  contractDocumentsPagination: {},
  contractFoldersPagination: {},
  accountEntryById: {},
  accountEntriesIdsByContractId: {},
  moneyOrderById: {},
  moneyOrderIdByContractId: {},
  contractAccountEntriesPagination: {},
  transactionById: {},
  contractTransactionsPagination: {},
};

const places = (state = initialState, action) => {
  const { type, payload, meta } = action;
  let nextState = { ...state };

  switch (type) {
    case 'USER_CONTRACTS_SUCCESS':
      return {
        ...state,
        contractsIds: payload.result,
        contractById: merge(state.contractById, payload.entities.contracts),
        parcelById: merge(state.parcelById, payload.entities.parcels),
      };

    case 'CONTRACT_SUCCESS':
      return {
        ...state,
        contractById: merge(state.contractById, payload.entities.contracts),
        parcelById: merge(state.parcelById, payload.entities.parcels),
      };

    case 'UPDATE_CONTRACT_PREFERENCES_SUCCESS':
      return {
        ...state,
        contractById: merge(
          state.contractById,
          payload.data.entities.contracts,
        ),
        parcelById: merge(state.parcelById, payload.data.entities.parcels),
      };

    case 'CONTRACT_ACCOUNT_ENTRIES_SUCCESS':
      addLocalPaginationToGlobalState({
        state: nextState.contractAccountEntriesPagination,
        payload,
        meta,
        status: 'DONE',
      });

      return {
        ...nextState,
        accountEntryById: merge(
          state.accountEntryById,
          get(payload, 'data.entities.accountEntries'),
        ),
      };

    case 'CONTRACT_TRANSACTIONS_SUCCESS':
      addLocalPaginationToGlobalState({
        state: nextState.contractTransactionsPagination,
        payload,
        meta,
        status: 'DONE',
      });

      return {
        ...nextState,
        transactionById: merge(
          state.accountEntryById,
          get(payload, 'data.entities.transactions'),
        ),
      };

    case 'CONTEST_ACCOUNT_PLACE_ENTRY_SUCCESS':
    case 'CONFIRM_ACCOUNT_PLACE_ENTRY_SUCCESS':
      return {
        ...state,
        accountEntryById: merge(
          state.accountEntryById,
          payload.data.entities.accountEntries,
        ),
      };

    case 'CONTRACT_MONEY_ORDER_SUCCESS':
      return {
        ...state,
        moneyOrderById: merge(
          state.moneyOrderById,
          payload.data.entities.moneyOrder,
        ),
        moneyOrderIdByContractId: {
          ...state.moneyOrderIdByContractId,
          [payload.contractId]: payload.data.result,
        },
      };

    case 'CONTRACT_FOLDERS_REQUEST':
      addLocalPaginationToGlobalState({
        state: nextState.contractFoldersPagination,
        payload,
        status: 'PENDING',
      });
      return nextState;

    case 'CONTRACT_FOLDERS_ERROR':
      addLocalPaginationToGlobalState({
        state: nextState.contractFoldersPagination,
        payload,
        status: 'ERROR',
      });
      return nextState;

    case 'CONTRACT_FOLDERS_SUCCESS':
      addLocalPaginationToGlobalState({
        state: nextState.contractFoldersPagination,
        ...action,
        status: 'DONE',
      });
      return {
        ...nextState,
        contractFolderById: merge(
          state.contractFolderById,
          get(payload, 'data.entities.contractFolders'),
        ),
      };

    case 'CONTRACT_FOLDER_DOCUMENTS_REQUEST':
      addLocalPaginationToGlobalState({
        state: nextState.contractDocumentsPagination,
        payload,
        status: 'PENDING',
      });
      return nextState;

    case 'CONTRACT_FOLDER_DOCUMENTS_ERROR':
      addLocalPaginationToGlobalState({
        state: nextState.contractDocumentsPagination,
        payload,
        status: 'STALLED',
      });
      return nextState;

    case 'CONTRACT_FOLDER_DOCUMENTS_SUCCESS':
      addLocalPaginationToGlobalState({
        state: nextState.contractDocumentsPagination,
        ...action,
        status: 'DONE',
      });
      return {
        ...nextState,
        contractDocumentById: merge(
          state.contractDocumentById,
          get(payload, 'data.entities.contractDocuments'),
        ),
      };

    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
};

export default places;
