import { Component } from 'react';
import PropTypes from 'prop-types';

const withSnacks = Wrapped =>
  class extends Component {
    static contextTypes = {
      sendSnack: PropTypes.func.isRequired,
      sendImportantSnack: PropTypes.func.isRequired,
      clearSnacks: PropTypes.func.isRequired,
      sendPageview: PropTypes.func.isRequired,
      sendEvent: PropTypes.func.isRequired,
      setTitle: PropTypes.func.isRequired,
    };

    render() {
      const {
        sendSnack,
        sendImportantSnack,
        clearSnacks,
        sendPageview,
        sendEvent,
        setTitle,
      } = this.context;

      return (
        <Wrapped
          {...this.props}
          {...{
            sendSnack,
            sendImportantSnack,
            clearSnacks,
            sendPageview,
            sendEvent,
            setTitle,
          }}
        />
      );
    }
  };

export default withSnacks;
