import styled, { css } from 'styled-components';
import zxcvbn from 'zxcvbn';
import get from 'lodash/get';
import { size, spacing } from 'ui';
import { withTranslation } from 'react-i18next';
import {
   hasOneDigit,
   hasOneLowerLetter,
   hasOneSpecialCharacter,
   hasOneUpperLetter,
   isLongerThanTwelve,
} from '../utils/validation';

const Wrapper = styled.div`
   position: relative;
   text-align: right;
   color: ${({ theme }) => theme.error};
   padding-top: ${spacing()};

   ${props =>
      props.score > 2 &&
      css`
         color: ${({ theme }) => theme.warning};
      `};

   ${props =>
      props.score > 3 &&
      css`
         color: ${({ theme }) => theme.success};
      `};
`;

const GaugeWrapper = styled.div`
   display: flex;
   justify-content: flex-end;
   margin-bottom: ${spacing(0.5)};
`;

const GaugeComponent = styled.span`
   width: ${spacing(2)};
   height: ${spacing(0.25)};
   background-color: ${({ theme }) => theme.separator};
   margin-left: ${spacing(0.25)};

   ${props =>
      props.isActive &&
      css`
         background-color: currentColor;
      `};
`;

const Label = styled.div`
   font-size: ${size(-1)};
   font-weight: 500;
   min-height: 15px;
`;

const Gauge = ({ score }) => (
   <GaugeWrapper score={score}>
      <GaugeComponent isActive={score >= 1} />
      <GaugeComponent isActive={score >= 2} />
      <GaugeComponent isActive={score >= 3} />
      <GaugeComponent isActive={score >= 4} />
   </GaugeWrapper>
);

const PasswordStrength = ({ password = '', t }) => {
   let score = get(zxcvbn(password), 'score');
   if (
      score > 3 &&
      (!isLongerThanTwelve(password) ||
         !hasOneUpperLetter(password) ||
         !hasOneLowerLetter(password) ||
         !hasOneDigit(password) ||
         !hasOneSpecialCharacter(password))
   ) {
      score = 3;
   }
   return (
      <Wrapper score={score}>
         <Gauge score={score} />
         <Label>{password === '' ? ' ' : t(`passwordStrength:${score}`)}</Label>
      </Wrapper>
   );
};

export default withTranslation()(PasswordStrength);
