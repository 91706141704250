import { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { MdKeyboardArrowDown, MdSearch } from 'react-icons/md';
import { get, find, debounce, setWith, clone, pickBy } from 'lodash';
import {
  Dropdown,
  PopoverItem,
  PopoverItemButton,
  PopoverMenu as RawPopoverMenu,
  Spinner,
  Stack,
} from '@tymate/margaret';
import { Input } from '../ui';
import Dotdotdot from 'react-dotdotdot';
import { useQuery } from '@apollo/react-hooks';
import Scrollbars from 'react-custom-scrollbars';
import { InputSpinner } from '@tymate/sergic';
import { useDebounce, useDeepCompareEffect } from 'react-use';

const PopoverMenuInner = styled(Scrollbars)``;

const PopoverMenu = styled(RawPopoverMenu)`
  max-height: none;
  overflow-y: initial;
`;

const PopoverFooter = styled(Stack)``;

const Label = styled.label`
  display: block;
  color: inherit;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  font-weight: 600;
  font-size: 1rem;
  color: ${({ theme }) => theme.text};

  ${({ disabled }) =>
    Boolean(disabled) &&
    css`
      opacity: 0.5;
    `};

  ${({ hasError }) =>
    hasError &&
    css`
      &,
      &:hover,
      &:active {
        color: ${({ theme }) => theme.error};
      }
    `}
`;

const Trigger = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.text};
  border: 1px solid ${({ theme }) => theme.separator};
  background-color: #fff;
  border-radius: 4px;
  font-size: 1rem;
  padding: 0 ${({ theme }) => theme.spacing()};
  width: 100%;
  background: linear-gradient(180deg, #ffffff 0%, #f6f7f7 99.94%);
  height: 48px;
  position: relative;

  ${({ disabled }) =>
    Boolean(disabled) &&
    css`
      opacity: 0.5;
    `}

  ${({ variant }) =>
    variant === 'input' &&
    css`
      background: #fff;
    `}

  svg {
    margin-left: ${({ theme }) => theme.spacing(0.5)};
  }

  ${props =>
    props.isBareLeft &&
    css`
      padding-left: 0;
    `}

  ${props =>
    props.isBareRight &&
    css`
      padding-right: 0;
    `}

  ${({ hasError }) =>
    hasError &&
    css`
      &,
      &:hover,
      &:active {
        border-color: ${({ theme }) => theme.error};
      }
    `}

    ${({ variant }) =>
      variant === 'user' &&
      css`
        height: 40px;
        padding-left: ${({ theme }) => theme.spacing(0.5)};
        padding-right: ${({ theme }) => theme.spacing(0.5)};
        background: #fff;
      `}
`;

const Value = styled(Dotdotdot)`
  ${({ isEmpty, theme }) => css`
    &,
    + svg {
      color: ${isEmpty ? theme.textLighten : theme.text};
    }
  `};
`;

const SearchableSelectGQL = ({
  label,
  placeholder,
  onChange,
  defaultValue,
  disabled,
  variant,
  wrapperStyle,
  value,
  hasFixedWidth,
  query,
  isSearch,
  name,
  defaultDisplayName,
  pathToEdges,
  nodeFallbackDisplay,
  popoverFooter,
  variables,
  renderOption,
  emptyState,
  hasError,
  shouldUseSqlId,
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const [isOpen, setIsOpen] = useState();
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const dropdownRef = useRef();
  const popoverRef = useRef();
  const inputRef = useRef();

  useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    500,
    [search],
  );

  const { loading, error, data, fetchMore, refetch } = useQuery(query, {
    variables: pickBy({ search: debouncedSearch, ...variables }),
    fetchPolicy: 'network-only',
  });

  const options = (isSearch && search.length === 0
    ? []
    : get(data, pathToEdges, [])
  ).map(({ node }) => ({
    value: shouldUseSqlId ? node.sqlId : node.id,
    label: node.displayName || get(node, [nodeFallbackDisplay]),
    data: node,
  }));

  const handleScroll = debounce(({ top }) => {
    const pathToPageInfo = pathToEdges.slice(0, -1).concat('pageInfo');
    const currentEdges = get(data, pathToEdges, []);
    const pageInfo = get(data, pathToPageInfo, {});

    if (top > 0.85 && !loading && pageInfo?.hasNextPage) {
      fetchMore({
        variables: {
          cursor: pageInfo?.endCursor,
          search,
        },
        updateQuery: (currentResult, { fetchMoreResult }) => {
          let nextResults = clone(currentResult);
          const currentEdges = get(currentResult, pathToEdges, []);
          const newEdges = get(fetchMoreResult, pathToEdges, []);
          const newPageInfo = get(fetchMoreResult, pathToPageInfo, []);

          nextResults = setWith(
            clone(nextResults),
            pathToEdges,
            currentEdges.concat(newEdges),
            clone,
          );
          nextResults = setWith(
            clone(nextResults),
            pathToPageInfo,
            newPageInfo,
            clone,
          );

          return {
            ...currentResult,
            ...nextResults,
          };
        },
      });
    }
  }, 200);

  const handleSelectOption = ({ label, value, data }) => {
    if (!onChange) {
      return;
    }

    onChange({ label, value, data });
  };

  useDeepCompareEffect(() => {
    const selectedOption = find(options, option => option.value === value);
    setSelectedValue(selectedOption);
  }, [options, { value }]);

  useDeepCompareEffect(() => {
    if (!Boolean(selectedValue) || !onChange) {
      return;
    }

    onChange(selectedValue);
    if (dropdownRef.current) {
      dropdownRef.current.close();
    }
  }, [{ selectedValue }]);

  useEffect(() => {
    setSelectedValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setSearch('');

    if (!isOpen) {
      return;
    }

    inputRef.current.focus();
  }, [isOpen]);

  return (
    <>
      {Boolean(label) && (
        <Label hasError={hasError} disabled={disabled}>
          {label}
        </Label>
      )}

      <Dropdown
        disabled={disabled}
        ref={dropdownRef}
        wrapperStyle={wrapperStyle}
        onToggle={setIsOpen}
        trigger={
          <Trigger
            disabled={disabled}
            variant={variant}
            hasFixedWidth={hasFixedWidth}
            isBareLeft={isOpen}
            isBareRight={isOpen && !isSearch}
            hasError={hasError}
          >
            {isOpen ? (
              <>
                <Input
                  ref={inputRef}
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  variant="bare"
                  placeholder={selectedValue?.label}
                  style={variant === 'user' ? { height: 38 } : null}
                />

                {isSearch && <MdSearch size={variant === 'user' ? 16 : 24} />}

                {loading && <InputSpinner />}
              </>
            ) : (
              <>
                <Value isEmpty={!Boolean(selectedValue)} clamp={1}>
                  {get(
                    selectedValue,
                    'label',
                    defaultDisplayName || placeholder,
                  )}
                </Value>

                {isSearch ? (
                  <MdSearch size={variant === 'user' ? 16 : 24} />
                ) : (
                  <MdKeyboardArrowDown size={variant === 'user' ? 16 : 24} />
                )}
              </>
            )}
          </Trigger>
        }
      >
        <PopoverMenu>
          <PopoverMenuInner
            ref={popoverRef}
            autoHeight
            autoHeightMax={300}
            onUpdate={handleScroll}
          >
            {options.map(({ value, label, data }, index) => (
              <PopoverItem key={index}>
                <PopoverItemButton
                  type="button"
                  onClick={() => handleSelectOption({ value, label, data })}
                  isActive={value === get(selectedValue, 'value')}
                >
                  {Boolean(renderOption) ? renderOption(data) : label}
                </PopoverItemButton>
              </PopoverItem>
            ))}
          </PopoverMenuInner>

          {(Boolean(popoverFooter) ||
            (Boolean(emptyState) && !loading && options.length === 0)) && (
            <PopoverFooter>{popoverFooter}</PopoverFooter>
          )}
        </PopoverMenu>
      </Dropdown>
    </>
  );
};

SearchableSelectGQL.defaultProps = {
  placeholder: '',
  options: [],
};

export default SearchableSelectGQL;
