import { Component } from 'react';
import { Field } from 'redux-form';
import styled, { css } from 'styled-components';
import visible from '../icons/icVisibleOn.svg';
import hidden from '../icons/icVisibleOff.svg';
import { useTranslation } from 'react-i18next';

export const Wrapper = styled.div`
  display: ${props => (props.theme === 'inline' ? 'flex' : 'block')};
  align-items: ${props => (props.theme === 'inline' ? 'baseline' : 'center')};
  color: ${({ theme, error, warning, light }) =>
    error
      ? theme.error
      : warning
      ? theme.warning
      : light
      ? 'rgba(255,255,255,.8)'
      : 'inherit'};

  ~ * {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }

  input {
    width: 100%;
  }

  ${props =>
    props.inline &&
    css`
      flex: 0 0 100%;
      margin-right: 0;
    `};
`;

export const TextFieldsLine = styled.div`
  display: flex;

  > * {
    flex: 1;
  }

  > * + * {
    margin-top: 0;
    margin-left: ${({ theme }) => theme.spacing()};
  }

  + * {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`;

export const Label = styled.label`
  display: block;
  padding-top: ${props => (props.theme === 'inline' ? spacing(0.5) : 0)};
  font-weight: 500;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  line-height: 1;

  ${({ hasError }) =>
    hasError &&
    css`
      color: ${({ theme }) => theme.error};
    `}

  ${props =>
    props.theme === 'inline' &&
    css`
      flex: 1;
      text-align: right;
      margin-right: ${({ theme }) => theme.spacing()};
      display: block;
    `};
`;

export const Input = styled.input`
  outline: none;
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: ${({ theme }) => theme.borderRadius};
  display: inline-block;
  width: ${props => (props.block ? '100%' : 'auto')};
  background-color: #fff;
  font-size: ${({ theme }) => theme.spacing(1)};
  color: inherit;
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(0.75)};
  appearance: none;
  height: 48px;

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
    color: inherit;
  }

  &:focus,
  &:active {
    border-color: ${({ theme }) => theme.primaryLight};
  }

  &:disabled {
    background: ${({ theme }) => theme.disabledBackgroundLight};
    color: ${({ theme }) => theme.disabledColor};

    &:hover {
      box-shadow: none;
    }
  }

  ${props =>
    props.size === 'small' &&
    css`
      padding: ${({ theme }) => theme.spacing(0.25)}
        ${({ theme }) => theme.spacing(0.5)};
      font-size: ${({ theme }) => theme.spacing(0)};
    `};

  ${props =>
    props.variant === 'full' &&
    css`
      width: 100%;
    `};
`;

const InputContainer = styled.div`
  position: relative;

  ${props =>
    props.theme === 'inline' &&
    css`
      flex: 2;
    `};
`;

const Suffix = styled.span`
  display: inline-block;
  margin-left: ${({ theme }) => theme.spacing(0.5)};
`;

export const ErrorMessage = styled.span`
  display: block;
  padding-top: ${({ theme }) => theme.spacing(0.5)};
  color: ${props => (props.error ? props.theme.error : props.theme.warning)};
`;

export const TextField = ({
  block,
  hideErrorMessages,
  id,
  input,
  inputStyle,
  label,
  description,
  meta = {},
  onChange,
  placeholder,
  style,
  suffix,
  theme = '',
  type = 'text',
  value,
  hasError,
  error,
  disabled,
  after,
}) => {
  const { t } = useTranslation('errors');

  return (
    <Wrapper
      theme={theme}
      style={style}
      error={hasError || (meta.touched && meta.error)}
      warning={meta.touched && meta.warning}
    >
      <Label htmlFor={id || input.name} theme={theme}>
        {label}
      </Label>

      <InputContainer theme={theme}>
        <Input
          id={id || input.name}
          value={value}
          onChange={onChange}
          {...input}
          block={block}
          placeholder={placeholder}
          type={type}
          style={inputStyle || {}}
          error={meta.touched && meta.error}
          warning={meta.touched && meta.warning}
          disabled={disabled}
        />
        {(hasError || meta.touched) &&
          !hideErrorMessages &&
          (((hasError || meta.error) && (
            <ErrorMessage error>{t(error || meta.error)}</ErrorMessage>
          )) ||
            (meta.warning && <ErrorMessage>{meta.warning}</ErrorMessage>))}
        {suffix && (
          <Suffix htmlFor={id} theme={theme}>
            {suffix}
          </Suffix>
        )}
      </InputContainer>

      {after}
    </Wrapper>
  );
};

TextField.defaultProps = { input: {} };

const renderTextField = props => (
  <TextField onChange={props.input.onChange} {...props} />
);

export const ConnectedTextField = ({ validate, ...props }) => (
  <Field {...props} validate={validate} component={renderTextField} />
);

const ConnectedPasswordWrapper = styled.div`
  position: relative;
`;

const ConnectedPasswordTrigger = styled.button`
  border: 0;
  background-color: transparent;
  padding: 0;
  line-height: 1;
  position: absolute;
  right: ${({ theme }) => theme.spacing(0.5)};
  top: ${({ theme }) => theme.spacing(2)};
  margin-top: 0;
  width: ${({ theme }) => theme.spacing(1.5)};
  height: ${({ theme }) => theme.spacing(1.5)};
  cursor: pointer;
  outline: none;

  > img {
    display: block;
    max-width: 100%;
  }
`;

export class ConnectedPassword extends Component {
  static defaultProps = {
    type: 'password',
  };

  constructor(props) {
    super(props);

    this.state = { shown: props.type !== 'password' };
  }

  componentDidUpdate(prevProps) {
    const { type } = this.props;

    if (prevProps.type !== type) {
      this.setState({ shown: type !== 'password' });
    }
  }

  render() {
    const { shown } = this.state;
    const type = shown ? 'text' : 'password';

    return (
      <ConnectedPasswordWrapper>
        <ConnectedTextField {...this.props} type={type} />
        <ConnectedPasswordTrigger
          tabIndex={-1}
          type="button"
          onClick={() => this.setState({ shown: !shown })}
        >
          <img src={shown ? visible : hidden} alt="" />
        </ConnectedPasswordTrigger>
      </ConnectedPasswordWrapper>
    );
  }
}

export default TextField;
