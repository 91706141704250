import { useState } from 'react';
import { Field } from 'redux-form';
import styled, { css } from 'styled-components';
import { size, spacing } from 'ui';
import { useTranslation } from 'react-i18next';
import { ButtonReset, Stack } from '@tymate/margaret';
import { MdError } from 'react-icons/all';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import PasswordConditions from './PasswordConditions';
import { validatePassword } from '../utils/validation';

export const Wrapper = styled.div`
   display: ${props => (props.theme === 'inline' ? 'flex' : 'block')};
   align-items: ${props => (props.theme === 'inline' ? 'baseline' : 'center')};
   color: ${({ theme, error, warning, light }) =>
      error ? theme.error : warning ? theme.warning : light ? 'rgba(255,255,255,.8)' : 'inherit'};

   ~ * {
      margin-top: ${spacing(2)};
   }

   input {
      width: 100%;
   }

   ${props =>
      props.inline &&
      css`
         flex: 0 0 100%;
         margin-right: 0;
      `};
`;

export const TextFieldsLine = styled.div`
   display: flex;

   > * {
      flex: 1;
   }

   > * + * {
      margin-top: 0;
      margin-left: ${spacing()};
   }

   + * {
      margin-top: ${spacing(2)};
   }
`;

export const Label = styled.label`
   display: block;
   padding-top: ${props => (props.theme === 'inline' ? spacing(0.5) : 0)};
   font-weight: 500;
   margin-bottom: ${spacing(0.5)};
   line-height: 1;

   ${({ hasError }) =>
      hasError &&
      css`
         color: ${({ theme }) => theme.error};
      `}

   ${props =>
      props.theme === 'inline' &&
      css`
         flex: 1;
         text-align: right;
         margin-right: ${spacing()};
         display: block;
      `};
`;

export const Input = styled.input`
   outline: none;
   border: 1px solid ${({ theme }) => theme.separator};
   border-radius: ${({ theme }) => theme.borderRadius};
   display: inline-block;
   width: ${props => (props.block ? '100%' : 'auto')};
   background-color: #fff;
   font-size: ${size(1)};
   color: inherit;
   padding: ${spacing(0.5)} ${spacing(0.75)};
   appearance: none;
   height: 48px;

   &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px white inset;
      color: inherit;
   }

   &:focus,
   &:active {
      border-color: ${({ theme }) => theme.separator};
   }

   &:disabled {
      background: ${({ theme }) => theme.disabledBackgroundLight};
      color: ${({ theme }) => theme.disabledColor};
      cursor: not-allowed;

      &:hover {
         box-shadow: none;
      }
   }

   ${props =>
      props.size === 'small' &&
      css`
         padding: ${spacing(0.25)} ${spacing(0.5)};
         font-size: ${size(0)};
      `};

   ${props =>
      props.variant === 'full' &&
      css`
         width: 100%;
      `};
`;

const InputContainer = styled.div`
   position: relative;

   ${props =>
      props.theme === 'inline' &&
      css`
         flex: 2;
      `};
`;

const Suffix = styled.span`
   display: inline-block;
   margin-left: ${spacing(0.5)};
`;

export const ErrorMessage = styled.span`
   display: block;
   padding-top: ${spacing(0.5)};
   color: ${props => (props.error ? props.theme.error : props.theme.warning)};
`;

export const InputIcon = styled.div`
   position: absolute;
   top: 0;
   bottom: 0;
   right: ${({ theme }) => theme.spacing(0.75)};
   display: flex;
   align-items: center;
   justify-content: center;
   color: ${({ theme }) => theme.textLight};
`;

export const PasswordButton = styled(InputIcon).attrs({
   as: ButtonReset,
})``;

export const TextField = ({
   block,
   hideErrorMessages,
   id,
   input,
   inputStyle,
   label,
   description,
   meta = {},
   onChange,
   placeholder,
   style,
   suffix,
   theme = '',
   value,
   hasError,
   error,
   disabled,
   after,
   inputIcon,
   ...props
}) => {
   const { t } = useTranslation('errors');
   const isPassword = props.type === 'password';
   const [type, setType] = useState(props.type || 'text');

   return (
      <Wrapper
         theme={theme}
         style={style}
         error={hasError || (meta.touched && meta.error)}
         warning={meta.touched && meta.warning}
      >
         <Label htmlFor={id || input.name} theme={theme}>
            {label}
         </Label>

         <InputContainer theme={theme}>
            <Stack style={{ position: 'relative' }} alignX='stretch' size='full'>
               <Input
                  id={id || input.name}
                  value={value}
                  onChange={onChange}
                  {...input}
                  block={block}
                  placeholder={placeholder}
                  type={type}
                  style={inputStyle || {}}
                  error={meta.touched && meta.error}
                  warning={meta.touched && meta.warning}
                  disabled={disabled}
               />{' '}
               {hasError ? (
                  <InputIcon>
                     <MdError color={theme.error} size={14} />
                  </InputIcon>
               ) : inputIcon ? (
                  <InputIcon>{inputIcon}</InputIcon>
               ) : null}
               {isPassword && (
                  <PasswordButton
                     disabled={props.disabled}
                     type='button'
                     onClick={() => setType(type === 'password' ? 'text' : 'password')}
                     tabIndex={-1}
                  >
                     {type === 'text' ? <MdVisibility /> : <MdVisibilityOff />}
                  </PasswordButton>
               )}
            </Stack>
            {(hasError || meta.touched) &&
               !hideErrorMessages &&
               (((hasError || meta.error) && <ErrorMessage error>{t(error || meta.error)}</ErrorMessage>) ||
                  (meta.warning && <ErrorMessage>{meta.warning}</ErrorMessage>))}
            {suffix && (
               <Suffix htmlFor={id} theme={theme}>
                  {suffix}
               </Suffix>
            )}
         </InputContainer>
         {after}
      </Wrapper>
   );
};

TextField.defaultProps = { input: {} };

const renderTextField = props => <TextField onChange={props.input.onChange} {...props} />;

export const ConnectedTextField = ({ validate, ...props }) => (
   <Field {...props} validate={validate} component={renderTextField} />
);

export const ConnectedPassword = ({ password, isDirty = false, validation = false, ...props }) => (
   <ConnectedTextField
      type='password'
      after={validation && <PasswordConditions password={password} isDirty={isDirty} />}
      validate={validation ? validatePassword : undefined}
      {...props}
   />
);

export default TextField;
