import { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import MDSpinner from 'react-md-spinner';
import { receiveLogin } from 'actions/user';
import { SO_GENERAL_MEETING_WEBAPP_URL } from '../constants';

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

class TokenLogin extends Component {
  _listener = null;

  componentDidMount() {
    this._listener = window.addEventListener('message', this.handleMessage);
    const parent = window.parent;
    if (parent) {
      parent.postMessage(
        { type: 'auth', action: 'ready' },
        SO_GENERAL_MEETING_WEBAPP_URL,
      );
    }
  }

  handleMessage = e => {
    const { origin, data = {} } = e;
    const { receiveLogin } = this.props;
    const parent = window.parent;

    if (origin !== SO_GENERAL_MEETING_WEBAPP_URL) {
      return;
    }

    if (data.type === 'auth' && Boolean(data.user)) {
      receiveLogin(data.user);
      parent.postMessage(
        { type: 'auth', action: 'done' },
        SO_GENERAL_MEETING_WEBAPP_URL,
      );
    }
  };

  render() {
    return (
      <Loader>
        <MDSpinner size={60} singleColor="rgb(76, 175, 80)" />
      </Loader>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  receiveLogin: user => dispatch(receiveLogin(user)),
});

export default connect(null, mapDispatchToProps)(TokenLogin);
