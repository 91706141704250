import styled, { css } from 'styled-components';
import { NavLink as RawNavLink, Link } from 'react-router-dom';
import { round } from 'lodash';
import { color, ui } from './colors';

export const base = 16;
export const spacing = (input = 1) => `${input * base}px`;
export const size = (input = 0) =>
  `${round(Math.sqrt(Math.sqrt(2)) ** input, 3)}rem`;

export const viewportSizes = {
  desktop: 1200,
  medium: 960,
  tablet: 768,
};

export const MAIN_HEADER_HEIGHT = '60px';
export const MAIN_SIDEBAR_WIDTH = '250px';
export const SECONDARY_SIDEBAR_WIDTH = '300px';

const mediaQuery: Function = (...query) => (...rules) =>
  css`
    @media ${css(...query)} {
      ${css(...rules)};
    }
  `;

export const media = {
  tablet: mediaQuery`(min-width: ${viewportSizes.tablet / 16}em)`,
  medium: mediaQuery`(min-width: ${viewportSizes.medium / 16}em)`,
  desktop: mediaQuery`(min-width: ${viewportSizes.desktop / 16}em)`,
};

export const Box = styled.div`
  background-color: #fff;
  border-radius: 16px;
  padding: ${props => (props.padded ? spacing(2) : props.bare ? 0 : spacing())};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15), 0 8px 24px rgba(0, 0, 0, 0.15),
    0 12px 56px rgba(0, 0, 0, 0.15);
  color: ${ui('primary')};
`;

export const Container = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: ${spacing(2)};
  padding-right: ${spacing(2)};

  ${props =>
    !props.full &&
    css`
      max-width: 1200px;
    `};

  ${props =>
    props.narrow &&
    css`
      max-width: 800px;
    `};

  ${props =>
    props.tight &&
    css`
      max-width: 540px;
    `};

  ${props =>
    props.leftAligned &&
    css`
      margin-left: 0;
      margin-right: 0;
    `};

  ${props =>
    props.isBare &&
    css`
      padding-left: 0;
      padding-right: 0;
    `};
`;

export const List = styled.ul`
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
`;

export const InlineList = styled(List)`
  display: flex;
  flex-wrap: wrap;
`;

export const NavLink = styled(RawNavLink)`
  text-decoration: none;
`;

export const EditButton = styled.button`
  padding: ${spacing(0.5)};
  border-radius: 6px;
  border: 1px solid ${ui('separator')};
  color: ${ui('primaryVibrant')};
  background-color: #fff;
  outline: none;
  line-height: 1.2;
  display: inline-block;
  cursor: pointer;
`;

export const Button = styled.button`
  padding: ${spacing(0.5)};
  border-radius: 6px;
  border: 1px solid ${ui('separator')};
  color: ${ui('primaryVibrant')};
  background-color: #fff;
  outline: none;
  line-height: 1.2;
  display: inline-block;
  cursor: pointer;
  transition: color 150ms ease, opacity 150ms ease, background-color 150ms ease;
  text-decoration: none;

  ${props =>
    props.marginBottom &&
    css`
      margin-bottom: ${spacing()};
    `};

  &:hover {
    background-color: ${ui('backgroundLight')};
  }

  &:focus,
  &:active {
    background-color: ${ui('background')};
  }

  img,
  svg {
    width: ${spacing()};
    margin-right: ${spacing(0.5)};
  }

  ${props =>
    props.white &&
    css`
      background-color: #fff;

      &:hover {
        background-color: #fff;
        border-color: ${ui('primaryVibrant')};
      }
    `};

  ${props =>
    props.primary &&
    css`
      background-color: ${ui('primaryVibrant')};
      color: #fff;

      &:hover {
        background-color: ${ui('primary')};
      }

      &:focus,
      &:active {
        background-color: ${ui('primaryVibrant')};
      }
    `};

  ${props =>
    props.secondary &&
    css`
      background-color: ${ui('secondaryVibrant')};
      color: #fff;

      &:hover {
        background-color: ${ui('secondary')};
      }

      &:focus,
      &:active {
        background-color: ${ui('secondaryVibrant')};
      }
    `};

  ${props =>
    props.select &&
    css`
      color: ${ui('textLight')};
      border: 1px solid ${ui('separator')};

      > svg {
        fill: ${color('black', 'tertiary')};
      }

      &:hover,
      &:focus,
      &:active {
        background-color: #fff;
        color: ${ui('text')};
      }
    `};

  ${props =>
    props.outlineSecondary &&
    css`
      color: ${ui('secondaryVibrant')};
      background-color: transparent;
      border-color: ${ui('secondary')};

      &:hover {
        color: ${ui('secondary')};
        background-color: transparent;
      }
    `};

  ${props =>
    props.outline &&
    css`
      color: ${ui('primaryVibrant')};
      background-color: transparent;
      border-color: ${ui('uiVibrant')};

      &:hover {
        color: ${ui('primary')};
        background-color: transparent;
      }
    `};

  ${props =>
    props.cta &&
    css`
      display: block;
      font-size: ${size(1)};
      padding: ${spacing()} ${spacing(2)};
    `};

  ${props =>
    props.ctaAlt &&
    css`
      display: block;
      font-size: ${size(1)};
      padding: ${spacing(0.5)} ${spacing(2)};
    `};

  ${props =>
    props.block &&
    css`
      width: 100%;
    `};

  ${props =>
    props.icon &&
    css`
      background-color: transparent;
      color: ${color('black', 'tertiary')};

      &:hover {
        color: ${color('black', 'secondary')};
      }
    `};

  ${props =>
    props.link &&
    css`
      background-color: transparent;
      border: 0;
      padding: 0;
      color: ${ui('textLight')};
      text-decoration: underline;

      &:hover {
        color: ${ui('primary')};
        background: transparent;
      }
    `};

  ${props =>
    props.linkSecondary &&
    css`
      background-color: transparent;
      border: 0;
      padding: 0;
      color: ${ui('textLight')};
      text-decoration: underline;

      &:hover {
        color: ${ui('secondary')};
        background: transparent;
      }
    `};

  ${props =>
    props.error &&
    css`
      background-color: ${ui('error')};
      color: #fff;

      &:hover {
        background-color: ${ui('error')};
      }

      &:focus,
      &:active {
        background-color: ${ui('error')};
      }
    `};

  ${props =>
    props.outlineError &&
    css`
      color: ${ui('error')};
      background-color: transparent;
      border-color: ${ui('separator')};

      &:hover {
        border-color: ${ui('error')};
      }
    `};

  ${props =>
    props.bold &&
    css`
      font-weight: bold;
    `};

  :disabled {
    background-color: ${ui('disabled')};
    color: ${color('black', 'tertiary')};
    cursor: not-allowed;
  }
`;

export const ButtonLink = props => {
  const Wrapped = props.disabled ? Button : Button.withComponent(Link);
  return <Wrapped {...props}>{props.children}</Wrapped>;
};

export const Buttons = styled.div`
  text-align: ${props =>
    props.right ? 'right' : props.left ? 'left' : 'center'};
  margin-left: ${spacing(-1)};

  ${props =>
    props.marged &&
    css`
      padding-top: ${spacing()};
    `};

  ${props =>
    props.big &&
    css`
      font-size: ${size(1)};

      > ${Button}, a {
        font-weight: bold;
        padding: ${spacing()} ${spacing(2)};
      }
    `};

  > * {
    margin-left: ${spacing()};
    margin-bottom: ${spacing()};
  }

  ${props =>
    props.bareBottom &&
    css`
      margin-bottom: 0;
      > * {
        margin-bottom: 0;
      }
    `};
`;

export const LinkButton = styled.button`
  border: 0;
  padding: 0;
  text-decoration: ${props => (props.underlined ? 'underline' : 'none')};
  background-color: transparent;
  outline: none;
  cursor: pointer;

  ${props =>
    props.error &&
    css`
      svg {
        fill: ${ui('error')};
      }
    `};

  &:hover {
    opacity: 0.9;
  }
`;

export const Ul = styled(List)`
  margin-left: ${spacing(-1)};
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
`;

export const Li = styled.li`
  flex: 0 0 100%;
  padding-left: ${spacing()};
  padding-bottom: ${spacing()};
  display: flex;
  align-items: stretch;

  ${media.tablet`
    flex: 0 0 50%;
  `};

  ${media.medium`
    flex: 0 0 33.333%;
  `};
`;

export const Title = styled.h2`
  font-weight: 600;
  margin-top: 0;
  margin-bottom: ${spacing()};
  font-size: ${size(2)};

  ${props =>
    props.big &&
    css`
      font-size: ${size(4)};
    `};

  ${props =>
    props.noMargin &&
    css`
      margin-bottom: 0;
    `};
`;

export const Card = styled.div`
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid ${ui('separator')};
  padding: ${spacing()};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: ${spacing(0.5)};
`;

export const Cards = styled.div`
  margin-bottom: ${spacing(3)};
`;

export const CardTitle = styled.h2`
  margin-top: 0;
  margin-bottom: ${spacing(0.25)};
  font-size: ${size(2)};
  display: flex;
  align-items: center;
`;

export const CardContent = styled.div`
  flex: 1;
`;

export const CardActions = styled.div``;

export const CardMeta = styled.div`
  margin: 0;
  color: ${ui('textLight')};
`;

export const ButtonReset = styled.button`
  appearance: none;
  outline: none;
  padding: 0;
  background: transparent;
  border: 0;
  text-align: left;
  cursor: pointer;

  svg {
    display: flex;
  }
`;

const Address = styled.div`
  color: rgba(25, 75, 105, 0.6);
`;

export const getAddress = ({ streetNumber, street, zipCode, city }) => (
  <Address>
    {streetNumber} {street}
    <br />
    {zipCode} {city}
  </Address>
);

export const SwitchListItem = styled.li`
  position: relative;
  padding: ${spacing(0.5)} 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: opacity 150ms ease;

  + li:before {
    position: absolute;
    content: '';
    display: block;
    left: ${spacing()};
    top: 0;
    right: 0;
    border-top: 1px solid ${ui('separator')};
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.35;
    `};
`;

export { color, ui };

export const AccessibleButton = styled(ButtonReset)`
  display: inline-block;
  align-items: center;
  color: inherit;
  background: linear-gradient(to bottom, #fff, ${ui('background')});
  padding: ${spacing(0.25)} ${spacing(0.5)};
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  margin-left: ${spacing(0.5)};
  font-size: ${size(-1)};
  cursor: pointer;
  text-decoration: none;

  ${props =>
    props.medium &&
    css`
      padding: ${spacing(0.5)} ${spacing(0.75)};
      font-size: ${size()};
    `} ${props =>
    props.big &&
    css`
      padding: ${spacing(0.5)} ${spacing(0.75)};
      font-size: ${size(1)};
    `};
`;

export const AccessibleLinkButton = styled(
  ({ medium, big, children, ...props }) => <Link {...props}>{children}</Link>,
)`
  appearance: none;
  outline: none;
  padding: 0;
  background: transparent;
  border: 0;
  text-align: left;
  display: inline-block;
  align-items: center;
  color: inherit;
  background: linear-gradient(to bottom, #fff, ${ui('background')});
  padding: ${spacing(0.25)} ${spacing(0.5)};
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  margin-left: ${spacing(0.5)};
  font-size: ${size(-1)};
  cursor: pointer;
  text-decoration: none;

  ${props =>
    props.medium &&
    css`
      padding: ${spacing(0.5)} ${spacing(0.75)};
      font-size: ${size()};
    `} ${props =>
    props.big &&
    css`
      padding: ${spacing(0.5)} ${spacing(0.75)};
      font-size: ${size(1)};
    `};
`;

export const Description = styled.p`
  max-width: 45em;
  line-height: 1.5;
  white-space: pre-wrap;
`;

export const Section = styled.section`
  margin-top: ${spacing(2)};
  margin-bottom: ${spacing(2)};

  ${props =>
    props.padded &&
    css`
      padding: ${spacing()};
    `};

  ${props =>
    props.bareTop &&
    css`
      margin-top: 0;
    `};

  ${props =>
    props.bareBottom &&
    css`
      margin-bottom: 0;
    `};
`;

export const Media = styled.div`
  display: table;
  width: 100%;
`;

export const MediaImage = styled.div`
  display: table-cell;
  vertical-align: middle;
  width: 0;
  white-space: nowrap;

  ${props =>
    props.center &&
    css`
      vertical-align: middle;
    `};

  ${props =>
    props.top &&
    css`
      vertical-align: top;
    `};
`;

export const MediaBody = styled.div`
  display: table-cell;
  width: 100%;
  vertical-align: middle;
  padding-left: ${spacing()};
`;

export const PublicWorkConcernLabel = styled.p`
  font-weight: 500;
  color: ${ui('success')};
  font-size: ${size(1)};
`;

export const ActionButton = styled(ButtonReset)`
  background-color: ${({ theme }) => theme.separator};
  border-radius: 100%;
  color: ${({ theme }) => theme.primary};
  font-size: ${size(2)};
  padding: ${spacing(0.25)};

  ${props =>
    props.isReadOnly &&
    css`
      background-color: #fff;
      cursor: initial;
    `};

  ${props =>
    props.isActive &&
    `
      background-color: #fff;
    `};
`;

export const ButtonNew = styled.button`
  font-weight: 600;
  border: 1px solid rgba(0, 0, 0, 0.08);
  padding: ${spacing(0.5)} ${spacing()};
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  outline: none;
  background-color: #fff;
  color: ${({ theme }) => theme.primary};
  text-decoration: none;
  transition: box-shadow 100ms ease;

  &:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  }

  > svg {
    margin-right: ${spacing(0.25)};
    font-size: ${size(1)};
  }

  ${props =>
    props.isPrimary &&
    css`
      border: 0;
      box-shadow: ${({ theme }) => theme.primaryButtonBoxShadow};
      background: linear-gradient(
        to bottom,
        ${({ theme }) => theme.primaryButtonGradientOne},
        ${({ theme }) => theme.primaryButtonGradientTwo}
      );
      color: ${({ theme }) => theme.primaryButtonTextColor};

      &:hover {
        box-shadow: ${({ theme }) => theme.primaryButtonBoxShadowHover};
      }
      > svg {
        color: ${({ theme }) => theme.primaryButtonIconColor};
      }
    `};

  ${props =>
    props.big &&
    css`
      padding: ${spacing(0.75)} ${spacing()};
      font-size: ${size(1)};
    `};

  &:disabled {
    background: ${({ theme }) => theme.disabledBackground};
    color: ${({ theme }) => theme.disabledColor};
    cursor: not-allowed;

    &:hover {
      box-shadow: none;
    }
  }
`;
