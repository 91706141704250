import styled, { css } from 'styled-components';
import Avatar from './Avatar';
import { Media, MediaImage, MediaBody } from '@tymate/margaret';

const AuthorMedia = styled(Media)`
  ${({ size }) =>
    size === 'small' &&
    css`
      ${MediaBody} {
        font-size: 14px;
        padding-left: ${({ theme }) => theme.spacing(0.5)};
      }
    `}
`;

const Author = ({ data, children, size }) => (
  <AuthorMedia size={size} verticalAlign={Boolean(children) ? 'top' : 'center'}>
    <MediaImage>
      <Avatar
        displayName={data?.displayName}
        firstName={data?.firstName}
        lastName={data?.lastName}
        avatarUrl={data?.avatarUrl}
        size="small"
      />
    </MediaImage>
    <MediaBody>
      {data?.displayName}
      {Boolean(children) && <div>{children}</div>}
    </MediaBody>
  </AuthorMedia>
);

export default Author;
