export let API_BASE = process.env.REACT_APP_API_BASE;
export let MOCK_API_BASE = process.env.REACT_APP_MOCK_API_BASE;
export let CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export let CLIENT_ID_DIR = process.env.REACT_APP_CLIENT_ID_DIR;
export let CLIENT_ID_VSY = process.env.REACT_APP_CLIENT_ID_VSY;
export let CLIENT_ID_ECO = process.env.REACT_APP_CLIENT_ID_ECO;
export let CLIENT_ID_NEUF = process.env.REACT_APP_CLIENT_ID_NEUF;
export let CLIENT_ID_VQC = process.env.REACT_APP_CLIENT_ID_VQC;
export let SERGIC_OFFER = process.env.REACT_APP_SERGIC_OFFER;
export let ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export let GTM_ID = process.env.REACT_APP_GTM_ID;
export const GOOGLE_RECAPTCHA_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY;
export const SO_GENERAL_MEETING_WEBAPP_URL = process.env.REACT_APP_SO_GENERAL_MEETING_WEBAPP_URL;
export const ESE_URL = process.env.REACT_APP_ESE_URL;
export const VSY_URL = process.env.REACT_APP_VSY_URL;
export const DIR_URL = process.env.REACT_APP_DIR_URL;
export const ECO_URL = process.env.REACT_APP_ECO_URL;
export const VQC_URL = process.env.REACT_APP_VQC_URL;
export const VERSION = process.env.REACT_APP_VERSION;

export const PRESIDENT_BOARD_MEMBER = 'president_board_member';
export const COOWNERSHIP_BOARD_MEMBER = 'coownership_board_member';
export const COOWNER = 'coowner';
export const COOWNER_SECONDARY = 'coowner_secondary';
export const TENANT = 'tenant';
export const CARETAKER = 'caretaker';
export const EMPLOYEE = 'employee';
export const SERGIC_PARTNER = 'sergic_partner';
export const DIRECTOR = 'director';

if (ENVIRONMENT === 'development') {
   try {
      const debugStorage = JSON.parse(localStorage.getItem('debug')) || {};

      API_BASE = debugStorage.API_BASE || API_BASE;
      SERGIC_OFFER = debugStorage.SERGIC_OFFER || SERGIC_OFFER;
      CLIENT_ID = debugStorage.CLIENT_ID || CLIENT_ID;
   } catch (e) {}
}

// if (Boolean(localStorage.getItem('debug'))) {
//   localStorage.removeItem('debug');
//   window.location.reload();
// }

export const ERROR_MESSAGES = {
   403: 'Vous n’êtes pas autorisé à faire cette action.',
   404: "La page n'existe pas ou a été supprimée.",
};
