import styled, { css } from 'styled-components';
import { media } from '@tymate/margaret';

const Img = styled.img`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  width: 180px;
  max-width: 100%;
`;

export const Sidebar = styled.div`
  background-color: #fff;
  position: relative;
  width: 600px;
  max-width: 100%;

  ${media.tablet`
    min-height: 100%;
    border-right: 1px solid ${({ theme }) => theme.separator};
  `};

  ${props =>
    props.multi &&
    props.one &&
    css`
      position: relative;
      z-index: 2;
      box-shadow: none;
    `};

  ${props =>
    props.two &&
    css`
      ${media.tablet`flex: 0 0 300px;`};
    `};

  ${props =>
    props.isLarge &&
    css`
      ${media.tablet`min-width: 300px;`};
      ${media.desktop`min-width: 420px;`};
    `};
`;

export const SecondWrapper = styled.div`
  ${media.tablet`
    display: flex;
    align-items: stretch;
  `};
`;

export const Container = styled.div`
  padding: ${({ theme }) => theme.spacing(2)};
`;

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  min-height: 100vh;
  position: relative;
  z-index: 1;
`;

export const Background = styled.div`
  background-image: url(${({ background }) => background});
  flex: 1;
  background-size: cover;
  background-position: center center;
`;

const AuthLayout = ({ logo, background, children }) => (
  <Wrapper>
    <SecondWrapper style={{ minHeight: '100vh' }}>
      <Sidebar>
        <Container hasVerticalPadding style={{ minHeight: '100vh' }}>
          <Img src={logo} alt="Syndic One" />
          {children}
        </Container>
      </Sidebar>
      <Background background={background} />
    </SecondWrapper>
  </Wrapper>
);

export default AuthLayout;
