import { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import Animate from 'react-move/Animate';
import { easePolyOut } from 'd3-ease';
import { spacing } from 'ui';
import { dismissSnack } from 'actions/snacks';

const Wrapper = styled.dialog`
  position: fixed;
  bottom: 0;
  transform: ${props => (props.active ? 'translateY(0)' : 'translateY(100%)')};
  margin-left: auto;
  margin-right: auto;
  background-color: #323232;
  color: #eef6f6;
  transition: transform 200ms ease-in-out;
  z-index: 2147483647; /*Maximum possible*/
  padding: ${spacing()};
  border: 0;
  -webkit-backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  -webkit-transform-style: preserve-3d;
  display: table;
  min-width: 20em;
  max-width: 100%;
  align-items: center;

  @media screen and (min-width: 900px) {
    max-width: 800px;
  }
`;

const Message = styled.p`
  margin: 0;
  display: table-cell;
  vertical-align: middle;
  white-space: pre-line;
`;

const Action = styled.button`
  border: 0;
  white-space: nowrap;
  vertical-align: middle;
  white-space: nowrap;
  text-transform: uppercase;
  padding-left: ${spacing(2)};
  font-weight: 600;
  outline: none;
  color: #fff;
  border: 0;
  background-color: transparent;
`;

class SnackBar extends Component {
  constructor() {
    super();

    this.state = {
      activeSnack: {},
      snackbarIsActive: false,
    };

    this.handleWillDismiss = this.handleWillDismiss.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const nextSnackId = nextProps.snacks.queue[0];
    const currentSnackId = this.props.snacks.queue[0];

    if (nextSnackId !== currentSnackId) {
      const nextSnack = nextProps.snacks.byId[nextSnackId];
      const switchActiveIn = currentSnackId ? 300 : 0;

      if (!currentSnackId) {
        this.setState({
          activeSnack: nextSnack,
          snackbarIsActive: true,
        });

        this.handleWillDismiss(nextSnack);
        return;
      }

      if (isEmpty(nextSnack)) {
        this.setState({ snackbarIsActive: false });
        setTimeout(
          () =>
            this.setState({
              activeSnack: {},
            }),
          switchActiveIn,
        );
      } else {
        this.setState({ snackbarIsActive: false });
        setTimeout(
          () =>
            this.setState({
              activeSnack: nextSnack,
              snackbarIsActive: true,
            }),
          switchActiveIn,
        );
        this.handleWillDismiss(nextSnack);
      }
    }
  }

  handleWillDismiss(snack) {
    if (snack.duration) {
      setTimeout(() => this.handleDismiss(snack.id), snack.duration);
    }
  }

  handleDismiss(snackId) {
    this.props.dispatchDismissSnack(snackId);
  }

  render() {
    const { activeSnack, snackbarIsActive } = this.state;
    const { type } = activeSnack;

    return (
      <Animate
        show={snackbarIsActive}
        start={{ translateY: 100 }}
        enter={{
          translateY: [0],
          timing: { duration: 100, ease: easePolyOut },
        }}
        leave={{
          translateY: [100],
          timing: { duration: 100, ease: easePolyOut },
        }}
      >
        {({ translateY }) => (
          <Wrapper
            style={{ transform: `translateY(${translateY}px)` }}
            aria-hidden={!snackbarIsActive}
            open={snackbarIsActive}
            active={snackbarIsActive}
            type={type}
          >
            <Message>{activeSnack.message}</Message>

            <Action
              disabled={!snackbarIsActive}
              onClick={() => this.handleDismiss(activeSnack.id)}
              type={activeSnack.type}
            >
              {activeSnack.action || 'ok'}
            </Action>
          </Wrapper>
        )}
      </Animate>
    );
  }
}

const mapStateToProps = state => ({
  snacks: state.snacks,
});

const mapDispatchToProps = dispatch => ({
  dispatchDismissSnack: id => dispatch(dismissSnack(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SnackBar);
