import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import { API_BASE, MOCK_API_BASE } from 'client/src/constants';


const client = new ApolloClient({
  uri: operation => operation.variables.mock ? `${MOCK_API_BASE}/graphql/${operation.operationName}` : `${API_BASE}/graphql`,
  request: operation => {
    const state = JSON.parse(localStorage.getItem('state'));
    const accessToken = localStorage.getItem('accessToken');

    const hasToken =
      Boolean(state?.user?.accessToken) ||
      Boolean(state?.user?.impersonatedUser?.accessToken) ||
      Boolean(accessToken);

    if (hasToken) {
      operation.setContext({
        headers: {
          authorization: `Bearer ${
            state?.user?.impersonatedUser?.accessToken ||
            state.user.accessToken ||
            accessToken
          }`,
        },
      });
    }
  },
});

client.defaultOptions = {
  query: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
};

const GraphQLProvider = ({ children }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);

export default GraphQLProvider;
