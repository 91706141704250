import styled, { css } from 'styled-components';
import { NavLink, Link } from 'react-router-dom';
import { InlineList } from '../ui/base';
import { media, ButtonReset, Stack } from '@tymate/margaret';
import Spinner from './Spinner';
import tinycolor from 'tinycolor2';

export const PillsNavBar = styled.nav`
  display: flex;
  padding: ${({ theme }) => theme.spacing(0.875)}
    ${({ theme }) => theme.spacing()};
  justify-content: flex-start;
  background-color: #fff;
  max-width: 100vw;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  white-space: nowrap;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);

  ${media.medium`
    flex-wrap: wrap;
    justify-content: center;
    box-shadow: none;
    padding: ${({ theme }) => theme.spacing(0.875)}
      ${({ theme }) => theme.spacing(2)};

    ${({ align }) =>
      align === 'left' &&
      css`
        justify-content: flex-start;
      `}
  `};
`;

export const PillItem = styled.li`
  white-space: nowrap;
`;

export const PillButton = styled(ButtonReset)`
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.separator};
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(0.75)};
  display: block;
  border-radius: ${({ theme }) => theme.borderRadius};
  color: ${({ theme }) => theme.textLight};
  text-decoration: none;
  transition: box-shadow 150ms ease, background 150ms ease, color 150ms ease;
  font-weight: 400;
  line-height: 1.5;

  &:hover {
    color: ${({ theme }) => theme.text};
  }

  &.active,
  &.active:hover {
    background-color: ${({ theme, activeBackgroundColor }) =>
      tinycolor(activeBackgroundColor || theme.primary)
        .setAlpha(0.12)
        .toString()};
    box-shadow: inset 0 0 0 1px
      ${({ theme, activeBackgroundColor }) =>
        tinycolor(activeBackgroundColor || theme.primary)
          .setAlpha(0.12)
          .toString()};
    color: ${({ theme, activeBackgroundColor }) =>
      activeBackgroundColor || theme.primary};

    ${({ variant }) =>
      variant === 'success' &&
      css`
        background-color: ${({ theme }) =>
          tinycolor(theme.success)
            .setAlpha(0.12)
            .toString()};
        box-shadow: inset 0 0 0 1px
          ${({ theme }) =>
            tinycolor(theme.success)
              .setAlpha(0.12)
              .toString()};
        color: ${({ theme }) => theme.success};
      `}

    ${({ variant }) =>
      variant === 'error' &&
      css`
        background-color: ${({ theme }) =>
          tinycolor(theme.error)
            .setAlpha(0.12)
            .toString()};
        box-shadow: inset 0 0 0 1px
          ${({ theme }) =>
            tinycolor(theme.error)
              .setAlpha(0.12)
              .toString()};
        color: ${({ theme }) => theme.error};
      `};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      &,
      &:hover {
        background: ${({ theme }) => theme.disabledBackground};
        color: ${({ theme }) => theme.textLight};
        cursor: default;
      }
    `}
`;

export const Pills = styled(InlineList)`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;

  > li + li {
    margin-left: ${({ theme }) => theme.spacing(0.5)};
  }
`;

export const Pill = ({
  to,
  onClick,
  isActive,
  isLoading,
  children,
  ...props
}) => (
  <PillItem>
    <PillButton
      type={!Boolean(to) ? 'button' : null}
      as={
        Boolean(to)
          ? typeof isActive === 'undefined' || isActive === null
            ? NavLink
            : Link
          : null
      }
      to={to}
      className={isActive && 'active'}
      onClick={onClick}
      disabled={isLoading}
      {...props}
    >
      <Stack alignY="center">
        {isLoading && <Spinner />}
        <div>{children}</div>
      </Stack>
    </PillButton>
  </PillItem>
);
