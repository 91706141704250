import { createAction } from 'redux-actions';
import { camelizeKeys } from 'humps';
import * as Api from 'api/user';
import { sendImportantSnack, sendSnack } from './snacks';
import { user } from 'api/schema';
import { normalize } from 'utils';

/**
 * Login / Refresh / Logout
 */

const requestLogin = createAction('LOGIN_REQUEST');
export const receiveLogin = createAction('LOGIN_SUCCESS');
const failLogin = createAction('LOGIN_ERROR');

export const login = payload => dispatch => {
   dispatch(requestLogin());

   return Api.login(payload).then(
      response => dispatch(receiveLogin(response)),
      error => {
         dispatch(
            sendImportantSnack({
               type: 'error',
               duration: 5000,
               message: 'La combinaison email / mot de passe est invalide',
               action: 'OK',
            })
         );
         dispatch(failLogin(error));
      }
   );
};

const receiveRefreshToken = createAction('REFRESH_TOKEN_SUCCESS');
const failRefreshToken = createAction('REFRESH_TOKEN_ERROR');

export const refreshToken = () => dispatch => {
   const { refreshToken } = JSON.parse(localStorage.getItem('state')).user;
   if (!refreshToken) return;

   return Api.refreshToken(refreshToken).then(
      response => dispatch(receiveRefreshToken(response)),
      error => dispatch(failRefreshToken(error))
   );
};

export const logout = createAction('LOGOUT');

/**
 * Signup
 */

const requestSignup = createAction('SIGNUP_REQUEST');
const receiveSignup = createAction('SIGNUP_SUCCESS');
// const failSignup = createAction('SIGNUP_ERROR');

export const signup = username => dispatch => {
   dispatch(requestSignup());

   return Api.signup(username).then(
      response => dispatch(receiveSignup(response)),
      error => {
         // TODO(Henri@22-06-17)
         // An empty 200 caused the error being dispatched
         // event upon success. Fix when fixed.
         dispatch(receiveSignup());
         // dispatch(failSignup(error));
      }
   );
};

/**
 * User info
 */

const requestMe = createAction('ME_REQUEST');
const receiveMe = createAction('ME_SUCCESS');
const failMe = createAction('ME_ERROR');

export const getMe = () => dispatch => {
   dispatch(requestMe());

   return Api.getMe().then(
      response => {
         if (Boolean(window.zE)) {
            window.zE('webWidget', 'prefill', {
               name: {
                  value: response.display_name,
                  readOnly: true,
               },
               email: {
                  value: response.email,
                  readOnly: true,
               },
            });
         }

         dispatch(receiveMe(normalize(response, user)));
      },
      error => dispatch(failMe(error))
   );
};

const requestUpdateMe = createAction('UPDATE_ME_REQUEST');
const receiveUpdateMe = createAction('UPDATE_ME_SUCCESS');
const failUpdateMe = createAction('UPDATE_ME_ERROR');

export const putMe = payload => dispatch => {
   dispatch(requestUpdateMe());

   return Api.putMe(payload).then(
      response => {
         dispatch(
            sendSnack({
               type: 'success',
               duration: 3000,
               message: 'Votre profil a été mis à jour',
               action: 'OK',
            })
         );
         dispatch(receiveUpdateMe(normalize(response, user)));
      },
      error => dispatch(failUpdateMe(error))
   );
};

const requestPrivacySettings = createAction('PRIVACY_SETTINGS_REQUEST');
const receivePrivacySettings = createAction('PRIVACY_SETTINGS_SUCCESS');
const failPrivacySettings = createAction('PRIVACY_SETTINGS_ERROR');

export const getMePrivacySettings = () => dispatch => {
   dispatch(requestPrivacySettings());

   return Api.getMePrivacySettings().then(
      response => dispatch(receivePrivacySettings(camelizeKeys(response))),
      error => dispatch(failPrivacySettings(error))
   );
};

const requestUpdatePrivacySettings = createAction('UPDATE_PRIVACY_SETTINGS_REQUEST');
const receiveUpdatePrivacySettings = createAction('UPDATE_PRIVACY_SETTINGS_SUCCESS');
const failUpdatePrivacySettings = createAction('UPDATE_PRIVACY_SETTINGS_ERROR');

export const putMePrivacySettings = payload => dispatch => {
   dispatch(requestUpdatePrivacySettings());

   return Api.putMePrivacySettings(payload).then(
      response => {
         dispatch(
            sendSnack({
               type: 'success',
               duration: 3000,
               message: 'Les paramètres de confidentialité ont été mis à jour',
               action: 'OK',
            })
         );
         dispatch(receiveUpdatePrivacySettings(camelizeKeys(response)));
      },
      error => dispatch(failUpdatePrivacySettings(error))
   );
};

const requestImpersonateUser = createAction('IMPERSONATE_USER_REQUEST');
const receiveImpersonateUser = createAction('IMPERSONATE_USER_SUCCESS');
const failImpersonateUser = createAction('IMPERSONATE_USER_ERROR');

export const impersonateUser = userId => dispatch => {
   dispatch(requestImpersonateUser());

   return Api.impersonateUser(userId).then(
      response => dispatch(receiveImpersonateUser({ userId, ...camelizeKeys(response) })),
      error => dispatch(failImpersonateUser(error))
   );
};

export const resetImpersonatedUser = createAction('RESET_IMPERSONATED_USER_REQUEST');

const requestUserIdentities = createAction('USER_IDENTITIES_REQUEST');
const receiveUserIdentities = createAction('USER_IDENTITIES_SUCCESS');
const failUserIdentities = createAction('USER_IDENTITIES_ERROR');

export const getUserIdentities = placeId => dispatch => {
   dispatch(requestUserIdentities());

   return Api.getUserIdentities(placeId).then(
      data => dispatch(receiveUserIdentities({ data, placeId })),
      error => dispatch(failUserIdentities(error))
   );
};

const requestUpdateIdentity = createAction('UPDATE_IDENTITY_REQUEST');
const receiveUpdateIdentity = createAction('UPDATE_IDENTITY_SUCCESS');
const failUpdateIdentity = createAction('UPDATE_IDENTITY_ERROR');

export const updateIdentity = (id, payload) => dispatch => {
   dispatch(requestUpdateIdentity());

   return Api.updateIdentity(id, payload).then(
      response => dispatch(receiveUpdateIdentity(response)),
      error => {
         dispatch(failUpdateIdentity(error));
         throw Error(JSON.stringify(error));
      }
   );
};
