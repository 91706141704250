import { camelizeKeys } from 'humps';
import get from 'lodash/get';
import { merge } from 'utils';

const initialState = {
  isFetching: false,
  isAuthenticated: false,
  accessToken: null,
  expiresIn: null,
  refreshToken: null,
  createdAt: null,
  userId: null,
  privacySettings: {},
  impersonatedUser: null,
  identityById: {},
  identitiesIdsByPlaceId: {},
  error: null,
};

const user = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case 'LOGIN_REQUEST':
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
      };

    case 'LOGIN_SUCCESS':
    case 'REFRESH_TOKEN_SUCCESS':
      return {
        ...state,
        ...camelizeKeys(payload),
        isAuthenticated: true,
        isFetching: false,
        error: null,
      };

    case 'ME_SUCCESS':
      return {
        ...state,
        userId: payload.result,
      };

    case 'PRIVACY_SETTINGS_SUCCESS':
    case 'UPDATE_PRIVACY_SETTINGS_SUCCESS':
      return {
        ...state,
        privacySettings: payload,
      };

    case 'IMPERSONATE_USER_SUCCESS':
      return {
        ...state,
        impersonatedUser: payload,
      };

    case 'RESET_IMPERSONATED_USER_REQUEST':
      return {
        ...state,
        impersonatedUser: null,
      };

    case 'PLACES_SUCCESS':
    case 'POST_PLACE_DEFAULT_IDENTITIES_SUCCESS':
    case 'UPDATE_IDENTITY_SUCCESS':
      return {
        ...state,
        identityById: merge(
          state.identityById,
          get(payload, 'entities.identities'),
        ),
      };

    case 'USER_IDENTITIES_SUCCESS':
      return {
        ...state,
        identitiesIdsByPlaceId: {
          ...state.identitiesIdsByPlaceId,
          [payload.placeId]: payload.data.result,
        },
        identityById: merge(
          state.identityById,
          get(payload, 'data.entities.identities'),
        ),
      };

    case 'USER_CONTRACTS_SUCCESS':
    case 'CONTRACT_SUCCESS':
      return {
        ...state,
        identityById: merge(
          state.identityById,
          get(payload, 'data.entities.identities'),
        ),
      };

    case 'LOGIN_ERROR':
      return {
        ...initialState,
        error: payload.explanation,
      };

    case 'REFRESH_TOKEN_ERROR':
    case 'LOGOUT':
      return initialState;

    default:
      return state;
  }
};

export default user;
