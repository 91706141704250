import { get } from 'lodash';
import { camelizeKeys } from 'humps';
import { addLocalPaginationToGlobalState, merge } from 'utils';

const initialState = {
  isFetching: false,
  hasFailed: false,
  placeById: {},
  placesIds: [],
  placesPagination: {},
  equipmentById: {},
  equipmentsIdsByPlaceId: [],
  budgetById: {},
  budgetsIdsByPlaceIdAndByFiscalYearId: {},
  treasuryById: {},
  treasuriesIdsByPlaceId: {},
  coownershipDocumentById: {},
  coownershipFolderById: {},
  coownershipFoldersIdsByPlaceId: {},
  coownershipFoldersPagination: {},
  coownershipDocumentsPagination: {},
  maintenanceContractById: {},
  maintenanceContractDocumentById: {},
  maintenanceContractsCategoryById: {},
  maintenanceContractsCategoriesIdsByPlaceId: {},
  maintenanceContractsIdByMaintenanceContractsCategoryId: {},
  fiscalYearsIdsByPlaceId: {},
  fiscalYearById: {},
  invitationsByPlaceId: {},
  notificationById: {},
  notificationsIdsByPlaceId: {},
  highlightedNotificationsIdsByPlaceId: {},
  agencyByPlaceId: {},
  distributionListById: {},
  distributionListsIdsByPlaceId: {},
  treasuriesRequestsStatusesByPlaceId: {},
  rssFeedById: {},
  rssFeedsIdsByPlaceId: {},
  notificationsPagination: {},
};

const places = (state = initialState, action) => {
  const { type, payload, meta } = action;
  let nextState = { ...state };

  switch (type) {
    case 'PLACES_REQUEST':
      return {
        ...state,
        hasFailed: false,
        isFetching: true,
      };

    case 'PLACES_ERROR':
      return {
        ...state,
        hasFailed: true,
        isFetching: false,
      };

    case 'PLACES_SUCCESS':
      return {
        ...state,
        isFetching: false,
        hasFailed: false,
        placeById: merge(state.placeById, get(payload, 'entities.places')),
        placesIds: [...new Set([...state.placesIds, ...payload.result])],
        placesPagination: {
          page: get(meta, 'page'),
          nextPage: get(meta, 'nextPage'),
          total: get(meta, 'total'),
          perPage: get(meta, 'perPage'),
          lastPage: Math.ceil(
            Number(get(meta, 'total')) / Number(get(meta, 'perPage')),
          ),
        },
      };

    case 'UPDATE_PLACE_SUCCESS':
      return {
        ...state,
        placeById: merge(state.placeById, get(payload, 'entities.places')),
      };

    case 'PLACE_SUCCESS':
    case 'PLACE_NOTIFICATIONS_COUNT_SUCCESS':
      return {
        ...state,
        placeById: merge(state.placeById, get(payload, 'entities.places')),
      };

    case 'PLACE_EQUIPMENTS_SUCCESS':
      return {
        ...state,
        placeById: {
          ...state.placeById,
          [payload.id]: {
            ...state.placeById[payload.id],
            isFetchingEquipments: false,
          },
        },
        equipmentById: {
          ...state.equipmentById,
          ...get(payload, 'data.entities.equipments'),
        },
        equipmentsIdsByPlaceId: {
          ...state.equipmentsIdsByPlaceId,
          [payload.id]: get(payload, 'data.result'),
        },
      };

    case 'PLACE_BUDGETS_SUCCESS':
      return {
        ...state,
        budgetById: merge(
          state.budgetById,
          get(payload, 'data.entities.budgets'),
        ),
        budgetsIdsByPlaceIdAndByFiscalYearId: {
          ...state.budgetsIdsByPlaceIdAndByFiscalYearId,
          [payload.placeId]: {
            ...(state.budgetsIdsByPlaceIdAndByFiscalYearId[payload.placeId] ||
              {}),
            [payload.fiscalYearId]: payload.data.result,
          },
        },
      };

    case 'PLACE_TREASURIES_REQUEST':
      return {
        ...state,
        treasuriesRequestsStatusesByPlaceId: {
          ...state.treasuriesRequestsStatusesByPlaceId,
          [payload.placeId]: 'pending',
        },
      };

    case 'PLACE_TREASURIES_SUCCESS':
      return {
        ...state,
        treasuryById: merge(
          state.treasuryById,
          get(payload, 'data.entities.treasuries'),
        ),
        treasuriesIdsByPlaceId: {
          ...state.treasuriesIdsByPlaceId,
          [payload.placeId]: payload.data.result,
        },
        treasuriesRequestsStatusesByPlaceId: {
          ...state.treasuriesRequestsStatusesByPlaceId,
          [payload.placeId]: 'done',
        },
      };

    case 'PLACE_TREASURIES_ERROR':
      return {
        ...state,
        treasuriesRequestsStatusesByPlaceId: {
          ...state.treasuriesRequestsStatusesByPlaceId,
          [payload.placeId]: 'stalled',
        },
      };

    case 'PLACE_COOWNERSHIP_FOLDERS_REQUEST':
      addLocalPaginationToGlobalState({
        state: nextState.coownershipFoldersPagination,
        ...action,
        status: 'PENDING',
      });
      return nextState;

    case 'PLACE_COOWNERSHIP_FOLDERS_SUCCESS':
      addLocalPaginationToGlobalState({
        state: nextState.coownershipFoldersPagination,
        payload,
        meta,
        status: 'DONE',
      });

      return {
        ...nextState,
        coownershipDocumentById: merge(
          state.coownershipDocumentById,
          payload?.data?.entities?.coownershipDocuments,
        ),
        coownershipFolderById: merge(
          state.coownershipFolderById,
          payload?.data?.entities?.coownershipFolders,
        ),
      };

    case 'PLACE_COOWNERSHIP_FOLDERS_ERROR':
      addLocalPaginationToGlobalState({
        state: state.coownershipFoldersPagination,
        ...action,
        status: 'STALLED',
      });
      return nextState;

    case 'COOWNERSHIP_FOLDER_DOCUMENTS_REQUEST':
      addLocalPaginationToGlobalState({
        state: state.coownershipDocumentsPagination,
        ...action,
        status: 'PENDING',
      });
      return nextState;

    case 'COOWNERSHIP_FOLDER_DOCUMENTS_SUCCESS':
      addLocalPaginationToGlobalState({
        state: state.coownershipDocumentsPagination,
        ...action,
        status: 'DONE',
      });
      return {
        ...nextState,
        coownershipDocumentById: merge(
          state.coownershipDocumentById,
          payload?.data?.entities?.coownershipDocuments,
        ),
      };

    case 'COOWNERSHIP_FOLDER_DOCUMENTS_ERROR':
      addLocalPaginationToGlobalState({
        state: state.coownershipDocumentsPagination,
        ...action,
        status: 'STALLED',
      });
      return nextState;

    case 'PLACE_MAINTENANCE_CONTRACTS_CATEGORIES_SUCCESS':
      return {
        ...state,
        maintenanceContractsCategoryById: merge(
          state.maintenanceContractsCategoryById,
          get(payload, 'data.entities.maintenanceContractsCategories'),
        ),
        maintenanceContractsCategoriesIdsByPlaceId: {
          ...state.maintenanceContractsCategoriesIdsByPlaceId,
          [payload.placeId]: payload.data.result,
        },
      };

    case 'PLACE_INVITATIONS_SUCCESS':
      return {
        ...state,
        invitationsByPlaceId: {
          ...state.invitationsByPlaceId,
          [payload.placeId]: camelizeKeys(payload.data),
        },
      };

    case 'PLACE_MAINTENANCE_CONTRACTS_CATEGORY_SUCCESS':
      return {
        ...state,
        maintenanceContractDocumentById: merge(
          state.maintenanceContractDocumentById,
          get(payload, 'data.entities.maintenanceContractDocuments'),
        ),
        maintenanceContractById: merge(
          state.maintenanceContractById,
          get(payload, 'data.entities.maintenanceContracts'),
        ),
        maintenanceContractsIdByMaintenanceContractsCategoryId: {
          ...state.maintenanceContractsIdByMaintenanceContractsCategoryId,
          [payload.contractId]: payload.data.result,
        },
      };

    case 'PLACE_FISCAL_YEARS_SUCCESS':
      return {
        ...state,
        fiscalYearById: merge(
          state.fiscalYearById,
          get(payload, 'data.entities.fiscalYears'),
        ),
        fiscalYearsIdsByPlaceId: {
          ...state.fiscalYearById,
          [payload.placeId]: payload.data.result,
        },
      };

    case 'PLACE_RSS_FEEDS_SUCCESS':
      return {
        ...state,
        rssFeedById: merge(
          state.rssFeedById,
          get(payload, 'data.entities.rssFeeds'),
        ),
        rssFeedsIdsByPlaceId: {
          ...state.rssFeedsIdsByPlaceId,
          [payload.placeId]: payload.data.result,
        },
      };

    case 'PLACE_NOTIFICATIONS_SUCCESS':
      addLocalPaginationToGlobalState({
        state: nextState.notificationsPagination,
        payload,
        meta,
        status: 'DONE',
      });

      return {
        ...nextState,
        notificationById: merge(
          state.notificationById,
          get(payload, 'data.entities.notifications'),
        ),
      };

    case 'PLACE_HIGHLIGHTED_NOTIFICATIONS_SUCCESS':
      return {
        ...state,
        notificationById: merge(
          state.notificationById,
          get(payload, 'data.entities.notifications'),
        ),
        highlightedNotificationsIdsByPlaceId: {
          ...state.highlightedNotificationsIdsByPlaceId,
          [payload.placeId]: payload.data.result,
        },
      };

    case 'PLACE_AGENCY_SUCCESS':
      return {
        ...state,
        agencyByPlaceId: {
          ...state.agencyByPlaceId,
          [payload.placeId]:
            payload.data.entities.agencies[payload.data.result],
        },
      };

    case 'DISTRIBUTION_LISTS_SUCCESS':
      return {
        ...state,
        distributionListById: merge(
          state.distributionListById,
          get(payload, 'data.entities.distributionLists'),
        ),
        distributionListsIdsByPlaceId: {
          ...state.distributionListsIdsByPlaceId,
          [payload.placeId]: get(payload, 'data.result'),
        },
      };

    case 'BUDGET_SUCCESS':
      return {
        ...state,
        budgetById: merge(
          state.budgetById,
          get(payload, 'data.entities.budgets'),
        ),
      };

    case 'POST_PLACE_DEFAULT_IDENTITIES_SUCCESS':
      return {
        ...state,
        placeById: {
          ...state.placeById,
          [payload.placeId]: {
            ...state.placeById[payload.placeId],
            defaultIdentity: payload.data.result,
          },
        },
      };

    case 'LOGOUT':
    case 'RESET_IMPERSONATED_USER_REQUEST':
      return initialState;

    default:
      return state;
  }
};

export default places;
