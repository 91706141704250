import { SERGIC_OFFER } from '../constants';
import chrome from '../images/chrome.svg';
import firefox from '../images/firefox.svg';
import logoEseis from '../images/eseis-colored.svg';
import logoSyndicOne from '../images/logo-syndic-one-colored.svg';
import logoViva from '../images/logo-viva-colored.svg';
import logoEcopro from '../images/logo-ecopro-colored.svg';
import logoNeuf from '../images/logo-neuf-colored.svg';
import 'ie.css';
import { withTranslation } from 'react-i18next';

const getAppName = offer => {
  switch (offer || SERGIC_OFFER) {
    case 'ESE':
      return 'éseis Syndic';
    case 'VSY':
      return 'Viva Syndic';
    case 'DIR':
      return 'Syndic One';
    case 'ECO':
      return 'e-Copro';
    default:
      return 'Sergic';
  }
};

const getLogo = () => {
  switch (SERGIC_OFFER) {
    case 'ESE':
      return logoEseis;
    case 'VSY':
      return logoViva;
    case 'ECO':
      return logoEcopro;
    case 'DIR':
      return logoSyndicOne;
    default:
      return logoNeuf;
  }
};

const IE = ({ t }) => (
  <div className="is-ie">
    <img src={getLogo()} alt={getAppName()} />
    <h1>{t('title')}</h1>
    <p>{t('description')}</p>
    <a
      className="c-browser-link"
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.google.com/intl/fr_fr/chrome/"
    >
      <img className="c-browser-link__logo" src={chrome} alt="Google Chrome" />{' '}
      Google Chrome
    </a>
    <a
      className="c-browser-link"
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.mozilla.org/fr/firefox/"
    >
      <img
        className="c-browser-link__logo"
        src={firefox}
        alt="Mozilla Firefox"
      />{' '}
      Mozilla Firefox
    </a>
  </div>
);

export default withTranslation('internetExplorer')(IE);
