import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as form } from 'redux-form';
import user from './user';
import users from './users';
import places from './places';
import snacks from './snacks';
import reports from './reports';
import contracts from './contracts';
import conversations from './conversations';
import feed from './feed';
import accounts from './accounts';
import ui from './ui';
import support from './support';
import forum from './forum';
import { connectRouter } from 'connected-react-router';
import expenses from './expenses';

const reducers = history =>
   combineReducers({
      router: connectRouter(history),
      routing,
      form,
      user,
      snacks,
      places,
      reports,
      ui,
      users,
      conversations,
      contracts,
      accounts,
      feed,
      support,
      forum,
      expenses,
   });

export default reducers;
