import styled from 'styled-components';
import { ButtonReset } from '@tymate/margaret';

export const ActionButton = styled(ButtonReset)`
  background-color: ${({ theme }) => theme.separator};
  border-radius: 100%;
  color: ${({ theme }) => theme.primary};
  font-size: ${({ theme }) => theme.size(2)};
  padding: ${({ theme }) => theme.spacing(0.25)};

  svg {
    display: flex;
  }

  ${props =>
    props.isReadOnly &&
    css`
      background-color: #fff;
      cursor: initial;
    `};

  ${props =>
    props.isActive &&
    `
      background-color: #fff;
    `}
`;

export const ButtonNew = styled.button`
  font-weight: 600;
  border: 1px solid rgba(0, 0, 0, 0.08);
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing()};
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  outline: none;
  background-color: #fff;
  color: ${({ theme }) => theme.primary};
  text-decoration: none;
  transition: box-shadow 100ms ease;

  &:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  }

  > svg {
    margin-right: ${({ theme }) => theme.spacing(0.25)};
    font-size: ${({ theme }) => theme.size(1)};
  }

  ${props =>
    props.isPrimary &&
    css`
      border: 0;
      box-shadow: ${({ theme }) => theme.primaryButtonBoxShadow};
      background: linear-gradient(
        to bottom,
        ${({ theme }) => theme.primaryButtonGradientOne},
        ${({ theme }) => theme.primaryButtonGradientTwo}
      );
      color: ${({ theme }) => theme.primaryButtonTextColor};

      &:hover {
        box-shadow: ${({ theme }) => theme.primaryButtonBoxShadowHover};
      }
      > svg {
        color: ${({ theme }) => theme.primaryButtonIconColor};
      }
    `};

  ${props =>
    props.big &&
    css`
      padding: ${({ theme }) => theme.spacing(0.75)}
        ${({ theme }) => theme.spacing()};
      font-size: ${({ theme }) => theme.size(1)};
    `};

  &:disabled {
    background: ${({ theme }) => theme.disabledBackground};
    color: ${({ theme }) => theme.disabledColor};
    cursor: not-allowed;

    &:hover {
      box-shadow: none;
    }
  }
`;
