import get from 'lodash/get';
import { merge } from 'utils';
import { generatePagination } from 'utils/pagination';

const initialState = {
  conversationById: {},
  conversationsPagination: {},
  messagesIdsByConversationId: {},
  messageById: {},
};

const conversations = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case 'PLACE_CONVERSATIONS_SUCCESS':
      return {
        ...state,
        conversationById: merge(
          state.conversationById,
          get(payload, 'data.entities.conversations'),
        ),
        conversationsPagination: {
          ...state.conversationsPagination,
          [payload.placeId]: merge(
            get(state, `conversationsPagination[${payload.placeId}]`),
            generatePagination(
              state.conversationsPagination,
              payload.placeId,
              action,
            ),
          ),
        },
      };
    case 'CONVERSATION_SUCCESS':
    case 'UPDATE_CONVERSATION_LAST_READ_MESSAGE_SUCCESS':
    case 'UPDATE_CONVERSATION_SUCCESS':
      return {
        ...state,
        conversationById: merge(
          state.conversationById,
          get(payload, 'entities.conversations'),
        ),
      };
    case 'CONVERSATION_MESSAGES_SUCCESS':
      return {
        ...state,
        messagesIdsByConversationId: {
          ...state.messagesIdsByConversationId,
          [payload.conversationId]: payload.data.result,
        },
        messageById: merge(
          state.messageById,
          get(payload, 'data.entities.messages'),
        ),
      };
    case 'POST_CONVERSATION_MESSAGE_SUCCESS':
      return {
        ...state,
        messageById: merge(
          state.messageById,
          get(payload, 'data.entities.messages'),
        ),
        messagesIdsByConversationId: {
          ...state.messagesIdsByConversationId,
          [payload.conversationId]: [get(payload, 'data.result')].concat(
            state.messagesIdsByConversationId[payload.conversationId] || [],
          ),
        },
        conversationById: {
          ...state.conversationById,
          [payload.conversationId]: {
            ...state.conversationById[payload.conversationId],
            preview: get(
              payload,
              `data.entities.messages.${payload.data.result}.body`,
            ),
            lastReadMessageId: get(
              payload,
              `data.entities.messages.${payload.data.result}.id`,
            ),
            updatedAt: get(
              payload,
              `data.entities.messages.${payload.data.result}.createdAt`,
            ),
          },
        },
      };
    case 'UPDATE_CONVERSATION_DISPLAY_NAME_SUCCESS':
      return {
        ...state,
        conversationById: merge(
          state.conversationById,
          get(payload, 'entities.conversations'),
        ),
      };
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
};

export default conversations;
