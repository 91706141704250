import { createBreakpoint } from 'react-use';
import { viewportSizes } from '@tymate/margaret';

const breakpointHook = createBreakpoint({ ...viewportSizes, default: 0 });

export const useBreakpoint = () => {
  const breakpoint = breakpointHook();

  return {
    breakpoint,
    isMobile: breakpoint === 'default',
  };
};
