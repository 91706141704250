import { Component } from 'react';
import styled from 'styled-components';
import { ButtonNew, Buttons, Card as RawCard, Container, Title } from '../ui';
import { Logo } from 'containers/Auth';
import { withTranslation } from 'react-i18next';

const Card = styled(RawCard)`
   flex-direction: column;
`;

const Wrapper = styled.div`
   min-height: 100vh;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: ${({ theme }) => theme.background};
   flex-direction: column;
`;

const Content = styled.div`
   flex: 0 0 100%;
`;

const P = styled.p`
   color: ${({ theme }) => theme.text};
   line-height: 1.5;
`;

class ErrorBoundary extends Component {
   state = {};

   static getDerivedStateFromError(error) {
      return { hasError: true };
   }

   redirectToBaseUrl = () => {
      window.location.href = '/';
   };

   refreshPage = () => {
      window.location.reload(true);
   };

   render() {
      const { t } = this.props;

      if (this.state.hasError) {
         return (
            <Wrapper>
               <Container tight>
                  <Card isPadded>
                     <Logo />
                     <Content>
                        <Title>{t('title')}</Title>
                        <P>{t('body')}</P>
                     </Content>
                     <Buttons marged>
                        <ButtonNew onClick={this.refreshPage} isPrimary>
                           {t('reload')}
                        </ButtonNew>
                        <ButtonNew onClick={this.redirectToBaseUrl}>{t('back_home')}</ButtonNew>
                     </Buttons>
                  </Card>
               </Container>
            </Wrapper>
         );
      }

      return this.props.children;
   }
}

export default withTranslation('mainError')(ErrorBoundary);
