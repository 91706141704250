import { generatePagination } from 'utils/pagination';
import get from 'lodash/get';
import { merge } from 'utils';

const initialState = {
  feedChunkById: {},
  feedChunksPagination: {},
};

const feed = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case 'PLACE_FEED_SUCCESS':
      return {
        ...state,
        feedChunkById: merge(
          state.feedChunkById,
          get(payload, 'data.entities.feedChunks'),
        ),
        feedChunksPagination: {
          ...state.feedChunksPagination,
          [payload.placeId]: merge(
            get(state, `feedChunksPagination[${payload.placeId}]`),
            generatePagination(
              state.feedChunksPagination,
              payload.placeId,
              action,
            ),
          ),
        },
      };
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
};

export default feed;
