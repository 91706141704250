import { spacing, Buttons, LinkButton as RawLinkButton, size } from './';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import backgroundEseis from 'images/background-eseis.png';
import backgroundSyndicOne from 'images/background-syndic-one.png';
import backgroundViva from 'images/background-viva.jpg';
import backgroundEcopro from 'images/background-ecopro.jpg';
import { SERGIC_OFFER } from '../constants';

const getBackground = () => {
  switch (SERGIC_OFFER) {
    case 'ESE':
      return backgroundEseis;
    case 'VSY':
      return backgroundViva;
    case 'DIR':
      return backgroundSyndicOne;
    case 'ECO':
      return backgroundEcopro;
    default:
      return backgroundSyndicOne;
  }
};

export const Wrapper = styled.div`
  min-height: 100vh;
  background-image: url(${getBackground()});
  background-size: cover;
  background-position: center center;
  padding-top: ${spacing(3)};
  padding-bottom: ${spacing(3)};
  display: flex;
  align-items: center;
`;

export const Logo = styled.div`
  text-align: center;
  margin-bottom: ${spacing()};

  img {
    display: inline-block;
    max-width: 100%;
  }
`;

export const BoxTitle = styled.h1`
  font-size: ${size(2)};
  margin-top: 0;
  margin-bottom: ${spacing(2)};
  font-weight: 700;
`;

export const BoxSuffix = styled.div`
  margin-top: ${spacing(2)};
  color: ${({ theme }) => theme.textLight};

  p {
    margin: 0 0 ${spacing(0.5)};
  }
`;

export const BoxDescription = styled.p`
  margin-bottom: ${spacing(2)};
  color: ${({ theme }) => theme.textLight};

  ${BoxTitle} + & {
    margin-top: ${spacing(-1.5)};
    margin-bottom: ${spacing(2)};
  }

  ${props =>
    props.variant === 'small' &&
    css`
      font-size: ${size(-1)};
    `};
`;

export const ForgottenPasswordButtonContainer = styled(Buttons)`
  margin-top: ${spacing()};
  margin-bottom: ${spacing(2)};
`;

export const LinkButton = styled(RawLinkButton)`
  color: inherit;
  text-decoration: none;
  transition: background-color 150ms ease;
  font-weight: 600;
  color: ${({ theme }) => theme.primary};
`.withComponent(Link);

export const AuthLabel = styled.p`
  margin-top: ${spacing()};
  margin-bottom: ${spacing()};
  color: ${({ theme }) => theme.textLight};

  ${BoxTitle} + & {
    margin-top: ${spacing(-1.5)};
    margin-bottom: ${spacing(2)};
  }
`;
