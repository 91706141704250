import { generatePagination } from 'utils/pagination';
import get from 'lodash/get';
import { merge } from 'utils';

const initialState = {
  topicById: {},
  topicsPagination: {},
  topicCategoryById: {},
  topicCategoriesIdsByPlaceId: {},
  postById: {},
  postsPagination: {},
  postsIdsByTopicId: {},
};

const feed = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case 'TOPICS_SUCCESS':
      return {
        ...state,
        topicById: merge(state.topicById, get(payload, 'data.entities.topics')),
        topicsPagination: {
          ...state.topicsPagination,
          [payload.placeId]: merge(
            get(state, `topicsPagination['${payload.placeId}']`),
            generatePagination(state.topicsPagination, payload.placeId, action),
          ),
        },
      };

    case 'TOPIC_SUCCESS':
    case 'ARCHIVE_TOPIC_SUCCESS':
      return {
        ...state,
        topicById: merge(state.topicById, get(payload, 'entities.topics')),
      };

    case 'UPDATE_TOPIC_SUCCESS':
      return {
        ...state,
        topicById: merge(state.topicById, get(payload, 'data.entities.topics')),
      };

    case 'UPDATE_POST_SUCCESS':
      return {
        ...state,
        postById: merge(state.postById, get(payload, 'data.entities.posts')),
      };

    case 'TOPIC_POSTS_SUCCESS':
      return {
        ...state,
        postById: merge(state.postById, get(payload, 'data.entities.posts')),
        postsPagination: {
          ...state.postsPagination,
          [payload.topicId]: merge(
            get(state, `postsPagination['${payload.topicId}']`),
            generatePagination(state.postsPagination, payload.topicId, action),
          ),
        },
        // postsIdsByTopicId: {
        //   ...state.postsIdsByTopicId,
        //   [payload.topicId]: get(payload, 'data.result'),
        // },
      };

    case 'TOPIC_CATEGORIES_SUCCESS':
      return {
        ...state,
        topicCategoryById: merge(
          state.topicCategoryById,
          get(payload, 'data.entities.topicCategories'),
        ),
        topicCategoriesIdsByPlaceId: {
          ...state.topicCategoriesIdsByPlaceId,
          [payload.placeId]: get(payload, 'data.result'),
        },
      };

    case 'LOGOUT':
      return initialState;

    default:
      return state;
  }
};

export default feed;
