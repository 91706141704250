import queryString from 'query-string';
import { pickBy, get, camelCase, range, flatten, padStart } from 'lodash';
import { format, differenceInHours, isSameDay, addDays } from 'date-fns';
import { fr } from 'date-fns/locale';

export const getAttachmentURL = (url = '') => {
  const [uri, search] = (url || '').split('?');
  const enrichedSearch = queryString.stringify({
    ...queryString.parse(search),
    access_token:
      JSON.parse(localStorage.getItem('state'))?.user?.accessToken ||
      localStorage.getItem('accessToken'),
  });

  return (url || '').indexOf(';base64') > -1 ? url : `${uri}?${enrichedSearch}`;
};

export const getWindowWidth = () =>
  Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

export const getPathSlug = url =>
  url.slice(1).split('/').map(camelCase).join('_');

export const getParam = (location, param) =>
  get(queryString.parse(location.search), param);

export const capitalize = (input = '') => {
  if (!Boolean(input)) {
    return '';
  }
  if (input.toLowerCase() === input) {
    return input
      .replace(/(?:^|[\s-/.'])\w/g, match => match.toUpperCase())
      .replace(/\s[a-zA-Z{1,2}]\s/g, match => match.toLowerCase());
  }
  if (input.toUpperCase() === input) {
    return input
      .toLowerCase()
      .replace(/(?:^|[\s-/.'])[\wéàèáùòóôûêâ]/g, match => match.toUpperCase())
      .replace(/\s[a-zA-Z{1,2}]\s/g, match => match.toLowerCase());
  }
  return input;
};

export const formatDate = (date, frmt) => {
  try {
    return format(new Date(date), frmt, { locale: fr });
  } catch (err) {
    try {
      return format(date, frmt, { locale: fr });
    } catch (err) {
      return '';
    }
  }
};

export const formatRelativeDate = (date, hideHourIfNotToday, hasPrefix) => {
  const now = new Date();
  const deltaHours = differenceInHours(now, new Date(date));
  const isToday = isSameDay(now, new Date(date));
  const isYesterday = isSameDay(addDays(now, -1), new Date(date));

  if (isToday) {
    return formatDate(new Date(date), `${hasPrefix ? '[à ]' : ''}HH:mm`);
  }

  if (isYesterday) {
    return hideHourIfNotToday
      ? hasPrefix
        ? 'hier'
        : 'Hier'
      : formatDate(
          new Date(date),
          `${hasPrefix ? '[hier à]' : '[Hier]'} HH:mm`,
        );
  }
  if (deltaHours < 24 * 7) {
    return `${hasPrefix ? 'le ' : ''}${
      hideHourIfNotToday
        ? formatDate(new Date(date), 'dddd')
        : formatDate(new Date(date), 'dddd, HH:mm')
    }`;
  }
  return `${hasPrefix ? 'le ' : ''}${formatDate(date, 'd MMM')}`;
};

export const formatAmount = (amount, shouldHidePlusSign) => {
  if (typeof Intl === 'undefined') {
    return `${amount > 0 && !shouldHidePlusSign ? '+' : ''}${amount.toFixed(
      2,
    )}\u00a0€`;
  }

  return `${
    amount > 0 && !shouldHidePlusSign ? '+' : ''
  }${new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
  }).format(amount)}`;
};

export const overrideParam = (search, param) =>
  queryString.stringify(
    pickBy({
      ...queryString.parse(search),
      ...param,
    }),
  );

export const getContestationReason = contestationReason => {
  switch (contestationReason) {
    case 'do_deposit':
      return 'Acompte à faire';
    case 'service_contest':
      return 'Contestation prestation';
    case 'contract_canceled':
      return 'Contrat résilié';
    case 'invoice_amount_improper':
      return 'Montant facture non conforme';
    case 'accountant_rejection':
      return 'Rejet comptable';
    case 'waiting_work':
      return 'Attente réception travaux';
    case 'error_vat_provider':
      return 'Erreur TVA prestataire';
    case 'place_lost':
      return 'Immeuble perdu';
    case 'imputation_error_without_rejection':
      return "Erreur d'imputation sans rejet";

    default:
      return '';
  }
};

export const hours = flatten(
  range(0, 24)
    .map(hour => padStart(hour, 2, '0'))
    .map(hour => [`${hour}:00`, `${hour}:30`]),
);

export const ERRORS = {
  REQUIRED: 'Champ requis',
  TAKEN: 'Valeur déjà prise',
  INVALID: 'Valeur invalide',
  EMAIL: 'Adresse mail invalide',
  PASSWORDS_DO_NOT_MATCH: 'Les mots de passe ne concordent pas',
  BAD_CHECK_DIGITS: 'IBAN incorrect',
  NUMBER: 'Ce champ doit être un nombre',
  NOT_APPROVED: 'Ce fournisseur n’est pas validé',
  CANNOT_APPROVE_IF_BLANK: 'Champ requis',
};
