import { SERGIC_OFFER } from '../constants';
import { round } from 'lodash';

export const base = 16;
export const spacing = (input = 1) => `${Number(input) * base}px`;
export const size = (input = 0) =>
  `${round(Math.sqrt(Math.sqrt(2)) ** input, 3)}rem`;

export const getBrandPalette = () => {
  switch (SERGIC_OFFER) {
    case 'ESE':
    case 'ECO':
    case 'NEUF':
      return {
        primary: '#029ce0',
        primaryLight: '#009de0',
        primaryUltraLight: '#dff3fb',
        primaryGradientOne: '#009ae1',
        primaryGradientTwo: '#295fcc',
        primaryButtonGradientOne: '#029ce0',
        primaryButtonGradientTwo: '#029ce0',
        primaryButtonIconColor: '#fff',
        primaryButtonTextColor: '#fff',
        primaryButtonBoxShadowHover: '0 2px 8px 0 #9fb8c7',
        primaryButtonBoxShadow: 'none',
        sidebarGradientOne: '#009ae1',
        sidebarGradientTwo: '#295fcc',
        sidebarLinkColor: '#fff',
        sidebarActiveLinkColor: '#fff',
        sidebarActiveLinkSvgColor: '#fff',
        sidebarActiveLinkBackgroundColor: 'rgba(0, 0, 0, 0.12)',
        sidebarLinkPadding: '16px',
        sidebarLinkMargin: '0',
        sidebarLinkBorderRadius: '0',
        sidebarSubmenuBackgroundColor: 'rgba(0, 0, 0, 0.12)',
        sidebarMenuActiveLinkColor: '#fff',
        sidebarMenuActiveLinkBackgroundColor: 'rgba(0, 0, 0, 0.08)',
        sidebarMenuActiveLinkInnerBackgroundColor: 'transparent',
        sidebarMenuActiveLinkInnerVerticalPadding: '12px',
        sidebarMenuActiveLinkFontWeight: '400',
        background: '#f2f4f7',
        backgroundLight: '#f4fafe',
        default: '#029ce0',
        medium: '#285fcc',
        dark: '#00364d',
        vibrant: '#009de0',
        light: '#78b2d8',
        separator: '#E1E3E6',
      };
    case 'VSY':
    case 'VQC':
      return {
        primary: '#d9002b',
        primaryLight: '#d9002b',
        primaryUltraLight: '#FCE8EC',
        primaryGradientOne: '#d9002b',
        primaryGradientTwo: '#d9002b',
        primaryButtonGradientOne: '#fff',
        primaryButtonGradientTwo: '#f6f7f7',
        primaryButtonIconColor: '#d9002b',
        primaryButtonTextColor: '#212121',
        primaryButtonBoxShadowHover:
          '0 2px 4px 0 rgba(0, 0, 0, 0.12), inset 0 0 0 1px rgba(0, 0, 0, 0.06)',
        primaryButtonBoxShadow: 'inset 0 0 0 1px rgba(0, 0, 0, 0.06)',
        sidebarGradientOne: '#fff',
        sidebarGradientTwo: '#fff',
        sidebarLinkColor: 'rgba(0, 0, 0, 0.6)',
        sidebarActiveLinkBackgroundColor: 'rgba(0, 0, 0, 0.04)',
        sidebarActiveLinkColor: '#212121',
        sidebarActiveLinkSvgColor: '#d9002b',
        sidebarLinkPadding: '8px',
        sidebarLinkMargin: '8px',
        sidebarLinkBorderRadius: '6px',
        sidebarSubmenuBackgroundColor: 'transparent',
        sidebarMenuActiveLinkColor: '#212121',
        sidebarMenuActiveLinkBackgroundColor: 'transparent',
        sidebarMenuActiveLinkInnerBackgroundColor: 'rgba(0, 0, 0, 0.04)',
        sidebarMenuActiveLinkInnerVerticalPadding: '8px',
        sidebarMenuActiveLinkFontWeight: '500',
        background: '#F0f0f0',
        backgroundLight: '#fbfbfb',
        default: '#d9002b',
        medium: '#285fcc',
        dark: '#00364d',
        vibrant: '#d9002b',
        light: '#f2c3c1',
      };
    case 'DIR':
      return {
        primary: '#26BF8C',
        primaryLight: '#49a99c',
        primaryUltraLight: '#DEEDEB',
        primaryGradientOne: '#49a99c',
        primaryGradientTwo: '#49a99c',
        primaryButtonGradientOne: '#49a99c',
        primaryButtonGradientTwo: '#49a99c',
        primaryButtonIconColor: '#fff',
        primaryButtonTextColor: '#fff',
        primaryButtonBoxShadowHover: '0 2px 8px 0 #9fb8c7',
        primaryButtonBoxShadow: 'none',
        sidebarGradientOne: '#26BF8C',
        sidebarGradientTwo: '#26BF8C',
        sidebarLinkColor: '#fff',
        sidebarActiveLinkColor: '#fff',
        sidebarActiveLinkSvgColor: '#fff',
        sidebarActiveLinkBackgroundColor: 'rgba(0, 0, 0, 0.12)',
        sidebarLinkPadding: '16px',
        sidebarLinkMargin: '0',
        sidebarLinkBorderRadius: '0',
        sidebarSubmenuBackgroundColor: 'rgba(0, 0, 0, 0.12)',
        sidebarMenuActiveLinkColor: '#fff',
        sidebarMenuActiveLinkBackgroundColor: 'rgba(0, 0, 0, 0.08)',
        sidebarMenuActiveLinkInnerBackgroundColor: 'transparent',
        sidebarMenuActiveLinkInnerVerticalPadding: '12px',
        sidebarMenuActiveLinkFontWeight: '400',
        background: '#EBF3F5',
        backgroundLight: '#f4fafe',
        default: '#49a99c',
        medium: '#419890',
        dark: '#00364d',
        vibrant: '#30c0ad',
        light: '#addcd6',
        separator: '#E1E3E6',
      };
    default:
  }
};

const colors = {
  brand: {
    default: '#49a99c',
    medium: '#419890',
    vibrant: '#30c0ad',
    light: '#addcd6',
    fade: '#e9fbf9',
    secondary: '#f1b14e',
    secondaryVibrant: '#ffa800',
    secondaryFade: '#fef6e7',
  },
  black: {
    default: '#212121',
    secondary: '#707070',
    tertiary: '#9e9e9e',
    quaternary: '#cccccc',
  },
  red: {
    default: '#e22a2a',
    medium: '#d7263d',
    dark: '#bc021a',
  },
  grey: {
    default: '#f6f7f7',
    medium: '#dadede',
    light: '#fafafa',
    dark: '#bbb',
  },
  green: {
    default: '#26bf8c',
  },
  orange: {
    default: '#ff9819',
    dark: '#e66322',
  },
};

const color = (name, shade = 'default') => {
  return colors[name] && colors[name][shade];
};

const colorsUi = {
  text: color('black'),
  textSecondary: color('black', 'secondary'),
  textLight: color('black', 'tertiary'),
  textLighter: color('black', 'quaternary'),
  background: color('grey'),
  backgroundLight: color('grey', 'light'),
  primary: color('brand'),
  primaryVibrant: color('brand', 'vibrant'),
  primaryLight: color('brand', 'light'),
  primaryFade: color('brand', 'fade'),
  secondary: color('brand', 'secondary'),
  secondaryVibrant: color('brand', 'secondaryVibrant'),
  secondaryFade: color('brand', 'secondaryFade'),
  disabled: color('grey', 'medium'),
  success: color('green'),
  error: color('red', 'medium'),
  warning: color('orange', 'dark'),
  separator: 'rgba(0, 0, 0, 0.10)',
  separatorLight: 'rgba(0, 0, 0, 0.05)',
  imageShadow: 'rgba(2, 24, 43, .25)',
  blueShadow: 'rgba(89, 149, 179, .15)',
};

const ui = name => colorsUi[name];

export const theme = {
  text: 'rgba(0, 0, 0, 0.87)',
  textLight: 'rgba(0, 0, 0, 0.6)',
  textLighter: 'rgba(0, 0, 0, 0.38)',
  primaryGradient: `linear-gradient(135deg, ${
    getBrandPalette().primaryGradientOne
  } 0%, ${getBrandPalette().primaryGradientTwo} 100%)`,
  sidebarGradient: `linear-gradient(135deg, ${
    getBrandPalette().sidebarGradientOne
  } 0%, ${getBrandPalette().sidebarGradientTwo} 100%)`,
  disabledBackground: 'rgba(0, 0, 0, 0.12)',
  disabledBackgroundLight: 'rgba(0, 0, 0, 0.06)',
  disabledText: 'rgba(0, 0, 0, 0.26)',
  separator: 'rgba(0, 0, 0, 0.12)',
  separatorLight: 'rgba(0, 0, 0, 0.08)',
  // background: '#EBF3F5',
  success: '#26bf8c',
  error: '#e22a2a',
  warning: '#ff9819',
  borderRadius: '6px',

  ...getBrandPalette(),

  spacing,
  size,
};

export { color, ui };
