import styled from 'styled-components';
import Animate from 'react-move/Animate';
import {
  spacing,
  Media,
  MediaImage,
  MediaBody,
  ButtonReset,
  Button,
  ui,
  size,
} from 'ui';
import { MdClear } from 'react-icons/md';
import { SERGIC_OFFER } from '../constants';
import appEseis from 'images/app-eseis.jpg';
import appViva from 'images/app-viva.jpg';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 2147483647;
  padding: ${spacing(0.5)};
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
  height: 56px;
`;

const AppIcon = styled.img`
  width: 40px;
  border-radius: 6px;
`;

const Title = styled.div`
  color: ${ui('text')};
  font-weight: 600;
`;

const Meta = styled.div`
  color: ${ui('textLight')};
  font-size: ${size(-1)};
`;

export const getAppName = offer => {
  switch (offer || SERGIC_OFFER) {
    case 'ESE':
      return 'éseis Syndic';
    case 'VSY':
      return 'Viva Syndic';
    case 'DIR':
      return 'Syndic One';
    case 'ECO':
      return 'e-Copro';
    default:
      return 'Sergic';
  }
};

const getAppLink = offer => {
  switch (offer || SERGIC_OFFER) {
    case 'ESE':
      return 'https://eseis.page.link/agKS';
    case 'VSY':
      return 'https://syndic.page.link/imXb';
    case 'DIR':
      return 'https://syndicone.page.link/WSBG';
    case 'ECO':
    default:
      return '';
  }
};

const getAppLogo = () => {
  switch (SERGIC_OFFER) {
    case 'ESE':
      return appEseis;
    case 'VSY':
      return appViva;
    case 'ECO':
    default:
      return null;
  }
};

const AppBanner = ({ onDismiss, onClick }) => {
  const { t } = useTranslation('appBanner');

  const handleClick = () => {
    localStorage.setItem('hasDismissedBanner', true);
    window.location = getAppLink();
  };

  return (
    <Animate
      show
      start={{ translate: -56 }}
      enter={[
        {
          translate: [0],
          timing: {
            duration: 150,
          },
        },
      ]}
      leave={[
        {
          translate: [-56],
          timing: {
            duration: 150,
          },
        },
      ]}
    >
      {({ translate }) => (
        <Wrapper style={{ transform: `translateY(${translate}px)` }}>
          <Media center>
            <MediaImage>
              <ButtonReset onClick={onDismiss}>
                <MdClear />
              </ButtonReset>
            </MediaImage>

            <MediaBody tight secondary>
              <AppIcon src={getAppLogo()} alt="" />
            </MediaBody>

            <MediaBody tight>
              <Title>{t('continue')}</Title>
              <Meta>{t('free')}</Meta>
            </MediaBody>

            <MediaBody tight secondary>
              <Button
                isPrimary
                style={{ whiteSpace: 'nowrap' }}
                onClick={handleClick}
              >
                {t('open')}
              </Button>
            </MediaBody>
          </Media>
        </Wrapper>
      )}
    </Animate>
  );
};

export default AppBanner;
