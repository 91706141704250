import { Component } from 'react';
import styled from 'styled-components';
import { getTerms } from 'api/user';
import { history } from 'store';
import { getLogo } from 'containers/Auth';
import { withTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  flex-direction: column;
  padding: 16px;
  min-height: 100vh;

  p {
    max-width: 45em;
  }
`;

class Maintenance extends Component {
  componentDidMount() {
    this.fetchTerms();
  }

  fetchTerms = async () => {
    try {
      await getTerms();
      history.push('/');
    } catch (e) {}
  };

  render() {
    const { t } = this.props;
    return (
      <Wrapper>
        <div>
          <img src={getLogo()} alt="" />

          <p>{t('maintenance:title')},</p>

          <p>
            {t('maintenance:update')}
            <br />
            {t('maintenance:message')}
          </p>

          <p> {t('maintenance:ending')}</p>
        </div>
      </Wrapper>
    );
  }
}

export default withTranslation()(Maintenance);
