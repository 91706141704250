const initialState = {
  byId: {},
  queue: [],
};

const snacks = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SNACK_QUEUE':
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: payload,
        },
        queue: [...state.queue, payload.id],
      };
    case 'SNACK_POP_IMPORTANT':
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: payload,
        },
        queue: [payload.id, ...state.queue],
      };
    case 'SNACK_DISMISS':
      return {
        ...state,
        queue: state.queue.filter(id => id !== payload),
      };
    case 'SNACK_CLEAR_ALL':
      return {
        ...state,
        queue: [],
      };
    default:
      return state;
  }
};

export default snacks;
