import styled from 'styled-components';
import { camelize, camelizeKeys } from 'humps';
import { normalize as rawNormalize } from 'normalizr';
import get from 'lodash/get';
import round from 'lodash/round';
import { addDays, differenceInHours, format, isSameDay } from 'date-fns';
import { fr } from 'date-fns/locale';
import groupBy from 'lodash/groupBy';
import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';
import setWith from 'lodash/setWith';
import { size } from 'ui';
import queryString from 'query-string';
import i18n from 'i18next';
import { SERGIC_OFFER } from '../constants';
import currencyFormatter from 'currency-formatter';

const getCodeFromOffer = () => {
   switch (SERGIC_OFFER) {
      case 'VQC':
         return 'CAD';
      default:
         return 'EUR';
   }
};

export const getPageAndRestFromQuery = query => {
   const { page, ...rest } = queryString.parse(query);

   return {
      page: page || 1,
      query: `?${queryString.stringify(rest)}`,
   };
};

export const getParams = ({ search }) =>
   search
      .slice(1)
      .split('&')
      .map(chunk => {
         const keyValue = chunk.split('=');
         if (keyValue.length === 1) return chunk;

         return {
            [camelize(keyValue[0])]: keyValue[1],
         };
      })
      .reduce((acc, curr) => ({ ...acc, ...curr }), {});

export const normalize = (data, schema) => rawNormalize(camelizeKeys(data), schema);

export const merge = (empirical = {}, payload = {}) => {
   let output = empirical;

   // eslint-disable-next-line
   for (const key in payload) {
      output = {
         ...output,
         [key]: {
            ...(output[key] || {}),
            ...(payload[key] || {}),
         },
      };
   }

   return output;
};

export const getAttachmentURL = (url = '') => {
   const [uri, search] = (url || '').split('?');
   const enrichedSearch = queryString.stringify({
      ...queryString.parse(search),
      access_token: get(JSON.parse(localStorage.getItem('state')), 'user.accessToken'),
   });

   return (url || '').indexOf(';base64') > -1 ? url : `${uri}?${enrichedSearch}`;
};

export const getBudgetPercentage = (allocated, spent) => {
   if (allocated === 0) {
      return '';
   }

   if (spent > allocated) {
      return `+${round((100 * (spent - allocated)) / allocated)} %`;
   }

   return `${round((100 * spent) / allocated)} %`;
};

export const languages =
   SERGIC_OFFER === 'VQC'
      ? [
           { name: 'fr-CA', label: 'fr-CA', locale: fr },
           { name: 'fr-FR', label: 'fr-FR', locale: fr },
           { name: 'en-CA', label: 'en-CA', locale: null },
        ]
      : [{ name: 'fr-FR', label: 'fr-FR', locale: fr }];

export const getLocale = language => {
   if (language) {
      return languages.filter(lang => lang.name === language)[0];
   }

   if (localStorage.getItem('locale')) {
      return languages.filter(({ name }) => name === JSON.parse(localStorage.getItem('locale')))[0];
   }

   if (i18n.language) {
      return languages.filter(({ name }) => name === i18n.language)[0];
   }

   return languages[0];
};

export let locale = getLocale().locale;

export const changeLocale = lang => {
   i18n.changeLanguage(lang);
   localStorage.setItem('locale', JSON.stringify(getLocale(lang).name));
   locale = getLocale(lang).locale;
};

export const formatDate = (date, frmt, locale) => {
   try {
      return format(new Date(date), frmt, {
         locale: locale === 'en-CA' ? null : fr,
      });
   } catch (err) {
      try {
         return format(date, frmt, { locale: locale === 'en-CA' ? null : fr });
      } catch (err) {
         return '';
      }
   }
};

export const formatCurrency = amount => {
   if (!amount && amount !== 0) {
      return '';
   }

   if (typeof Intl === 'undefined') {
      return currencyFormatter.format(amount, {
         code: getCodeFromOffer(),
         locale: getLocale().label.slice(0, 2),
      });
   }

   return new Intl.NumberFormat(getLocale().label, {
      style: 'currency',
      currency: getCodeFromOffer(),
   }).format(amount);
};

export const capitalize = (input = '') => {
   if (!Boolean(input)) {
      return '';
   }
   if (input.toLowerCase() === input) {
      return input
         .replace(/(?:^|[\s-/.'])\w/g, match => match.toUpperCase())
         .replace(/\s[a-zA-Z{1,2}]\s/g, match => match.toLowerCase());
   }
   if (input.toUpperCase() === input) {
      return input
         .toLowerCase()
         .replace(/(?:^|[\s-/.'])[\wéàèáùòóôûêâ]/g, match => match.toUpperCase())
         .replace(/\s[a-zA-Z{1,2}]\s/g, match => match.toLowerCase());
   }
   return input;
};

export const capitalizeFirstLetter = input => {
   if (!Boolean(input)) {
      return '';
   }
   if (input.toLowerCase() === input) {
      return input
         .replace(/(?:^|[/.])\w/g, match => match.toUpperCase())
         .replace(/\s[a-zA-Z{1,2}]\s/g, match => match.toLowerCase());
   }
   if (input.toUpperCase() === input) {
      return input
         .toLowerCase()
         .replace(/(?:^|[/.'])[\wéàèáùòóôûêâ]/g, match => match.toUpperCase())
         .replace(/\s[a-zA-Z{1,2}]\s/g, match => match.toLowerCase());
   }
   if (input.match(/[A-Z]/, 'g') != null) {
      input = input.toLowerCase();
      return input
         .replace(/(?:^|[/.])\w/g, match => match.toUpperCase())
         .replace(/\s[a-zA-Z{1,2}]\s/g, match => match.toLowerCase());
   }
   return input;
};

export const formatRelativeDate = (date, hideHourIfNotToday, hasPrefix) => {
   const now = new Date();
   const deltaHours = differenceInHours(now, new Date(date));
   const isToday = isSameDay(now, new Date(date));
   const isYesterday = isSameDay(addDays(now, -1), new Date(date));

   if (isToday) {
      return formatDate(new Date(date), `${hasPrefix ? "'à '" : ''}HH:mm`);
   }

   if (isYesterday) {
      return hideHourIfNotToday
         ? hasPrefix
            ? 'hier'
            : 'Hier'
         : formatDate(new Date(date), `${hasPrefix ? '[hier à]' : '[Hier]'} HH:mm`);
   }

   if (deltaHours < 24 * 7) {
      return `${hasPrefix ? 'le ' : ''}${
         hideHourIfNotToday ? formatDate(new Date(date), 'EEEE') : formatDate(new Date(date), 'EEEE, HH:mm')
      }`;
   }

   return `${hasPrefix ? 'le ' : ''}${formatDate(date, 'd MMM yyyy')}`;
};
export const formatRelativeSupportDate = (date, hideHourIfNotToday, hasPrefix) => {
   const now = new Date();
   const deltaHours = differenceInHours(now, date);
   const isToday = isSameDay(now, date);
   const isYesterday = isSameDay(addDays(now, -1), date);
   if (isToday) {
      return formatDate(date, '[Aujourd‘hui à] HH:mm');
   }
   if (isYesterday) {
      return hideHourIfNotToday ? (hasPrefix ? 'hier' : 'Hier') : formatDate(date, '[Hier à] HH:mm');
   }
   if (deltaHours < 24 * 7) {
      return `${hasPrefix ? 'le ' : ''}${
         hideHourIfNotToday ? formatDate(date, 'dddd') : formatDate(date, 'dddd, HH:mm')
      }`;
   }
   return `${formatDate(date, 'Le d MMM à HH:mm')}`;
};
const DupeCount = styled.span`
   font-size: ${size(-1)};
`;
export const getParcelsSummary = (parcels = []) => {
   const dupes = groupBy(parcels, 'displayName');
   return keys(dupes).map((parcel, index) => (
      <span key={index}>
         {parcel}
         {dupes[parcel].length > 1 && (
            <DupeCount>
               {' '}
               (×
               {dupes[parcel].length})
            </DupeCount>
         )}
         {index < keys(dupes).length - 1 ? ', ' : ''}
      </span>
   ));
};
export const addLocalPaginationToGlobalState = ({ state, payload, meta, status }) => {
   const { sliceId, params } = payload;
   const { page, query } = getPageAndRestFromQuery(params);
   setWith(state, [sliceId, query, 'loadingState', page].filter(Boolean), status, Object);
   if (status === 'DONE') {
      const pagination = {
         total: meta.total,
         perPage: meta.perPage,
         lastPage: Math.ceil(meta.total / meta.perPage),
      };
      setWith(state, [sliceId, query, 'idsByPage', meta.page].filter(Boolean), payload.data.result, Object);
      setWith(state, [sliceId, query, 'pagination'].filter(Boolean), pagination, Object);
   }
};

export const overrideSearchWith = ({ location, ...rest }) =>
   queryString.stringify(
      pickBy({
         ...queryString.parse(location.search),
         ...rest,
      })
   );

export const getFileName = sourceFileName => {
   const [extension, ...chunks] = (sourceFileName || '').split('_').reverse();

   return chunks.reverse().join('_') + '.' + extension;
};

export const formatFiscalYear = fiscalYear => {
   if (!get(fiscalYear, 'displayName')) {
      return '';
   }

   return fiscalYear.displayName;
};

export const getResolutionAttachments = resolution =>
   [
      ...(resolution?.attachments?.nodes ?? []),
      resolution?.kind === 'account' && {
         sourceFileName: 'Comptes',
         ...resolution?.generalMeeting?.account,
      },
      resolution?.kind === 'contract' && {
         sourceFileName: 'Contrat de syndic',
         ...resolution?.generalMeeting?.contract,
      },
   ]
      .filter(Boolean)
      .map(node => ({
         ...node,
         href: node.fileUrl,
      }));

export const decodeHtmlCharCodes = str =>
   (str || '').replace(/(&#(\d+);)/g, (_, __, charCode) => String.fromCharCode(charCode));

export const constructURI = (baseURL, params, formatKey = s => s) => {
   const queryString = Object.keys(params)
      .filter(key => params[key] !== undefined && params[key] !== null && params[key] !== '')
      .map(key => formatKey(key) + '=' + params[key])
      .join('&');
   return queryString ? baseURL + '?' + queryString : baseURL;
};
