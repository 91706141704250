import { Component } from 'react';
import styled, { css } from 'styled-components';
import { ButtonReset } from '@tymate/margaret';
import { getAttachmentURL, getWindowWidth } from '../../utils';
import IcPDF from '../../icons/PDF';
import { media, ActionButton, viewportSizes } from '../../ui';
import { Document as PDFDocument, Page } from 'react-pdf';
import round from 'lodash/round';
import { MdClear } from 'react-icons/md';

const AttachmentRemoveButton = styled(ActionButton)`
  position: absolute;
  right: ${({ theme }) => theme.spacing(0.25)};
  top: 50%;
  transform: translateY(-50%);
`;

const MessagePicture = styled.div`
  display: inline-block;
  width: ${({ theme }) => theme.spacing(8)};
  height: ${({ theme }) => theme.spacing(8)};
  border-radius: ${({ theme }) => theme.borderRadius};
  background-size: cover;
  background-position: center center;
  margin-right: ${({ theme }) => theme.spacing(0.5)};

  ${props =>
    !props.shouldHavePreview &&
    !props.isInInput &&
    css`
      width: ${({ theme }) => theme.spacing(2)};
      height: ${({ theme }) => theme.spacing(2)};
    `};

  ${props =>
    props.isInInput &&
    css`
      ${media.tablet`
        width: ${({ theme }) => theme.spacing(2)};
        height: ${({ theme }) => theme.spacing(2)};
      `};
    `};

  ${props =>
    props.size === 'small' &&
    css`
      width: ${({ theme }) => theme.spacing(4)};
      height: ${({ theme }) => theme.spacing(4)};
    `}
`;

const MessageAttachment = styled.div`
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius};
  margin-left: ${({ theme }) => theme.spacing()};
  margin-top: ${({ theme }) => theme.spacing()};
  position: relative;
  flex: 0 0 100%;
  text-decoration: none;
  color: inherit;
  min-height: 48px;
  background-color: #fff;
  text-align: left;

  ${props =>
    props.href &&
    css`
      width: 100%;
    `};

  ${props =>
    props.variant === 'full' &&
    css`
      width: 100%;
    `};

  ${props =>
    props.variant === 'bare' &&
    css`
      margin-left: 0;
      margin-top: 0;
    `};

  ${props =>
    (props.shouldHavePreview || props.isInInput) &&
    css`
      flex: 0 0 auto;
    `};

  ${props =>
    props.variant === 'dashboard' &&
    css`
      width: auto;
      margin-left: 0;
      margin-top: 0;
      margin-bottom: ${({ theme }) => theme.spacing()};
    `};

  ${media.tablet`
    flex: 0 0 auto;
  `};

  > svg {
    height: ${({ theme }) => theme.spacing(8)};
    width: ${({ theme }) => theme.spacing(8)};
  }

  ${props =>
    !props.shouldHavePreview &&
    !props.isInInput &&
    css`
      padding: ${({ theme }) => theme.spacing(0.25)};
      border: 1px solid ${({ theme }) => theme.separator};

      ${'' /* &:hover {
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12);
      } */}

      > svg {
        height: ${({ theme }) => theme.spacing(2)};
        width: ${({ theme }) => theme.spacing(2)};
      }
    `};

  ${props =>
    props.isInInput &&
    css`
      ${media.tablet`
        border: 1px solid ${({ theme }) => theme.separator};
        padding: ${({ theme }) => theme.spacing(0.25)};

        > svg {
          height: ${({ theme }) => theme.spacing(2)};
          width: ${({ theme }) => theme.spacing(2)};
        }
      `};
    `};

  ${props =>
    Boolean(props.as) &&
    css`
      width: calc(100% - ${({ theme }) => theme.spacing()});
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.08);

      &:hover {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
      }
    `};

  ${props =>
    props.variant === 'bareLeftAndSemiTop' &&
    css`
      margin-left: 0;
      margin-top: ${({ theme }) => theme.spacing()};
    `};
`;

export const MessageAttachments = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + ${({ theme }) => theme.spacing()});
  padding-top: ${({ theme }) => theme.spacing(0.5)};
  margin-left: ${({ theme }) => theme.spacing(-1)};
  margin-top: ${({ theme }) => theme.spacing(-1)};

  ${props =>
    props.isInInput &&
    css`
      padding-bottom: ${({ theme }) => theme.spacing(0.5)};
      order: -1;
      padding-left: ${({ theme }) => theme.spacing()};
      padding-right: ${({ theme }) => theme.spacing()};
      position: relative;
      margin-left: 0;

      > *:first-child {
        margin-left: 0;
      }

      ${media.tablet`
        border-bottom: 0;
        order: initial;
        margin-left: 0;
        margin-right: 0;
      `};
    `};

  ${props =>
    props.shouldNotWrap &&
    css`
      flex-wrap: initial;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    `};
`;

const MessageAttachmentLabel = styled.p`
  display: none;
  margin: 0;
  font-weight: 500;

  ${props =>
    !props.shouldHavePreview &&
    !props.isInInput &&
    css`
      display: block;
      width: ${({ theme }) => theme.spacing(15)};
      max-width: 60vw;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `};

  ${props =>
    props.isInInput &&
    css`
      ${media.tablet`
        display: block;
        width: ${({ theme }) => theme.spacing(15)};
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      `};
    `};
`;

const MessageAttachmentLabelMeta = styled.span`
  color: ${({ theme }) => theme.textLight};
  display: block;
  font-weight: 400;
  font-size: ${({ theme }) => theme.size(-1)};
`;

const MessageAttachmentPictureContainer = styled.div`
  width: ${({ theme }) => theme.spacing(8)};
  height: ${({ theme }) => theme.spacing(8)};
  margin-right: ${({ theme }) => theme.spacing(0.25)};

  ${props =>
    !props.shouldHavePreview &&
    !props.isInInput &&
    css`
      width: ${({ theme }) => theme.spacing(2)};
      height: ${({ theme }) => theme.spacing(2)};
    `};

  ${props =>
    props.isInInput &&
    css`
      ${media.tablet`
        width: ${({ theme }) => theme.spacing(2)};
        height: ${({ theme }) => theme.spacing(2)};
      `};
    `};

  ${props =>
    props.size === 'small' &&
    css`
      width: ${({ theme }) => theme.spacing(4)};
      height: ${({ theme }) => theme.spacing(4)};
    `}

  canvas {
    margin-left: auto;
    margin-right: auto;
  }
`;

const IconContainer = styled.span`
  > svg {
    width: ${({ theme }) => theme.spacing(6)};
    height: ${({ theme }) => theme.spacing(6)};
  }

  ${props =>
    !props.shouldHavePreview &&
    !props.isInInput &&
    css`
      > svg {
        width: ${({ theme }) => theme.spacing(2)};
        height: ${({ theme }) => theme.spacing(2)};
      }
    `};

  ${props =>
    props.isInInput &&
    css`
      ${media.tablet`
        > svg {
          width: ${({ theme }) => theme.spacing(2)};
          height: ${({ theme }) => theme.spacing(2)};
        }
      `};
    `};
`;

class Attachment extends Component {
  static defaultProps = {
    sourceContentType: '',
  };

  state = {
    calculationIsDone: false,
    width: null,
    height: null,
    ratio: null,
  };

  handleLoadDocument = async pdf => {
    const { _pageInfo } = await pdf.getPage(1);

    const width = _pageInfo?.view?.[2];
    const height = _pageInfo?.view?.[3];
    const ratio = width / height;

    const baseSize = getWindowWidth() >= viewportSizes.tablet ? 32 : 128;

    this.setState({
      width: ratio >= 1 ? baseSize : baseSize * ratio,
      height: ratio <= 1 ? baseSize : baseSize / ratio,
      ratio: _pageInfo?.view?.[2] / _pageInfo?.view?.[3],
      calculationIsDone: true,
    });
  };

  render() {
    const {
      shouldHavePreview,
      isInInput,
      sourceContentType,
      sourceFileSize,
      sourceFileName,
      fileUrl,
      canBeRemoved,
      onRemove,
      onClick,
      variant,
      href,
      size,
    } = this.props;
    const { calculationIsDone, width, height } = this.state;

    if (!fileUrl) {
      return null;
    }

    return (
      <MessageAttachment
        as={Boolean(onClick) ? ButtonReset : Boolean(href) ? 'a' : null}
        onClick={onClick}
        shouldHavePreview={shouldHavePreview}
        isInInput={isInInput}
        variant={variant}
        download={Boolean(href)}
        href={Boolean(href) ? getAttachmentURL(href) : null}
        target={Boolean(href) ? '_blank' : null}
      >
        {canBeRemoved && (
          <AttachmentRemoveButton type="button" onClick={onRemove}>
            <MdClear />
          </AttachmentRemoveButton>
        )}

        <MessageAttachmentPictureContainer
          shouldHavePreview={shouldHavePreview}
          isInInput={isInInput}
          size={size}
        >
          {sourceContentType.indexOf('image') > -1 && (
            <MessagePicture
              shouldHavePreview={shouldHavePreview}
              isInInput={isInInput}
              style={{
                backgroundImage: `url(${getAttachmentURL(fileUrl)})`,
              }}
              size={size}
            />
          )}

          {!shouldHavePreview &&
            !isInInput &&
            sourceContentType.indexOf('pdf') > -1 && (
              <IconContainer>
                <IcPDF />
              </IconContainer>
            )}

          {(shouldHavePreview || isInInput) &&
            sourceContentType.indexOf('pdf') > -1 && (
              <PDFDocument
                file={getAttachmentURL(fileUrl)}
                onLoadSuccess={this.handleLoadDocument}
                loading={
                  <IconContainer>
                    <IcPDF />
                  </IconContainer>
                }
              >
                {calculationIsDone && (
                  <Page
                    pageNumber={1}
                    ref={c => (this.page = c)}
                    width={width}
                    height={height}
                  />
                )}
              </PDFDocument>
            )}
        </MessageAttachmentPictureContainer>

        <MessageAttachmentLabel
          shouldHavePreview={shouldHavePreview}
          isInInput={isInInput}
        >
          {sourceFileName}

          {Boolean(sourceFileSize) && (
            <MessageAttachmentLabelMeta>
              {round(sourceFileSize / 1000)} Ko
            </MessageAttachmentLabelMeta>
          )}
        </MessageAttachmentLabel>
      </MessageAttachment>
    );
  }
}

export default Attachment;
