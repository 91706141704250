import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import { Suspense, lazy } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { Switch, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import cleanDeep from 'clean-deep';
import { unregister } from './registerServiceWorker';
import store, { history } from './store';
import { saveState } from './api/localStorage';
import SnacksProvider from 'containers/SnacksProvider';
import TokenLogin from 'containers/TokenLogin';
import { TokenSender } from '@tymate/sergic';
import Maintenance from 'components/Maintenance';
import ErrorBoundary from 'components/ErrorBoundary';
import {
  ENVIRONMENT,
  SO_GENERAL_MEETING_WEBAPP_URL,
  GTM_ID,
} from './constants';
import { ui } from './ui';
import { theme } from './ui/colors';
import 'normalize.css/normalize.css';
import './index.css';
import omit from 'lodash/omit';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getLocale } from 'utils';
import { GraphQLProvider } from '@tymate/sergic';
import IE from 'containers/IE';
import TagManager from 'react-gtm-module';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Toaster } from 'react-hot-toast';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const queryClient = new QueryClient();

const isIE =
  Boolean(navigator.userAgent.indexOf('MSIE') !== -1) ||
  Boolean(navigator.appVersion.indexOf('Trident/') > -1);

export const tagManagerArgs = {
  gtmId: GTM_ID,
};

if (Boolean(GTM_ID)) {
  TagManager.initialize(tagManagerArgs);
}

i18n.use(initReactI18next).init({
  fallbackLng:
    process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_ENV === 'preprod'
      ? null
      : 'fr',
  lng: getLocale().name,
  defaultNS: 'shared',
  resources: {
    fr: require('./locales/fr.json'),
    'fr-CA': cleanDeep(require('./locales/fr-ca.json')),
    en: require('./locales/en.json'),
  },
  interpolation: {
    escapeValue: false,
  },
});

if (!Boolean(isIE)) {
  const Auth = lazy(() => import('containers/Auth'));
  const Debug = lazy(() => import('containers/Debug'));
  const App = lazy(() => import('App'));
  const Callback = lazy(() => import('containers/Callback'));
  const CheckYourEmails = lazy(() => import('components/CheckYourEmails'));
  const CheckProfile = lazy(() => import('containers/CheckProfile'));

  const Loading = () => (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        backgroundColor: ui('background'),
      }}
    />
  );

  store.subscribe(() => {
    const state = store.getState();
    const {
      ui: { selectedPlaceId, hasSkippedCheck },
      user,
    } = state;

    saveState({
      user: omit(user, 'error'),
      ui: { selectedPlaceId, hasSkippedCheck },
    });
  });

  render(
    <Suspense fallback={<Loading />}>
      <QueryClientProvider client={queryClient}>
        <GraphQLProvider>
          <ThemeProvider theme={theme}>
            <ErrorBoundary>
              <Provider store={store}>
                <SnacksProvider>
                  <TokenSender destination={SO_GENERAL_MEETING_WEBAPP_URL}>
                    <ConnectedRouter history={history}>
                      <>
                        <Switch>
                          <Route path="/connexion" component={Auth} />
                          <Route path="/inscription" component={Auth} />
                          <Route path="/mot-de-passe-oublie" component={Auth} />
                          <Route path="/callback/:type" component={Callback} />
                          <Route
                            path="/email-envoye"
                            component={CheckYourEmails}
                          />
                          <Route
                            path="/verifier-profil"
                            component={CheckProfile}
                          />
                          <Route path="/token-login" component={TokenLogin} />
                          <Route path="/maintenance" component={Maintenance} />

                          <Route component={App} />
                        </Switch>
                        {ENVIRONMENT === 'development' && (
                          <Route path="/connexion" component={Debug} />
                        )}
                        <Toaster
                          position="bottom-center"
                          containerStyle={{ zIndex: 2147483647 }}
                        />
                      </>
                    </ConnectedRouter>
                  </TokenSender>
                </SnacksProvider>
              </Provider>
            </ErrorBoundary>
          </ThemeProvider>
        </GraphQLProvider>
      </QueryClientProvider>
    </Suspense>,
    document.getElementById('app'),
  );

  unregister();
} else {
  render(<IE />, document.getElementById('app'));
}
