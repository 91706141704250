import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { spacing, List, NavLink, size, media } from './';
// import background from 'images/icons/plus/menu.svg';
// import contacts from 'images/icons/lineking/icUser.svg';
// import documents from 'images/icons/lineking/briefcase.svg';
// import messages from 'images/icons/lineking/chat-bubble.svg';
// import doorOut from 'images/icons/lineking/door-out.svg';
// import elevator from 'images/icons/lineking/elevator.svg';
// import bin from 'images/icons/lineking/bin.svg';
// import bicycle from 'images/icons/lineking/bicycle.svg';
// import parking from 'images/icons/lineking/parking.svg';
// import parkingIn from 'images/icons/lineking/parking-in.svg';
// import bench from 'images/icons/lineking/bench.svg';
// import lights from 'images/icons/lineking/lights.svg';
// import amperMeter from 'images/icons/lineking/amper-meter.svg';
import { getAttachmentURL } from 'utils';
import { MAIN_HEADER_HEIGHT } from 'ui';
import { formatDate } from 'utils';

export const Sidebar = styled.div`
  background-color: #fff;
  position: relative;
  width: 100%;

  ${media.tablet`
    min-height: 100%;
    flex: 0 0 300px;
    border-right: 1px solid ${({ theme }) => theme.separator};
  `};

  ${props =>
    props.multi &&
    props.one &&
    css`
      position: relative;
      z-index: 2;
      box-shadow: none;
    `};

  ${props =>
    props.two &&
    css`
      ${media.tablet`flex: 0 0 300px;`};
    `};

  ${props =>
    props.hasHeader &&
    css`
      padding-top: 58px;
    `};

  ${props =>
    props.secondary &&
    css`
      border-right: 0;
      border-left: 1px solid ${({ theme }) => theme.separator};
      position: fixed;
      top: ${MAIN_HEADER_HEIGHT};
      width: 300px;
      right: 0;
      z-index: 1;
    `};

  ${props =>
    props.isLarge &&
    css`
      ${media.tablet`min-width: 300px;`};
      ${media.desktop`min-width: 420px;`};
    `};

  ${props =>
    props.shouldBeHiddenOnSmallViewports &&
    css`
      display: none;

      ${media.tablet`display: block`};
    `};
`;

export const SidebarMenu = styled(List)`
  display: none;

  ${props =>
    props.isAlwaysShown &&
    css`
      display: block;
    `};

  ${media.tablet`display: block;`};

  ${props =>
    props.dark &&
    css`
      color: #fff;
      background-color: ${({ theme }) => theme.sidebarSubmenuBackgroundColor};
    `};
`;

export const SidebarMenuItem = styled.li`
  position: relative;

  + li:before {
    display: block;
  }
`;

export const SidebarMenuLinkWrapper = styled(NavLink)`
  display: flex;
  align-items: center;
  padding-left: ${spacing(2.25)};
  padding-right: ${spacing(0.5)};
  color: ${({ theme }) => theme.sidebarLinkColor};
  font-weight: ${({ theme }) => theme.sidebarMenuActiveLinkFontWeight};

  &.active {
    color: ${({ theme }) => theme.sidebarMenuActiveLinkColor};
    background-color: ${({ theme }) =>
      theme.sidebarMenuActiveLinkBackgroundColor};

    > span {
      background: ${({ theme }) =>
        theme.sidebarMenuActiveLinkInnerBackgroundColor};
    }
  }

  > span {
    border-radius: ${({ theme }) => theme.borderRadius};
    display: block;
    padding: ${({ theme }) => theme.sidebarMenuActiveLinkInnerVerticalPadding}
      ${spacing(0.75)};
    width: 100%;
  }
`;

// const SideBarIconWrapper = styled.span`
//   position: relative;
//   width: 36px;
//   height: 36px;
//   margin-right: ${spacing(0.5)};
//   background-image: url(${background});
//   background-repeat: no-repeat;
//   background-size: cover;
//
//   > img {
//     position: absolute;
//     height: 24px;
//     width: 24px;
//     top: 6px;
//     left: 6px;
//   }
// `;

// const getSidebarIcon = icon => {
//   switch (icon) {
//     case 'documents':
//       return documents;
//     case 'messages':
//       return messages;
//     case 'contacts':
//     case 'door-out':
//       return doorOut;
//     case 'elevator':
//       return elevator;
//     case 'bin':
//       return bin;
//     case 'bicycle':
//       return bicycle;
//     case 'parking':
//       return parking;
//     case 'parking-in':
//       return parkingIn;
//     case 'bench':
//       return bench;
//     case 'lights':
//       return lights;
//     case 'amper-meter':
//       return amperMeter;
//     default:
//       return contacts;
//   }
// };

// const SidebarIcon = ({ icon, iconURL }) => (
//   <SideBarIconWrapper>
//     {iconURL && <img src={iconURL} alt="" />}
//     {!iconURL && <img src={getSidebarIcon(icon)} alt="" />}
//   </SideBarIconWrapper>
// );

export const SidebarMenuLink = ({ icon, iconURL, to, children }) => (
  <SidebarMenuLinkWrapper to={to}>{children}</SidebarMenuLinkWrapper>
);

export const Wrapper = styled.div`
  ${media.tablet`
    min-height: calc(100vh - ${MAIN_HEADER_HEIGHT});
    display: flex;
    align-items: stretch;
  `};
`;

export const Content = styled.div`
  flex: 1;
`;

export const SidebarEntity = styled.div`
  min-height: 200px;
  padding: ${spacing()};
  text-align: center;
  border-bottom: 1px solid ${({ theme }) => theme.separator};

  > img {
    width: ${spacing(6)};
  }
`;

export const SidebarEntityTitle = styled.h2`
  font-weight: 600;
  font-size: ${size(2)};
  margin-bottom: 0;
  margin-top: ${spacing()};
`;

export const SidebarEntityParagraph = styled.p`
  color: ${({ theme }) => theme.textLight};
  margin-top: ${spacing(0.25)};
  margin-bottom: 0;
`;

export const SidebarEntityButtons = styled.div`
  margin-top: ${spacing()};
  margin-left: ${spacing(-0.5)};
`;

export const SidebarEntityButton = styled.button`
  display: inline-block;
  background: linear-gradient(
    to bottom,
    #fff,
    ${({ theme }) => theme.background}
  );
  padding: ${spacing(0.25)} ${spacing(0.5)};
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: ${({ theme }) => theme.borderRadius};
  margin-left: ${spacing(0.5)};
  margin-bottom: ${spacing(0.5)};
  font-size: ${size(-1)};
  outline: none;
  color: inherit;
  text-decoration: none;

  &:active,
  &:focus {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  }
`;

export const SidebarEntityLinkButton = SidebarEntityButton.withComponent(Link);

const SidebarThumbnail = styled.a`
  display: table;
  width: 100%;
  color: inherit;
  text-decoration: none;
`;

const SidebarThumbnailImageWrapper = styled.span`
  display: table-cell;
  padding: ${spacing(0.5)};
  padding-left: ${spacing()};
  width: 0;
`;

const SidebarThumbnailImage = styled.span`
  display: block;
  width: ${spacing(3)};
  height: ${spacing(3)};
  background-size: cover;
  background-position: center center;
  border-radius: ${({ theme }) => theme.borderRadius};
  vertical-align: middle;
`;

const SidebarThumbnailBody = styled.span`
  display: table-cell;
  vertical-align: middle;
  width: 100%;
`;

const SidebarThumbnailName = styled.span`
  font-weight: 600;
  font-size: ${size()};
  display: block;
  word-break: break-all;
`;

const SidebarThumbnailMeta = styled.span`
  font-weight: 400;
  font-size: ${size(-1)};
  color: ${({ theme }) => theme.textLight};
`;

export const SidebarAttachment = ({
  fileUrl,
  sourceUpdatedAt,
  sourceFileName,
}) => (
  <SidebarThumbnail href={getAttachmentURL(fileUrl)} download>
    <SidebarThumbnailImageWrapper>
      <SidebarThumbnailImage
        style={{ backgroundImage: `url(${getAttachmentURL(fileUrl)})` }}
      />
    </SidebarThumbnailImageWrapper>
    <SidebarThumbnailBody>
      <SidebarThumbnailName>{sourceFileName}</SidebarThumbnailName>
      <SidebarThumbnailMeta>
        {formatDate(sourceUpdatedAt, 'd MMMMM yyyy')}
      </SidebarThumbnailMeta>
    </SidebarThumbnailBody>
  </SidebarThumbnail>
);

export const SidebarTitle = styled.h2`
  font-weight: 600;
  font-size: ${size(1)};
  padding: ${spacing()} ${spacing()} ${spacing(0.5)};
  margin: 0;
  position: relative;

  ${props =>
    props.hasBottomSeparator &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.separator};
    `};

  ${props =>
    props.hasNoHorizontalSpacing &&
    css`
      padding-left: 0;
      padding-right: 0;
    `};
`;

export const SidebarTitleImage = styled.img`
  position: absolute;
  right: ${spacing()};
  top: 50%;
  width: 24px;
  transform: translateY(-50%);
`;
