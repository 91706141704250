import Icon from 'react-icon-base';

const IcPDF = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        d="M1.571 0H14l8 8v14.5c0 .829-.704 1.5-1.571 1.5H1.57C.704 24 0 23.329 0 22.5v-21C0 .671.704 0 1.571 0z"
        id="a"
      />
      <path
        d="M22 7.958L15.643 8c-.868 0-1.572-.7-1.572-1.563L14 0l8 7.958z"
        id="c"
      />
      <path
        d="M17.465 11.345h-1.47v2.045h1.238c.384 0 .576.33.576.663 0 .34-.192.683-.576.683h-1.237v1.504c0 .489-.257.76-.6.76-.345 0-.6-.271-.6-.76v-5.48c0-.399.223-.76.623-.76h2.046c.352 0 .535.33.535.673 0 .331-.176.672-.535.672zM10.549 10c2.021 0 3.177 1.412 3.178 3.588 0 2.059-1.227 3.392-3.087 3.392H9.069c-.297 0-.712-.175-.712-.735v-5.49c0-.451.288-.755.695-.755h1.497zm.072 5.627c1.164 0 1.696-.93 1.696-2.107 0-1.256-.541-2.167-1.786-2.167h-.823v4.274h.913zM5.136 10c1.231 0 2.145.935 2.145 2.33 0 1.424-.947 2.31-2.094 2.31h-.93v1.6c0 .489-.268.76-.629.76-.36 0-.628-.271-.628-.76v-5.48c0-.458.234-.76.653-.76h1.483zm-.024 3.354c.527 0 .862-.448.863-1.033 0-.585-.336-1.034-.863-1.034h-.855v2.067h.855z"
        id="e"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M0 0h24v24H0z" />
      <g transform="translate(1)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          fill="#E2574C"
          mask="url(#b)"
          d="M-5.5 29.455h32.175v-34.91H-5.5z"
        />
        <mask id="d" fill="#fff">
          <use xlinkHref="#c" />
        </mask>
        <path fill="#B53629" mask="url(#d)" d="M9 12h13V0H9z" />
        <mask id="f" fill="#fff">
          <use xlinkHref="#e" />
        </mask>
        <path fill="#FFF" mask="url(#f)" d="M-2 21h26V4H-2z" />
      </g>
    </g>
  </Icon>
);

export default IcPDF;
