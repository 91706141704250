import get from 'lodash/get';
import { merge } from 'utils';
import { generatePagination } from 'utils/pagination';

const initialState = {
  isFetching: false,
  hasFailed: false,
  equipmentById: {},
  equipmentsIdsByPlaceId: [],
  reportEventById: {},
  reportCategoryById: {},
  reportsIdsByPlaceId: {},
  reportsIdsByEquipmentId: {},
  reportById: {},
  placesReportsPagination: {},
  witnessesIdsByReportId: {},
};

const reports = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'PLACE_REPORTS_SUCCESS':
      return {
        ...state,
        reportById: merge(
          state.reportById,
          get(payload, 'data.entities.reports'),
        ),
        reportEventById: merge(
          state.reportEventById,
          get(payload, 'data.entities.reportEvents'),
        ),
        reportCategoryById: merge(
          state.reportCategoryById,
          get(payload, 'data.entities.category'),
        ),
        placesReportsPagination: {
          ...state.placesReportsPagination,
          [payload.placeId]: merge(
            get(state, `placesReportsPagination[${payload.placeId}]`),
            generatePagination(
              state.placesReportsPagination,
              payload.placeId,
              action,
            ),
          ),
        },
      };

    case 'RESOLVE_REPORT_SUCCESS':
      return {
        ...state,
        reportById: merge(state.reportById, payload.data),
      };

    case 'REPORT_SUCCESS':
    case 'WITNESS_TOGGLE_SUCCESS':
      return {
        ...state,
        reportById: merge(state.reportById, get(payload, 'entities.reports')),
        reportCategoryById: merge(
          state.reportCategoryById,
          get(payload, 'entities.category'),
        ),
        reportEventById: merge(
          state.reportEventById,
          get(payload, 'entities.reportEvents'),
        ),
      };

    case 'NEW_REPORT_EVENT_SUCCESS':
      return {
        ...state,
        reportEventById: merge(
          state.reportEventById,
          get(payload, 'data.entities.reportEvents'),
        ),
        reportById: {
          ...state.reportById,
          [payload.id]: {
            ...get(state, `reportById['${payload.id}']`),
            reportEvents: [
              ...(get(state, `reportById['${payload.id}'].reportEvents`) || []),
              get(payload, 'data.result'),
            ],
          },
        },
      };

    case 'REPORT_WITNESSES_SUCCESS':
      return {
        ...state,
        witnessesIdsByReportId: {
          ...state.witnessesIdsByReportId,
          [payload.reportId]: payload.data.result,
        },
      };
    case 'EQUIPMENT_REPORTS_SUCCESS':
      return {
        ...state,
        reportById: merge(
          state.reportById,
          get(payload, 'data.entities.reports'),
        ),
        reportEventById: merge(
          state.reportEventById,
          get(payload, 'data.entities.reportEvents'),
        ),
        reportsIdsByEquipmentId: {
          ...state.reportsIdsByPlaceId,
          [payload.id]: payload.data.result,
        },
        reportCategoryById: merge(
          state.reportCategoryById,
          get(payload, 'data.entities.category'),
        ),
      };
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
};

export default reports;
