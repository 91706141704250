import styled from 'styled-components';
import { Title } from './base';

export const ModalContent = styled.section`
  img {
    display: block;
    max-width: 100%;
  }

  strong {
    font-weight: 600;
  }
`;

export const ModalBody = styled.div`
  padding: ${({ theme }) => theme.spacing()};
`;

export const ModalHeader = styled.header`
  text-align: center;
  background-color: #fff;

  ${props =>
    props.padded &&
    `
    padding: ${({ theme }) => theme.spacing()};
  `};
`;

export const ModalTitle = styled.h1`
  font-size: ${({ theme }) => theme.size(2)};
  margin-top: ${({ theme }) => theme.spacing()};
  margin-bottom: 0;
`;

export const ModalSubtitle = styled(Title)`
  margin-top: ${props => (props.marged ? spacing(2) : spacing())};
`;

export const ModalSection = styled.div`
  + div {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`;
