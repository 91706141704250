import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { ButtonReset } from '@tymate/margaret';
import { Legend } from '../ui';
import { useDropzone } from 'react-dropzone';
import { IoMdDocument } from 'react-icons/io';
import { camelizeKeys } from 'humps';
import MDSpinner from 'react-md-spinner';

const DragAndDropWrapper = styled(ButtonReset)`
  border: 1px dashed ${({ theme }) => theme.separator};
  background-color: #fff;
  min-height: 150px;
  padding: ${({ theme }) => theme.spacing(2)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  svg {
    color: ${({ theme }) => theme.separator};
    font-size: 4rem;
  }
`;

const DragAndDropContent = styled.div`
  max-width: 22em;
  line-height: 1.5;
  text-align: center;
  font-family: inherit;

  strong {
    color: ${({ theme }) => theme.primary};
    display: block;
    font-weight: 500;
  }
`;

const Dropzone = ({
  text = "Seuls les formats PDF, JPEG, et PNG sont acceptés.",
  onAdd,
  shouldReturnBase64,
  shouldUpload,
  post,
  ...props
}) => {
  const [isFetching, setIsFetching] = useState();
  const onDrop = useCallback(
    acceptedFiles => {
      if (shouldUpload) {
        setIsFetching(true);
      }

      acceptedFiles.forEach(file => {
        try {
          const reader = new FileReader();

          reader.onloadend = async () => {
            if (shouldReturnBase64) {
              onAdd(reader, file);
              return;
            }

            const result = await post('/v1/attachments', {
              attachment: {
                image_base: reader.result,
                source_file_name: file.name,
              },
            });

            onAdd(camelizeKeys(result));
            setIsFetching(false);
          };

          reader.readAsDataURL(file);
        } catch (err) {
          console.error(err);
        }
      });
    },
    [onAdd, shouldReturnBase64, shouldUpload],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <DragAndDropWrapper {...getRootProps()}>
      {isFetching || props.isFetching ? (
        <MDSpinner singleColor="currentColor" size={16} />
      ) : (
        <>
          <input {...getInputProps()} accept="image/*,.pdf" />
          <IoMdDocument />
          {isDragActive ? (
            <p>Glissez votre fichier ici…</p>
          ) : (
            <DragAndDropContent>
              <div>
                Déposez des éléments ici pour les ajouter ou les{' '}
                <strong>importer depuis l’appareil</strong>
              </div>
              <Legend>{text}</Legend>
            </DragAndDropContent>
          )}
        </>
      )}
    </DragAndDropWrapper>
  );
};

Dropzone.defaultProps = { shouldUpload: true };

export default Dropzone;
