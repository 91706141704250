import get from 'lodash/get';
import { addLocalPaginationToGlobalState, merge } from 'utils';

const initialState = {
   accountPlaceEntryById: {},
   accountPlaceEntriesPagination: {},
   submittedAccountPlaceEntriesPagination: {},
   invoiceById: {},
   invoiceGroupById: {},
   invoicesPagination: {},
   coownerAmountById: {},
   coownerAmountsPagination: {},
   accountPlaceEntriesIdsByBudgetId: {},
   providerAccountPlaceEntriesIdsByBudgetId: {},
   accountCodeAccountPlaceEntriesIdsByBudgetId: {},
   providerAccountPlaceEntryById: {},
   accountCodeAccountPlaceEntryById: {},
   orphanAccountPlaceEntriesIdsByBudgetId: {},
   orphanAccountPlaceEntriesTotalByBudgetId: {},
};

const accounts = (state = initialState, action) => {
   const { payload, type, meta } = action;
   let nextState = { ...state };

   switch (type) {
      case 'CONTEST_ACCOUNT_PLACE_ENTRY_SUCCESS':
      case 'CONFIRM_ACCOUNT_PLACE_ENTRY_SUCCESS':
         return {
            ...state,
            accountPlaceEntryById: merge(
               state.accountPlaceEntryById,
               get(payload, 'data.entities.accountPlaceEntries')
            ),
         };

      case 'TREASURY_ACCOUNT_PLACE_ENTRY_SUCCESS':
         addLocalPaginationToGlobalState({
            state: nextState.accountPlaceEntriesPagination,
            payload,
            meta,
            status: 'DONE',
         });
         return {
            ...nextState,
            accountPlaceEntryById: merge(
               state.accountPlaceEntryById,
               get(payload, 'data.entities.accountPlaceEntries')
            ),
         };

      case 'TREASURY_INVOICES_SUCCESS':
         addLocalPaginationToGlobalState({
            state: nextState.invoicesPagination,
            payload,
            meta,
            status: 'DONE',
         });

         return {
            ...nextState,
            invoiceById: merge(state.invoiceById, get(payload, 'data.entities.invoices')),
            invoiceGroupById: merge(state.invoiceGroupById, get(payload, 'data.entities.invoicesGroups')),
         };

      case 'TREASURY_COOWNER_AMOUNTS_SUCCESS':
         addLocalPaginationToGlobalState({
            state: nextState.coownerAmountsPagination,
            payload,
            meta,
            status: 'DONE',
         });

         return {
            ...nextState,
            coownerAmountById: merge(state.coownerAmountById, get(payload, 'data.entities.coownerAmounts')),
         };

      case 'BUDGET_ACCOUNT_PLACE_ENTRIES_SUCCESS':
         return {
            ...state,
            accountPlaceEntryById: merge(
               state.accountPlaceEntryById,
               get(payload, 'data.entities.accountPlaceEntries')
            ),
            accountPlaceEntriesIdsByBudgetId: {
               ...state.accountPlaceEntriesIdsByBudgetId,
               [payload.budgetId]: payload.data.result,
            },
         };

      case 'BUDGET_PROVIDERS_SUCCESS':
         return {
            ...state,
            accountPlaceEntryById: merge(
               state.accountPlaceEntryById,
               get(payload, 'data.entities.accountPlaceEntries')
            ),
            providerAccountPlaceEntriesIdsByBudgetId: {
               ...state.providerAccountPlaceEntriesIdsByBudgetId,
               [payload.budgetId]: payload.data.result,
            },
            providerAccountPlaceEntryById: merge(
               state.providerAccountPlaceEntryById,
               get(payload, 'data.entities.providerAccountPlaceEntries')
            ),
         };

      case 'BUDGET_ACCOUNT_CODES_SUCCESS':
         return {
            ...state,
            accountPlaceEntryById: merge(
               state.accountPlaceEntryById,
               get(payload, 'data.entities.accountPlaceEntries')
            ),
            accountCodeAccountPlaceEntriesIdsByBudgetId: {
               ...state.accountCodeAccountPlaceEntriesIdsByBudgetId,
               [payload.budgetId]: payload.data.result,
            },
            accountCodeAccountPlaceEntryById: merge(
               state.accountCodeAccountPlaceEntryById,
               get(payload, 'data.entities.accountCodeAccountPlaceEntries')
            ),
         };

      case 'BUDGET_ORPHAN_ENTRIES_SUCCESS':
         return {
            ...state,
            orphanAccountPlaceEntriesIdsByBudgetId: {
               ...state.orphanAccountPlaceEntriesIdsByBudgetId,
               [payload.budgetId]: get(payload, 'data.result'),
            },
            accountPlaceEntryById: merge(
               state.accountPlaceEntryById,
               get(payload, 'data.entities.accountPlaceEntries')
            ),
         };

      case 'TREASURY_ACCOUNT_PLACE_ENTRIES_SUCCESS':
         addLocalPaginationToGlobalState({
            state: nextState.accountPlaceEntriesPagination,
            payload,
            meta,
            status: 'DONE',
         });
         return {
            ...nextState,
            accountPlaceEntryById: merge(
               state.accountPlaceEntryById,
               get(payload, 'data.entities.accountPlaceEntries')
            ),
         };

      case 'SUBMITTED_ACCOUNT_PLACE_ENTRIES_SUCCESS':
         addLocalPaginationToGlobalState({
            state: nextState.submittedAccountPlaceEntriesPagination,
            payload,
            meta,
            status: 'DONE',
         });

         return {
            ...nextState,
            accountPlaceEntryById: merge(
               state.accountPlaceEntryById,
               get(payload, 'data.entities.accountPlaceEntries')
            ),
         };

      case 'LOGOUT':
         return initialState;

      default:
         return state;
   }
};

export default accounts;
