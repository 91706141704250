import { Component } from 'react';
import styled, { withTheme } from 'styled-components';
import { Buttons as RawButtons, ButtonReset } from '@tymate/margaret';
import Modal from 'react-modal';
import { MdFileDownload } from 'react-icons/md';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { MdClose } from 'react-icons/md';
import { getAttachmentURL } from '../../utils';
import { Document as PDFDocument, Page } from 'react-pdf';
import IcWarning from '../../icons/Warning';
import MDSpinner from 'react-md-spinner';
import { withTranslation } from 'react-i18next';

const el = document.querySelector('#app');

const Buttons = styled(RawButtons)`
  margin-left: 0;
  display: flex;
  justify-content: flex-end;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  canvas {
    margin-left: auto;
    margin-right: auto;
  }
`;

const ArrowButton = styled(ButtonReset)`
  color: #fff;
  font-size: ${({ theme }) => theme.spacing(3)};
  cursor: pointer;
  margin-left: ${({ theme }) => theme.spacing(0.5)};

  &:disabled {
    opacity: 0.5;
    cursor: initial;
  }
`;

const LoadingContainer = styled.div`
  padding: ${({ theme }) => theme.spacing()};
  background-color: #fff;
  width: 25em;
  max-width: calc(100vw - 32px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: ${({ theme }) => theme.borderRadius};
  text-align: center;

  > span {
    margin-bottom: ${({ theme }) => theme.spacing()};
    font-size: ${({ theme }) => theme.spacing(3)};
  }
`;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '800px',
    maxWidth: '90vw',
    backgroundColor: 'transparent',
    borderRadius: 0,
    overflowX: 'hidden',
    border: 0,
  },
  overlay: {
    zIndex: 10,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
  },
};

class MagnifiableAttachment extends Component {
  state = {
    isOpen: false,
    calculationIsDone: false,
    numPages: null,
    pageNumber: 1,
    scale: null,
    handleRequestOpen: () => null,
  };

  static defaultProps = {
    sourceContentType: '',
  };

  componentDidMount() {
    this.setWrapperSize();
    window.addEventListener('resize', this.setWrapperSize);
  }

  handleRequestOpen = () => {
    this.setState({ isOpen: true });
  };

  handleRequestClose = () => {
    this.setState({ isOpen: false, pageNumber: 1 });
    if (this.props.onRequestClose) {
      this.props.onRequestClose();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { pageNumber } = this.state;

    if (prevState.pageNumber !== pageNumber) {
      this.setWrapperSize();
    }

    if (!prevState.isOpen && this.state.isOpen) {
      this.setState({ pageNumber: 1 });
      document.documentElement.style.overflowY = 'hidden';
      document.addEventListener('keydown', this.handleKeyDown);
    }

    if (prevState.isOpen && !this.state.isOpen) {
      document.documentElement.style.overflowY = 'auto';
      document.removeEventListener('keydown', this.handleKeyDown);
    }
  }

  handleLoadDocument = async pdf => {
    const { numPages } = pdf;

    const pagesPromises = [...Array(numPages)]
      .map((v, index) => index + 1)
      .map(index => pdf.getPage(index));

    const result = await Promise.all(pagesPromises);

    const pages = result
      .map(({ _pageInfo }) => ({
        width: _pageInfo?.view?.[2],
        height: _pageInfo?.view?.[3],
        ratio: _pageInfo?.view?.[2] / _pageInfo?.view?.[3],
      }))
      .reduce(
        (acc, curr, index) => ({
          ...acc,
          [index + 1]: curr,
        }),
        {},
      );

    this.setState({ numPages, pages, calculationIsDone: true });
    this.setWrapperSize();
  };

  setWrapperSize = () => {
    const { pageNumber, pages } = this.state;

    if (!this.wrapper || !this.page || !pages) {
      return;
    }

    const { width, height, ratio } = pages[pageNumber];
    const maxHeight = this.wrapper.getBoundingClientRect().height;
    const maxWidth = this.wrapper.getBoundingClientRect().width;

    const maxRatio = maxWidth / maxHeight;

    if (width <= maxWidth && height <= maxHeight) {
      this.setState({
        scale: 1,
      });
      return;
    }

    if (ratio > maxRatio) {
      this.setState({
        scale: maxWidth / width,
      });
      return;
    }

    this.setState({
      scale: maxHeight / height,
    });
  };

  handleClickOnWrapper = e => {
    if (e.target.nodeName !== 'DIV') {
      return;
    }

    this.setState({ isOpen: false });
  };

  handleKeyDown = e => {
    const { pageNumber, numPages } = this.state;
    e.preventDefault();

    switch (e.key) {
      case 'ArrowLeft':
        this.setState({ pageNumber: Math.max(1, pageNumber - 1) });
        break;
      case 'ArrowRight':
        this.setState({ pageNumber: Math.min(numPages, pageNumber + 1) });
        break;
      default:
        return;
    }
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.setWrapperSize);
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  render() {
    const {
      sourceContentType,
      sourceFileName,
      fileUrl,
      children,
      shallBeOpen,
      t,
      theme,
    } = this.props;
    const {
      isOpen,
      calculationIsDone,
      numPages,
      pageNumber,
      scale,
    } = this.state;

    return (
      <>
        {children(this.handleRequestOpen)}

        {(isOpen || shallBeOpen) && (
          <Modal
            appElement={el}
            isOpen
            style={customStyles}
            onRequestClose={this.handleRequestClose}
          >
            {((sourceContentType.indexOf('pdf') > -1 && calculationIsDone) ||
              sourceContentType.indexOf('image') > -1) && (
              <Buttons>
                <ArrowButton
                  href={`${getAttachmentURL(fileUrl)}`}
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                  as="a"
                >
                  <MdFileDownload />
                </ArrowButton>

                {Boolean(numPages) && numPages > 1 && (
                  <>
                    <ArrowButton
                      disabled={pageNumber === 1}
                      left
                      onClick={() =>
                        this.setState({ pageNumber: pageNumber - 1 })
                      }
                    >
                      <MdKeyboardArrowLeft />
                    </ArrowButton>

                    <ArrowButton
                      disabled={pageNumber === numPages}
                      onClick={() =>
                        this.setState({ pageNumber: pageNumber + 1 })
                      }
                    >
                      <MdKeyboardArrowRight />
                    </ArrowButton>
                  </>
                )}

                <ArrowButton onClick={this.handleRequestClose}>
                  <MdClose />
                </ArrowButton>
              </Buttons>
            )}

            <Wrapper
              ref={ref => (this.wrapper = ref)}
              onClick={this.handleClickOnWrapper}
              style={{
                width: `100%`,
                height: `calc(100vh - ${({ theme }) => theme.spacing(4)})`,
              }}
            >
              {(sourceContentType || '').indexOf('pdf') > -1 && (
                <PDFDocument
                  file={`${getAttachmentURL(fileUrl)}`}
                  onLoadSuccess={this.handleLoadDocument}
                  error={
                    <LoadingContainer>
                      <IcWarning />
                      <br />
                      {t('magnifiableAttachment:loadingError1')} <br />{' '}
                      {t('magnifiableAttachment:loadingError2')}{' '}
                      <a
                        href={`${getAttachmentURL(fileUrl)}`}
                        download
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('magnifiableAttachment:download')}
                      </a>
                      .
                    </LoadingContainer>
                  }
                  loading={
                    <LoadingContainer>
                      <MDSpinner singleColor={theme.primary} />
                      <br />
                      {t('magnifiableAttachment:loading')}
                    </LoadingContainer>
                  }
                >
                  {calculationIsDone && (
                    <Page
                      pageNumber={pageNumber}
                      scale={scale}
                      ref={c => (this.page = c)}
                    />
                  )}
                </PDFDocument>
              )}

              {sourceContentType.indexOf('image') > -1 && (
                <img
                  src={getAttachmentURL(fileUrl)}
                  style={{
                    display: 'block',
                    maxWidth: '100%',
                    maxHeight: '100%',
                  }}
                  alt={sourceFileName}
                />
              )}
            </Wrapper>
          </Modal>
        )}
      </>
    );
  }
}

export default withTranslation()(withTheme(MagnifiableAttachment));
