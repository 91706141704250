import { Component } from 'react';
import styled from 'styled-components';
import { Buttons as RawButtons, ButtonReset } from '@tymate/margaret';
import { Document as PDFDocument, Page } from 'react-pdf';
import { getAttachmentURL } from '../../utils';
import Modal from 'react-modal';
import { MdFileDownload } from 'react-icons/md';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { MdClose } from 'react-icons/md';
import { MdInsertDriveFile } from 'react-icons/md';
import IcPDF from '../../icons/PDF';

const DocumentLink = styled(ButtonReset)`
  display: block;
  padding: ${({ theme }) => theme.spacing(0.5)};
  color: ${({ theme }) => theme.text};
  border: 1px solid ${({ theme }) => theme.separatorLight};
  text-decoration: none;
  cursor: pointer;
`;

const Buttons = styled(RawButtons)`
  margin-left: 0;
  display: flex;
  justify-content: flex-end;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  canvas {
    margin-left: auto;
    margin-right: auto;
  }
`;

const ArrowButton = styled(ButtonReset)`
  color: #fff;
  font-size: ${({ theme }) => theme.size(3)};
  cursor: pointer;
  margin-left: ${({ theme }) => theme.spacing(0.5)};

  &:disabled {
    opacity: 0.5;
    cursor: initial;
  }
`;

const IconContainer = styled.span`
  > svg {
    width: ${({ theme }) => theme.spacing(6)};
    height: ${({ theme }) => theme.spacing(6)};
  }

  ${props =>
    !props.shouldHavePreview &&
    !props.isInInput &&
    css`
      > svg {
        width: ${({ theme }) => theme.spacing(2)};
        height: ${({ theme }) => theme.spacing(2)};
      }
    `};

  ${props =>
    props.isInInput &&
    css`
      ${media.tablet`
        > svg {
          width: ${({ theme }) => theme.spacing(2)};
          height: ${({ theme }) => theme.spacing(2)};
        }
      `};
    `};
`;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '800px',
    backgroundColor: 'transparent',
    borderRadius: 0,
    overflowX: 'hidden',
  },
  overlay: {
    zIndex: 10,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
  },
};

class Document extends Component {
  state = {
    pageNumber: 1,
    numPages: null,
    scale: 1,
    calculationIsDone: false,
  };

  componentDidMount() {
    this.setWrapperSize();
    window.addEventListener('resize', this.setWrapperSize);
  }

  componentDidUpdate(prevProps, prevState) {
    const { pageNumber } = this.state;

    if (prevState.pageNumber !== pageNumber) {
      this.setWrapperSize();
    }

    if (!prevState.isOpen && this.state.isOpen) {
      this.setState({ pageNumber: 1 });
      document.documentElement.style.overflowY = 'hidden';
      document.addEventListener('keydown', this.handleKeyDown);
    }

    if (prevState.isOpen && !this.state.isOpen) {
      document.documentElement.style.overflowY = 'auto';
      document.removeEventListener('keydown', this.handleKeyDown);
    }
  }

  handleLoadDocument = async pdf => {
    const { numPages } = pdf;

    const pagesPromises = [...Array(numPages)]
      .map((v, index) => index + 1)
      .map(index => pdf.getPage(index));

    const result = await Promise.all(pagesPromises);

    const pages = result
      .map(({ _pageInfo }) => ({
        width: _pageInfo?.view?.[2],
        height: _pageInfo?.view?.[3],
        ratio: _pageInfo?.view?.[2] / _pageInfo?.view?.[3],
      }))
      .reduce(
        (acc, curr, index) => ({
          ...acc,
          [index + 1]: curr,
        }),
        {},
      );

    this.setState({ numPages, pages, calculationIsDone: true });
    this.setWrapperSize();
  };

  setWrapperSize = () => {
    const { pageNumber, pages } = this.state;

    if (!this.wrapper || !this.page || !pages) {
      return;
    }

    const { width, height, ratio } = pages[pageNumber];
    const maxHeight = this.wrapper.getBoundingClientRect().height;
    const maxWidth = this.wrapper.getBoundingClientRect().width;

    const maxRatio = maxWidth / maxHeight;

    if (width <= maxWidth && height <= maxHeight) {
      this.setState({
        scale: 1,
      });
      return;
    }

    if (ratio > maxRatio) {
      this.setState({
        scale: maxWidth / width,
      });
      return;
    }

    this.setState({
      scale: maxHeight / height,
    });
  };

  handleClickOnWrapper = e => {
    if (e.target.nodeName !== 'DIV') {
      return;
    }

    this.setState({ isOpen: false });
  };

  handleKeyDown = e => {
    const { pageNumber, numPages } = this.state;
    e.preventDefault();

    switch (e.key) {
      case 'ArrowLeft':
        this.setState({ pageNumber: Math.max(1, pageNumber - 1) });
        break;
      case 'ArrowRight':
        this.setState({ pageNumber: Math.min(numPages, pageNumber + 1) });
        break;
      default:
        return;
    }
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.setWrapperSize);
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  render() {
    const { url, name } = this.props;
    const {
      pageNumber,
      numPages,
      isOpen,
      scale,
      calculationIsDone,
    } = this.state;

    return (
      <>
        <DocumentLink onClick={() => this.setState({ isOpen: true })}>
          <IcPDF /> {name}
        </DocumentLink>

        <Modal
          isOpen={isOpen}
          style={customStyles}
          onRequestClose={() => this.setState({ isOpen: false })}
        >
          {calculationIsDone && (
            <Buttons>
              <ArrowButton
                href={`${getAttachmentURL(url)}`}
                download
                target="_blank"
                rel="noopener noreferrer"
                as="a"
              >
                <MdFileDownload />
              </ArrowButton>

              {Boolean(numPages) && numPages > 1 && (
                <>
                  <ArrowButton
                    disabled={pageNumber === 1}
                    left
                    onClick={() =>
                      this.setState({ pageNumber: pageNumber - 1 })
                    }
                  >
                    <MdKeyboardArrowLeft />
                  </ArrowButton>

                  <ArrowButton
                    disabled={pageNumber === numPages}
                    onClick={() =>
                      this.setState({ pageNumber: pageNumber + 1 })
                    }
                  >
                    <MdKeyboardArrowRight />
                  </ArrowButton>

                  <ArrowButton onClick={() => this.setState({ isOpen: false })}>
                    <MdClose />
                  </ArrowButton>
                </>
              )}
            </Buttons>
          )}

          <Wrapper
            ref={ref => (this.wrapper = ref)}
            onClick={this.handleClickOnWrapper}
            style={{
              width: `100%`,
              height: `calc(100vh - ${({ theme }) => theme.spacing(4)})`,
            }}
          >
            <PDFDocument
              file={getAttachmentURL(url)}
              onLoadSuccess={this.handleLoadDocument}
              loading={
                <IconContainer>
                  <IcPDF />
                </IconContainer>
              }
            >
              {calculationIsDone && (
                <Page
                  pageNumber={pageNumber}
                  scale={scale}
                  ref={c => (this.page = c)}
                />
              )}
            </PDFDocument>
          </Wrapper>
        </Modal>
      </>
    );
  }
}

export default Document;
