import { useEffect, useRef, useState } from 'react';
import { reduxForm } from 'redux-form';
import { connect, useSelector } from 'react-redux';
import { skipCheck } from 'actions/ui';
import { ConnectedPassword, ConnectedTextField } from './TextField';
import { isEmail, isRequired, isValidEmail, normalizePhone } from 'utils/validation';
import { BoxDescription, BoxTitle } from 'ui/auth';
import { ButtonNew, Buttons, DefaultLink, LoadingButton } from 'ui';
import { getAppName } from 'components/AppBanner';
import { ESE_URL, GOOGLE_RECAPTCHA_KEY, SERGIC_OFFER, VSY_URL } from '../constants';
import { useTranslation } from 'react-i18next';
import { Button } from '@tymate/sergic';
import { kebabCase, lowerCase } from 'lodash';
import ReCAPTCHA from 'react-google-recaptcha';
import styled from 'styled-components';
import { ui } from 'ag/src/ui';

export const ErrorMessage = styled.div`
   color: ${ui('error')};
`;

export const LoginForm = ({ onSubmit, shouldShowExtraFields, type, error, offer }) => {
   const { t } = useTranslation('authForm');
   const passwordRef = useRef();

   useEffect(() => {
      if (!shouldShowExtraFields || !document.querySelector('#login-password')) {
         return;
      }

      document.querySelector('#login-password').focus();
   }, [shouldShowExtraFields]);

   const formatError = error => {
      switch (error) {
         case 'account_locked':
            return t('errors.accountLocked');
         case 'last_attempt_before_lock':
            return t('errors.lastAttempt');
         default:
            return error;
      }
   };

   const getTitleOffer = () => {
      let message = '';
      if (SERGIC_OFFER === 'ESE') {
         message = t('title_space') + lowerCase(getAppName(SERGIC_OFFER));
      } else if (SERGIC_OFFER === 'VSY') {
         message = t('title_space') + lowerCase(getAppName(SERGIC_OFFER));
      } else {
         message = t('title');
      }
      return message;
   };

   return (
      <form onSubmit={onSubmit}>
         <BoxTitle>{getTitleOffer()}</BoxTitle>
         <ConnectedTextField
            id='login-username'
            label={t('fields.email')}
            type='email'
            name='username'
            validate={[isEmail, isRequired]}
            autoComplete='email'
         />
         {shouldShowExtraFields && (
            <>
               <ConnectedPassword
                  type={type}
                  label={t('fields.password')}
                  id='login-password'
                  name='password'
                  autoComplete='off'
                  ref={passwordRef}
               />

               <DefaultLink to='/mot-de-passe-oublie' style={{ display: 'block', marginTop: 16 }}>
                  {t('fields.forgotPassword')}
               </DefaultLink>
            </>
         )}
         {Boolean(error) && <ErrorMessage>{formatError(error)}</ErrorMessage>}
         <Buttons right marged>
            <LoadingButton big isPrimary>
               {t('connexion')}
            </LoadingButton>
         </Buttons>
      </form>
   );
};

export const SignupForm = ({ onSubmit }) => {
   const { t } = useTranslation('authForm');
   return (
      <form onSubmit={onSubmit}>
         <BoxTitle>{t('signUp.title')}</BoxTitle>

         <BoxDescription>{t('signUp.description')}</BoxDescription>

         <ConnectedTextField
            id='signup-username'
            label={t('fields.email')}
            type='email'
            name='username'
            validate={[isEmail, isRequired]}
         />

         <Buttons right>
            <LoadingButton big isPrimary>
               {t('validate')}
            </LoadingButton>
         </Buttons>
      </form>
   );
};

export const CheckYourMail = ({ username, onConfirm }) => {
   const { t } = useTranslation('authForm');

   return (
      <div>
         <BoxTitle>
            {t('mailCheck.title')} : {username}
         </BoxTitle>

         <BoxDescription>{t('mailCheck.description')}</BoxDescription>

         <Buttons right>
            <LoadingButton big onClick={onConfirm}>
               {t('finish')}
            </LoadingButton>
         </Buttons>
      </div>
   );
};

export const CheckYourMailBis = ({ username, onConfirm }) => {
   const { t } = useTranslation('authForm');

   return (
      <div>
         <BoxTitle>{t('mailCheckBis.title')} </BoxTitle>

         <BoxDescription>{t('mailCheckBis.description')} :</BoxDescription>

         <p style={{ fontWeight: 700 }}>{username}</p>

         <BoxDescription variant='small'>{t('mailCheckBis.spam')}</BoxDescription>

         <Buttons right>
            <LoadingButton big onClick={onConfirm}>
               {t('finish')}
            </LoadingButton>
         </Buttons>
      </div>
   );
};

export const ActivateForm = ({ onSubmit, error }) => {
   const { t } = useTranslation('authForm');

   return (
      <form onSubmit={onSubmit}>
         <BoxTitle>{t('activation.title')}</BoxTitle>

         <BoxDescription>{t('activation.description')}</BoxDescription>

         <Buttons>
            <div style={{ flex: 1 }}>
               <ConnectedTextField
                  id='signup-referenceNumber'
                  label={t('activation.clientRef')}
                  name='customerReferenceNumber'
                  validate={[isRequired]}
               />
            </div>

            <div style={{ flex: 1 }}>
               <ConnectedTextField
                  id='signup-zipCode'
                  label={t('activation.zipCode')}
                  name='zipCode'
                  validate={[isRequired]}
               />
            </div>
         </Buttons>

         {Boolean(error) && <p>{error}</p>}

         <Buttons right marged>
            <LoadingButton big isPrimary>
               {t('validate')}
            </LoadingButton>
         </Buttons>
      </form>
   );
};

export const LostPasswordForm = ({ onSubmit }) => {
   const { t } = useTranslation('authForm');
   const [recaptchaToken, setRecaptchaToken] = useState();
   const [email, setEmail] = useState();

  useEffect(() => {
    const initialEmail = document.getElementById('signup-username').value;
    setEmail(initialEmail);
  }, []);

   return (
      <form onSubmit={e => onSubmit(e, recaptchaToken)}>
         <BoxTitle>{t('forgotPassword.title')}</BoxTitle>

         <BoxDescription>{t('forgotPassword.description')}</BoxDescription>

         <ConnectedTextField
            id='signup-username'
            label='Email'
            type='email'
            name='username'
            validate={[isEmail, isRequired]}
            defaultValue={email}
            onChange={e => setEmail(e.target.value)}
         />
         <ReCAPTCHA sitekey={GOOGLE_RECAPTCHA_KEY} onChange={value => setRecaptchaToken(value)} />

         <Buttons right>
            <Button variant='primary' disabled={!recaptchaToken || !isValidEmail(email)}>
               {t('send')}
            </Button>
         </Buttons>
      </form>
   );
};

export const AcceptInvitationForm = props => <PasswordForm i18nKey='passwordActivation' {...props} />;
export const ResetPasswordForm = props => <PasswordForm i18nKey='resetPassword' {...props} />;

const PasswordForm = ({ onSubmit, invalid, isLoading, i18nKey }) => {
   const { t } = useTranslation('authForm');
   const form = useSelector(state => state.form.auth);
   const [submitCount, setSubmitCount] = useState(0);

   return (
      <form
         onSubmit={e => {
            setSubmitCount(submitCount + 1);
            onSubmit(e);
         }}
      >
         <BoxTitle>{t(`${i18nKey}.title`)}</BoxTitle>

         <BoxDescription>{t(`${i18nKey}.description`)}</BoxDescription>

         <ConnectedPassword
            id={kebabCase(i18nKey)}
            label={t(`${i18nKey}.label`)}
            name='password'
            password={form?.values?.password}
            isDirty={submitCount > 0}
            validation
         />

         <Buttons right style={{ marginTop: 16 }}>
            <Button variant='primary' isLoading={isLoading} disabled={invalid}>
               {t('save')}
            </Button>
         </Buttons>
      </form>
   );
};

export const CheckProfileForm = reduxForm({ form: 'checkProfile' })(
   connect(null, dispatch => ({ skipCheck: () => dispatch(skipCheck()) }))(({ handleSubmit, submitting, ...props }) => {
      const { t } = useTranslation('authForm');

      return (
         <form onSubmit={handleSubmit}>
            <BoxTitle>{t('checkProfile.title')}</BoxTitle>

            <BoxDescription>{t('checkProfile.description')}</BoxDescription>

            <ConnectedTextField
               id='signup-phone'
               label={t('checkProfile.phone')}
               name='phoneNumber'
               validate={[isRequired]}
               normalize={normalizePhone}
            />

            <Buttons>
               <LoadingButton big isPrimary isLoading={submitting} disabled={submitting}>
                  {t('send')}
               </LoadingButton>

               <ButtonNew big type='button' onClick={() => props.skipCheck()}>
                  {t('checkProfile.pass')}
               </ButtonNew>
            </Buttons>
         </form>
      );
   })
);

const getOfferUrl = offer => {
   switch (offer || SERGIC_OFFER) {
      case 'VSY':
         return VSY_URL;
      case 'ESE':
      default:
         return ESE_URL;
   }
};

export const RedirectionWarning = ({ allowedOffer }) => {
   const { t } = useTranslation('authForm');

   return (
      <div>
         <p>{t('redirection.title')}</p>

         <p>
            {t('redirection.desc1')} {getAppName(allowedOffer)}, {t('redirection.desc2')}{' '}
            <DefaultLink as='a' href={getOfferUrl(allowedOffer)}>
               {t('redirection.desc3')}.
            </DefaultLink>
         </p>
      </div>
   );
};
