import get from 'lodash/get';

const initialState = {
  supportTicketsIds: [],
  supportTicketById: {},
  supportTicketCommentById: {},
  supportTicketCommentsIdsBySupportTicketId: {},
};

const support = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'SUPPORT_TICKETS_SUCCESS':
      return {
        ...state,
        supportTicketsIds: get(payload, 'result'),
        supportTicketById: get(payload, 'entities.zendeskTickets') || {},
      };
    case 'SUPPORT_TICKET_COMMENTS_SUCCESS':
      return {
        ...state,
        supportTicketCommentById: {
          ...state.supportTicketCommentById,
          ...(get(payload, 'data.entities.zendeskTicketComments') || {}),
        },
        supportTicketCommentsIdsBySupportTicketId: {
          ...state.supportTicketCommentsIdsBySupportTicketId,
          [payload.ticketId]: get(payload, 'data.result') || [],
        },
      };
    case 'NEW_SUPPORT_TICKET_COMMENT_SUCCESS':
      return {
        ...state,
        supportTicketCommentById: {
          ...state.supportTicketCommentById,
          [payload.data.id]: payload.data,
        },
        supportTicketCommentsIdsBySupportTicketId: {
          ...state.supportTicketCommentsIdsBySupportTicketId,
          [payload.ticketId]: [
            ...(state.supportTicketCommentsIdsBySupportTicketId[
              payload.ticketId
            ] || []),
            payload.data.id,
          ],
        },
      };
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
};

export default support;
