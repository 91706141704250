import styled, { keyframes, createGlobalStyle } from 'styled-components';
import { Box } from '@tymate/margaret';

const flow = keyframes`
  0%, 80%, 100% {
    transform: scale(0.3); }
  40% {
    transform: scale(1); 
  }
`;

const Flow = styled(Box)`
  --sk-size: ${({ theme }) => theme.spacing(1.2)};
  width: calc(var(--sk-size) * 1.3);
  height: calc(var(--sk-size) * 1.3);
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ size }) =>
    Boolean(size) &&
    css`
      --sk-size: ${({ theme }) => theme.spacing(size)};
    `}

  .sk-flow-dot {
    width: 25%;
    height: 25%;
    background-color: ${({ theme }) => theme.textLight};
    border-radius: 50%;
    animation: ${flow} 1.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s infinite
      both;
  }

  .sk-flow-dot:nth-child(1) {
    animation-delay: -0.3s;
  }
  .sk-flow-dot:nth-child(2) {
    animation-delay: -0.15s;
  }
`;

Flow.defaultProps = {
  marginHorizontal: 0.5,
};

const InputSpinner = props => (
  <Flow {...props}>
    {[0, 1, 2].map(index => (
      <div className="sk-flow-dot" key={index} />
    ))}
  </Flow>
);

export default InputSpinner;
