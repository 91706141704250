import { CLIENT_ID, SERGIC_OFFER } from '../constants';
import { get, post, put, rawPost } from './api';
import { identity, termsVersion } from './schema';
import * as Api from './v2';

const getScopeFromOffer = () => {
   switch (SERGIC_OFFER) {
      case 'ESE':
         return 'eseis';
      case 'VSY':
         return 'viva';
      case 'DIR':
         return 'syndicone';
      case 'ECO':
         return 'ecopro';
      case 'NEUF':
         return 'sergicneuf';
      case 'VQC':
         return 'vivaqc';
      default:
         return null;
   }
};

export const login = payload =>
   post(`/v1/oauth/token`, {
      ...payload,
      client_id: CLIENT_ID,
      grant_type: 'password',
      scope: getScopeFromOffer(SERGIC_OFFER),
   });

export const refreshToken = token =>
   post(`/v1/oauth/token`, {
      refresh_token: token,
      client_id: CLIENT_ID,
      grant_type: 'refresh_token',
   });

export const rawRefreshToken = token =>
   rawPost(`/v1/oauth/token`, {
      refresh_token: token,
      client_id: CLIENT_ID,
      grant_type: 'refresh_token',
   });

export const signup = username =>
   post('/v1/users/sign_up', {
      user: { email: username, sergic_offer: SERGIC_OFFER },
      client_id: CLIENT_ID,
   });

export const register = payload =>
   post(`/v1/users/registration`, {
      user: payload,
      client_id: CLIENT_ID,
   });

export const sendForgottenPasswordEmail = (username, recaptchaToken) =>
   post('/v1/users/password', {
      user: { email: username, sergic_offer: SERGIC_OFFER },
      recaptcha_token: recaptchaToken,
      client_id: CLIENT_ID,
   });

export const getMe = () => get(`/v1/users/me`);
export const putMe = payload => put(`/v1/users/me`, payload);

export const resetPassword = ({ email, password, resetToken }) =>
   post(`/v1/users/reset`, {
      user: {
         email: decodeURIComponent(email),
         password,
         sergic_offer: SERGIC_OFFER,
      },
      reset_password_token: resetToken,
      client_id: CLIENT_ID,
   });

export const acceptInvite = ({ email, password, invitationToken }) =>
   post(`/v1/users/set_password`, {
      user: { email: decodeURIComponent(email), password },
      invitation_token: invitationToken,
      client_id: CLIENT_ID,
   });

export const getMePrivacySettings = () => get(`/v1/users/me/privacy_settings`);
export const putMePrivacySettings = payload => put(`/v1/users/me/privacy_settings`, payload);

export const getTerms = () => get(`/terms?by_sergic_offer=${SERGIC_OFFER}`, [termsVersion]);
export const updatePassword = payload => put(`/v1/users/me/passwords`, payload);

export const impersonateUser = userId =>
   post(`/v1/admin/token`, {
      clientId: CLIENT_ID,
      userId,
   });

export const getZendeskToken = payload => post(`/v1/zendesk/jwt`, payload);

export const getUserIdentities = placeId => get(`/v1/users/me/identities?by_place=${placeId}`, [identity]);

export const updateIdentity = (id, payload) => put(`/v1/users/me/identities/${id}`, payload, identity);

export const checkEmail = ({ username }) =>
   post(`/v1/users/mail_check`, {
      user: { email: username },
      client_id: CLIENT_ID,
   });

export const getMobileVersions = () =>
   Api.get(`/v1/mobile_versions?by_device=web&by_sergic_offer=${SERGIC_OFFER}&sort=-updated_at`);

export const markVersionAsRead = id => post(`/v1/mobile_versions/${id}/mark_as_read`);

export const checkPlaceMoneyOrder = id => get(`/v1/places/${id}/money_order_check`);
