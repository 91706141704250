import styled, { css } from 'styled-components';
import { NavLink as RawNavLink } from 'react-router-dom';
import { Box, media, Stack } from '@tymate/margaret';
import { includes, keys } from 'lodash';

export { Box, Stack };

export const Legend = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.textLight};
`;

export const Name = styled.span`
  font-weight: 500;
`;

export const gutterSize = ({ theme, gutterSize }) => {
  switch (gutterSize) {
    case 'none':
      return 0;
    case 'tiny':
      return theme.spacing(0.25);
    case 'small':
      return theme.spacing(0.5);
    case 'large':
      return theme.spacing(2);
    case 'default':
    case 'medium':
    case 'normal':
    default:
      return theme.spacing();
  }
};

export const spacings = props => css`
  ${({ margin }) =>
    (Boolean(margin) || margin === 0) &&
    css`
      margin: ${({ theme }) => theme.spacing(margin)};
    `}

  ${({ marginVertical }) =>
    (Boolean(marginVertical) || marginVertical === 0) &&
    css`
      margin-top: ${({ theme }) => theme.spacing(marginVertical)};
      margin-bottom: ${({ theme }) => theme.spacing(marginVertical)};
    `}

  ${({ marginHorizontal }) =>
    (Boolean(marginHorizontal) || marginHorizontal === 0) &&
    css`
      margin-left: ${({ theme }) => theme.spacing(marginHorizontal)};
      margin-right: ${({ theme }) => theme.spacing(marginHorizontal)};
    `}

  ${({ marginTop }) =>
    (Boolean(marginTop) || marginTop === 0) &&
    css`
      margin-top: ${({ theme }) => theme.spacing(marginTop)};
    `}

  ${({ marginBottom }) =>
    (Boolean(marginBottom) || marginBottom === 0) &&
    css`
      margin-bottom: ${({ theme }) => theme.spacing(marginBottom)};
    `}

  ${({ marginLeft }) =>
    (Boolean(marginLeft) || marginLeft === 0) &&
    css`
      margin-left: ${({ theme }) => theme.spacing(marginLeft)};
    `}

  ${({ marginRight }) =>
    (Boolean(marginRight) || marginRight === 0) &&
    css`
      margin-right: ${({ theme }) => theme.spacing(marginRight)};
    `}

  ${({ padding }) =>
    (Boolean(padding) || padding === 0) &&
    css`
      padding: ${({ theme }) => theme.spacing(padding)};
    `}

  ${({ paddingVertical }) =>
    (Boolean(paddingVertical) || paddingVertical === 0) &&
    css`
      padding-top: ${({ theme }) => theme.spacing(paddingVertical)};
      padding-bottom: ${({ theme }) => theme.spacing(paddingVertical)};
    `}

  ${({ paddingHorizontal }) =>
    (Boolean(paddingHorizontal) || paddingHorizontal === 0) &&
    css`
      padding-left: ${({ theme }) => theme.spacing(paddingHorizontal)};
      padding-right: ${({ theme }) => theme.spacing(paddingHorizontal)};
    `}

  ${({ paddingTop }) =>
    (Boolean(paddingTop) || paddingTop === 0) &&
    css`
      padding-top: ${({ theme }) => theme.spacing(paddingTop)};
    `}

  ${({ paddingBottom }) =>
    (Boolean(paddingBottom) || paddingBottom === 0) &&
    css`
      padding-bottom: ${({ theme }) => theme.spacing(paddingBottom)};
    `}

  ${({ paddingLeft }) =>
    (Boolean(paddingLeft) || paddingLeft === 0) &&
    css`
      padding-left: ${({ theme }) => theme.spacing(paddingLeft)};
    `}

  ${({ paddingRight }) =>
    (Boolean(paddingRight) || paddingRight === 0) &&
    css`
      padding-right: ${({ theme }) => theme.spacing(paddingRight)};
    `}
`;

export const List = styled(Stack)`
  list-style-type: none;

  > * {
    flex: auto;
  }
`;

List.defaultProps = {
  as: 'ul',
  margin: 0,
  padding: 0,
};

export const InlineList = styled(List)`
  flex-wrap: wrap;
`;

InlineList.defaultProps = {
  direction: 'row',
  marginTop: 0,
  marginBottom: 0,
};

export const PageTitle = styled(Box)`
  font-size: ${({ theme }) => theme.size(4)};
  line-height: 1.1;
`;

PageTitle.defaultProps = {
  as: 'h1',
};

export const Title = styled.h2`
  font-weight: 600;
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spacing()};
  font-size: ${({ theme }) => theme.size(2)};

  ${props =>
    props.isBig &&
    css`
      font-size: ${({ theme }) => theme.size(3)};
      margin-top: ${({ theme }) => theme.spacing()};
    `};

  ${props =>
    props.isMargedTop &&
    css`
      margin-top: ${({ theme }) => theme.spacing(2)};
    `};

  ${props =>
    props.hasSmallTopMargin &&
    css`
      margin-top: ${({ theme }) => theme.spacing()};
    `};

  ${props =>
    props.hasBorderBottom &&
    css`
      padding-bottom: ${({ theme }) => theme.spacing()};
      margin-bottom: ${({ theme }) => theme.spacing()};
      border-bottom: 1px solid ${({ theme }) => theme.separator};
    `};

  ${({ size }) =>
    size === 'medium' &&
    css`
      font-size: ${({ theme }) => theme.size(1)};
      margin-bottom: ${({ theme }) => theme.spacing(0.5)};
    `}
`;

export const Subtitle = styled(Stack)`
  font-size: 1.189em;
  font-weight: 600;
  line-height: 1.2;

  ${({ variant }) => variant === 'small' && `font-size:1em;`}
`;

Subtitle.defaultProps = {
  marginTop: 2,
  marginBottom: 0.5,
};

export const NavLink = styled(RawNavLink)`
  text-decoration: none;
`;

export const Container = styled(Box)`
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  ${({ size }) =>
    size === 'medium' &&
    css`
      max-width: 1000px;
    `}

  ${({ size }) =>
    size === 'narrow' &&
    css`
      max-width: 832px;
    `};

  ${({ size }) =>
    size === 'tight' &&
    css`
      max-width: 540px;
    `};

  ${({ size }) =>
    size === 'full' &&
    css`
      width: 100%;
      max-width: none;
    `};

  ${({ alignX }) =>
    alignX === 'flex-start' &&
    css`
      margin-right: auto;
      margin-left: 0;
    `}

  ${({ alignX }) =>
    alignX === 'center' &&
    `
      margin-right: auto;
      margin-left: auto;
    `}

  ${({ variant, theme }) =>
    variant === 'main' &&
    css`
      padding-left: ${theme.spacing()} !important;
      padding-right: ${theme.spacing()} !important;

      ${media.tablet`
        padding-left: ${theme.spacing(2)} !important;
        padding-right: ${theme.spacing(2)} !important;
      `}
    `}
`;

Container.defaultProps = {
  alignX: 'flex-start',
  paddingHorizontal: 1,
};

export const PageHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 10;
`;

export const TopBar = styled(Stack)`
  background-color: #fff;
  box-shadow: 0 2px 4px ${({ theme }) => theme.separator};

  ${({ variant }) =>
    includes(['main', 'mainFull'], variant) &&
    css`
      height: ${({ theme }) => theme.mainHeaderHeight};
      position: relative;
      display: none;
      right: 0;
      left: 0;
      top: 0;
      z-index: 5;

      ${media.tablet`
        display: flex;
        position: fixed;
        top: 0;
        left: ${({ theme }) => theme.mainSidebarWidth};
      `};

      ${({ variant }) =>
        variant === 'mainFull' &&
        media.tablet`
        left: 0;
      `}
    `}
`;

TopBar.defaultProps = {
  as: 'header',
  alignY: 'center',
};

export const SectionTitle = ({ icon, children, variant, props }) => (
  <Subtitle
    alignY="flex-start"
    marginTop={0}
    {...props}
    gutterSize={0.5}
    variant={variant}
  >
    {Boolean(icon) && <Stack marginTop={0.125}>{icon}</Stack>}
    <span>{children}</span>
  </Subtitle>
);

export const Grid = styled(Box)`
  display: flex;
  flex-wrap: wrap;

  ${({ gutterSize, theme }) =>
    Boolean(gutterSize) &&
    css`
      margin-top: ${theme.spacing(-1 * gutterSize)};
      margin-left: ${theme.spacing(-1 * gutterSize)};
      width: calc(100% + ${theme.spacing(gutterSize)});

      > * {
        padding-top: ${theme.spacing(gutterSize)};
        padding-left: ${theme.spacing(gutterSize)};
      }
    `};
`;

export const Cell = styled.div`
  flex: 1;
  min-width: 0;

  ${props =>
    props.sizes &&
    css`
      flex: 0 0 ${Math.floor(props.sizes.default * 10000) / 100}%;

      ${keys(props.sizes)
        .filter(key => key !== 'default')
        .map(
          breakpoint => css`
            ${media[breakpoint]`flex: 0 0 ${Math.floor(
              props.sizes[breakpoint] * 10000,
            ) / 100}%`};
          `,
        )};
    `};

  ${props =>
    props.min &&
    css`
      flex: 0;
      white-space: nowrap;
    `};

  ${props =>
    props.hiddenUntilTablet &&
    css`
      display: none;

      ${media.tablet`
        display: block;
      `}
    `}
`;
