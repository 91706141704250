import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { SERGIC_OFFER } from './constants';
import createRootReducer from './reducers';
import { loadState } from './api/localStorage';
import { merge } from 'utils';
import { initialState as initialUiState } from 'reducers/ui';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

const persistedState = loadState();
export const history = createBrowserHistory();

const store = createStore(
  createRootReducer(history),
  merge(persistedState, { ui: { ...initialUiState, offer: SERGIC_OFFER } }),
  compose(
    applyMiddleware(routerMiddleware(history), thunkMiddleware),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : f => f,
  ),
);

export default store;
