import { useState, useEffect } from 'react';
import { Document as RawDocument, Page as RawPage } from 'react-pdf';
import Button from './Button';
import { getAttachmentURL } from '../utils';
import styled from 'styled-components';
import EmptyState from './EmptyState';
import Dropzone from './Dropzone';
import { Buttons } from '@tymate/margaret';
import {
  MdFileDownload,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';

const Document = styled(RawDocument)`
  position: relative;
`;

const Navigation = styled(Buttons)`
  position: absolute;
  top: 0;
  right: 0;

  svg {
    margin-right: 0;
  }
`;

const Page = styled(RawPage)`
  > canvas {
    height: auto !important;
    width: 100% !important;
  }
`;

const Viewer = ({ attachment, onAdd, readOnly }) => {
  const { fileUrl } = attachment || {};
  const [page, setPage] = useState(1);
  const [pagesCount, setPagescount] = useState(1);

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setPagescount(numPages);
  };

  const handleGoToPreviousPage = () => {
    setPage(Math.max(page - 1, 1));
  };

  const handleGoToNextPage = () => {
    setPage(Math.min(page + 1, pagesCount));
  };

  const handlePageLoadSuccess = ({ originalWidth, originalHeight }) => {};

  useEffect(() => {
    setPage(1);
    setPagescount(1);
  }, [fileUrl]);

  if (!Boolean(fileUrl) && !readOnly) {
    return (
      <div>
        <Dropzone onAdd={onAdd} shouldUpload={false} shouldReturnBase64 />
      </div>
    );
  }


  if (
    attachment?.displayName?.split('.')[1] == 'JPG' ||
    attachment?.displayName?.split('.')[1] == 'PNG'
  ) {
    return (
      <img src={getAttachmentUrl(`${fileUrl}`)} alt={attachment.displayName} />
    );
  }


  return (
    <>
      <Document
        file={getAttachmentURL(fileUrl)}
        onLoadSuccess={handleDocumentLoadSuccess}
        error={
          <EmptyState icon="brokenDocument" variant="bare">
            {Boolean(fileUrl)
              ? 'Erreur lors du chargement du document.'
              : 'Aucun document à afficher.'}
          </EmptyState>
        }
      >
        <Page
          pageNumber={page}
          onLoadSuccess={handlePageLoadSuccess}
          error={
            <EmptyState icon="brokenDocument" variant="bare">
              Erreur lors du chargement de la page.
            </EmptyState>
          }
        />

        <Navigation gutterSize={0.5} spacingSize="small">
          <Button as="a" href={fileUrl} target="_blank">
            <MdFileDownload />
          </Button>

          {pagesCount > 1 && (
            <>
              <Button onClick={handleGoToPreviousPage} disabled={page < 2}>
                <MdKeyboardArrowLeft />
              </Button>

              <Button
                onClick={handleGoToNextPage}
                disabled={page >= pagesCount}
              >
                <MdKeyboardArrowRight />
              </Button>
            </>
          )}
        </Navigation>
      </Document>
    </>
  );
};

export default Viewer;
