import styled from 'styled-components';
import { Stack } from '@tymate/margaret';
import { FiCheckCircle } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import {
   hasOneDigit,
   hasOneLowerLetter,
   hasOneSpecialCharacter,
   hasOneUpperLetter,
   isLongerThanTwelve,
} from '../utils/validation';
import PasswordStrength from './PasswordStrength';

const Conditions = styled(Stack).attrs({ gutterSize: 0.5, marginTop: 1 })``;

const ConditionWrapper = styled(Stack)`
   ${({ isChecked, theme }) =>
      isChecked &&
      `
      color: ${theme.success}
    `}

   ${({ isChecked, isDirty, theme }) =>
      !isChecked &&
      isDirty &&
      `
      color: ${theme.error}
    `}
`;

const Condition = ({ label, isChecked, isDirty, ...props }) => (
   <ConditionWrapper isChecked={isChecked} isDirty={isDirty} alignY='center' gutterSize={0.25} {...props}>
      <Stack>
         <FiCheckCircle />
      </Stack>
      <div>{label}</div>
   </ConditionWrapper>
);

const PasswordConditions = ({ password, isDirty }) => {
   const { t } = useTranslation('authForm');

   return (
      <Stack alignX='space-between'>
         <Conditions direction='column' alignY='center'>
            <Condition label={t('password.upper_case')} isChecked={hasOneUpperLetter(password)} isDirty={isDirty} />
            <Condition label={t('password.lower_case')} isChecked={hasOneLowerLetter(password)} isDirty={isDirty} />
            <Condition label={t('password.figure')} isChecked={hasOneDigit(password)} isDirty={isDirty} />
            <Condition
               label={t('password.special_character')}
               isChecked={hasOneSpecialCharacter(password)}
               isDirty={isDirty}
            />
            <Condition
               label={t('password.length_condition')}
               isChecked={isLongerThanTwelve(password)}
               isDirty={isDirty}
            />
         </Conditions>
         <PasswordStrength password={password} />
      </Stack>
   );
};
export default PasswordConditions;
