import { decamelizeKeys } from 'humps';
import { API_BASE, MOCK_API_BASE } from '../constants';
import store from 'store';
import { normalize } from 'utils';
import { sendSnack } from 'actions/snacks';
import { pickBy, isEqual } from 'lodash';
import { history } from 'store';
import { rawRefreshToken } from './user';

const refreshFetch = (uri, options, hasTriedRefresh) => {
  return fetch(uri, options).then(async response => {
    if (response.status === 401) {
      const refreshTokenValue = JSON.parse(localStorage.getItem('state'))?.user
        ?.refreshToken;

      if (!Boolean(refreshTokenValue) || hasTriedRefresh) {
        store.dispatch({ type: 'LOGOUT' });
        return response;
      }

      try {
        const response = await rawRefreshToken(refreshTokenValue);

        if (response.status === 401) {
          store.dispatch({ type: 'LOGOUT' });
          return;
        }
        const payload = await response.json();
        store.dispatch({ type: 'REFRESH_TOKEN_SUCCESS', payload });
      } catch (e) {
        store.dispatch({ type: 'LOGOUT' });
      }

      const opts = {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem('state')).user.accessToken
          }`,
        },
      };

      return refreshFetch(uri, opts, true).then(response => response);
    }

    return response;
  });
};

const getPaginationMeta = response => {
  const perPage = Number(response.headers.get('per-page'));
  const total = Number(response.headers.get('total'));
  const page = Number(response.headers.get('page'));
  const nextPage = Math.ceil(total / perPage) > page ? page + 1 : null;

  if (!perPage && !total && !page) {
    return null;
  }

  return {
    nextPage,
    page,
    perPage,
    total,
  };
};

const returnResponse = async response => {
  if (response.status === 204 || response.status === 201) {
    return { json: {}, response };
  }

  if (response.status === 503) {
    history.push('/maintenance');
    return Promise.reject({
      status: response.status,
      error: response.statusText,
    });
  }

  if (response.status === 401) {
    store.dispatch({ type: 'LOGOUT' });
    return response.json().then(error => Promise.reject(error));
  } else if (response.status === 403) {
    store.dispatch(
      sendSnack({
        type: 'error',
        duration: 3000,
        message: 'Vous n’êtes pas autorisé à faire cette action.',
        action: 'OK',
      }),
    );

    try {
      const { error } = await response.json();
      return Promise.reject({
        status: response.status,
        error: response.statusText,
        message: error.message,
      });
    } catch (err) {
      return Promise.reject({
        status: response.status,
        error: response.statusText,
      });
    }
  }

  const json = await response.json();
  return { json, response };
};

const handleResponse = ({ json, response, schema, params }) => {
  if (!response.ok) {
    return Promise.reject({
      status: response.status,
      error: response.statusText,
      ...json,
    });
  }

  if (response.status === 204) {
    return [];
  }

  const pagination = pickBy({
    ...getPaginationMeta(response),
    params,
  });

  if (!schema) {
    return Promise.resolve(
      pickBy({ ...json, pagination }, v => !isEqual(v, {})),
    );
  }

  return Promise.resolve(
    pickBy(
      {
        ...normalize(json, schema),
        pagination,
      },
      v => v => !isEqual(v, {}),
    ),
  );
};

const headers = input => {
  const state = JSON.parse(localStorage.getItem('state'));
  const accessToken =
    state?.user?.impersonatedUser?.accessToken || state?.user?.accessToken;
  const selectedPlaceId = state?.ui?.selectedPlaceId;

  return {
    ...input,
    headers: pickBy({
      ...input.headers,
      Authorization: Boolean(accessToken) ? `Bearer ${accessToken}` : null,
      'X-Current-Place-Id': Boolean(selectedPlaceId) ? selectedPlaceId : null,
    }),
  };
};

export const get = (uri, schema, params, mock = false) =>
  refreshFetch(
    `${mock ? MOCK_API_BASE : API_BASE}${uri}`,
    headers({
      method: 'GET',
    }),
  )
    .then(returnResponse)
    .then(response => handleResponse({ ...response, schema, params }));

export const rawPost = (uri, payload = {}, mock = false) =>
  fetch(
    `${mock ? MOCK_API_BASE : API_BASE}${uri}`,
    headers({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify(decamelizeKeys(payload)),
    }),
  );

export const post = (uri, payload = {}, schema, mock = false) =>
  refreshFetch(
    `${mock ? MOCK_API_BASE : API_BASE}${uri}`,
    headers({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify(decamelizeKeys(payload)),
    }),
  )
    .then(returnResponse)
    .then(response => handleResponse({ ...response, schema }));

export const put = (uri, payload = {}, schema, mock = false) =>
  refreshFetch(
    `${mock ? MOCK_API_BASE : API_BASE}${uri}`,
    headers({
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify(decamelizeKeys(payload)),
    }),
  )
    .then(returnResponse)
    .then(response => handleResponse({ ...response, schema }));

export const destroy = (uri, mock = false) =>
  refreshFetch(
    `${mock ? MOCK_API_BASE : API_BASE}${uri}`,
    headers({
      method: 'delete',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    }),
  )
    .then(returnResponse)
    .then(handleResponse);
