import styled, { css } from 'styled-components';
import { MdCheckBoxOutlineBlank, MdCheckBox } from 'react-icons/md';
import { Stack } from '@tymate/margaret';

const CheckboxContainer = styled.span`
  display: flex;
  align-items: center;
  position: relative;
  padding-right: ${({ theme }) => theme.spacing(0.25)};
  height: 24px;

  ${({ checked }) =>
    checked &&
    css`
      color: ${({ theme }) => theme.primary};
    `}

  &:before {
    position: absolute;
    top: 8px;
    left: 4px;
    right: 8px;
    bottom: 4px;
    z-index: -1;
    display: block;
    content: '';
  }

  &:hover:before {
    transition: background-color 100ms ease;
    background-color: #f4f2ef;
  }

  ${({ variant }) =>
    variant === 'boxed' &&
    css`
      padding-right: ${({ theme }) => theme.spacing()};
    `}
`;

const CheckboxLabel = styled.label`
  cursor: pointer;
  display: flex;
  user-select: none;
  color: currentColor;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(0.5)};

  [type='checkbox'] {
    display: none;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.textLighter};
    `};

  ${({ variant }) =>
    variant === 'boxed' &&
    css`
      margin-top: 0;
    `}
`;

const CheckboxContent = styled.div`
  flex: 1;
`;

const Checkbox = ({
  checked,
  label,
  suffix,
  disabled,
  onChange,
  variant,
  children,
}) => (
  <CheckboxLabel
    disabled={disabled}
    checked={Boolean(checked)}
    variant={variant}
  >
    <input
      type="checkbox"
      checked={Boolean(checked)}
      disabled={disabled}
      onChange={onChange}
    />

    <CheckboxContainer checked={Boolean(checked)} variant={variant}>
      {checked && <MdCheckBox size={20} />}
      {!checked && <MdCheckBoxOutlineBlank size={20} />}
    </CheckboxContainer>

    <Stack direction="column" size="full" gutterSize={0.5}>
      <CheckboxContent variant={variant}>
        <span>{label}</span>
        {suffix}
      </CheckboxContent>
      {children}
    </Stack>
  </CheckboxLabel>
);

export default Checkbox;
