import { useRef } from 'react';
import styled from 'styled-components';
import { languages, changeLocale, getLocale } from 'utils';
import Dropdown from './Dropdown';
import { PopoverItem, PopoverMenu, PopoverItemButton } from 'ui/popover';
import { MdArrowDropDown } from 'react-icons/md';
import { spacing } from 'ui';
import { useEffect } from 'react';
import { SERGIC_OFFER } from '../constants';
import 'react-flag-icon-css';

const CountryFlag = styled.span`
  font-size: 18px;
  cursor: pointer;
`;

const DropdownIconWrapper = styled.div`
  display: flex;
  padding: 6px ${spacing(0.5)};
  height: 44px;
  align-items: center;
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.separator};
  border-radius: 4px;

  &:hover {
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.separator},
      0 2px 4px rgba(0, 0, 0, 0.12);
  }
`;

const getLocaleFlag = locale => {
  switch (locale.label) {
    case 'en-CA':
      return 'us';
    case 'fr-FR':
    case 'fr-CA':
    default:
      return 'fr';
  }
};

export const LanguageSelector = ({ top }) => {
  const dropdownRef = useRef();
  const locale = getLocale();

  useEffect(() => {
    if (!dropdownRef.current) {
      return;
    }

    dropdownRef.current.close();
  }, [locale]);

  if (SERGIC_OFFER !== 'VQC') {
    return null;
  }

  return (
    <Dropdown
      ref={dropdownRef}
      top={top}
      bare
      trigger={
        <DropdownIconWrapper>
          <CountryFlag
            className={`flag-icon flag-icon-${getLocaleFlag(locale)}`}
          />
          <MdArrowDropDown size={24} />
        </DropdownIconWrapper>
      }
    >
      <PopoverMenu>
        {languages
          .filter(({ name }) => name !== 'fr-FR')
          .map(lang => (
            <PopoverItem key={lang.name}>
              <PopoverItemButton onClick={() => changeLocale(lang.name)}>
                <CountryFlag
                  className={`flag-icon flag-icon-${getLocaleFlag(lang)}`}
                />
              </PopoverItemButton>
            </PopoverItem>
          ))}
      </PopoverMenu>
    </Dropdown>
  );
};
