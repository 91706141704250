import styled, { css } from 'styled-components';
import { InlineList } from '../ui';
import { ButtonReset, Stack } from '@tymate/margaret';

const Wrapper = styled.div`
  max-width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: ${({ theme }) => theme.spacing(1)};
`;

const StepsList = styled(InlineList)`
  flex-wrap: nowrap;
`;

const Step = styled.li`
  text-align: center;
`;

const StepNumber = styled.div`
  display: inline-block;
  font-weight: 600;
  line-height: 2em;
  width: 2em;
  border-radius: 100%;
  background-color: #fff;
  color: ${({ theme }) => theme.primary};
  text-align: center;
  box-shadow: inset 0 0 0 2px ${({ theme }) => theme.primary};

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${({ theme }) => theme.primary};
      color: #fff;
    `};
`;

const StepLabel = styled.div`
  color: ${({ theme }) => theme.primary};
  font-weight: 600;
`;

const StepLink = styled(Stack)``;

const getStepClickCallback = ({
  step,
  activeStep,
  canSelectFormerSteps,
  canSelectLaterSteps,
  onSelectStep,
}) => {
  if (!Boolean(onSelectStep)) {
    return null;
  }

  if (step === activeStep) {
    return null;
  }

  if (step > activeStep && !canSelectLaterSteps) {
    return null;
  }

  if (step < activeStep && !canSelectFormerSteps) {
    return null;
  }

  return () => onSelectStep(step);
};

const Steps = ({
  steps,
  activeStep,
  onSelectStep,
  isStepActive,
  canSelectLaterSteps,
  canSelectFormerSteps,
  alignX,
}) => (
  <Wrapper>
    <StepsList gutterSize={2} alignX={alignX}>
      {steps.map(({ step, label }) => {
        const isActive = isStepActive({ step, activeStep });
        const onClick = getStepClickCallback({
          step,
          activeStep,
          onSelectStep,
          isStepActive,
          canSelectLaterSteps,
          canSelectFormerSteps,
        });

        return (
          <Step key={step}>
            <StepLink
              onClick={onClick}
              as={Boolean(onClick) ? ButtonReset : 'div'}
              alignY="center"
              gutterSize={0.5}
            >
              <StepNumber isActive={isActive}>{step}</StepNumber>
              <StepLabel isActive={isActive}>{label}</StepLabel>
            </StepLink>
          </Step>
        );
      })}
    </StepsList>
  </Wrapper>
);

Steps.defaultProps = {
  isStepActive: ({ step, activeStep }) => step <= activeStep,
  canSelectFormerSteps: true,
};

export default Steps;
