import Paginator from 'paginator';
import { createAction } from 'redux-actions';
import get from 'lodash/get';
import keys from 'lodash/keys';
import { getPaginationQueryFromApiQuery } from 'utils/routes';

export const createActionWithPagination = name =>
  createAction(
    name,
    ({ pagination: omit, ...payload }) => payload,
    ({ pagination }) => pagination,
  );

export const createActionWithNestedPagination = name =>
  createAction(
    name,
    ({ data: { pagination: omit, ...payload }, ...rest }) => ({
      ...rest,
      data: { ...payload },
    }),
    ({ data: { pagination } }) => pagination,
  );

export const generatePagination = (
  state,
  id,
  { payload: { data, result }, meta },
) => {
  const URI = getPaginationQueryFromApiQuery(get(meta, 'params'));

  return {
    [URI]: {
      page: get(meta, 'page'),
      nextPage: get(meta, 'nextPage'),
      total: get(meta, 'total'),
      perPage: get(meta, 'perPage'),
      lastPage: Math.ceil(
        Number(get(meta, 'total')) / Number(get(meta, 'perPage')),
      ),
      idsByPage: {
        ...get(state, `${id}.${URI}.idsByPage`),
        [get(meta, 'page')]: result || data.result,
      },
    },
  };
};

export const generatePaginators = (
  uri,
  entity,
  collection = {},
  perPage = 15,
) => {
  let paginators;

  keys(collection).forEach(key => {
    const pagination = collection[key];

    paginators = {
      ...paginators,
      [key]: new Paginator(
        uri.replace('{id}', key),
        entity,
        pagination.page || 1,
        perPage,
      ),
    };
  });

  return paginators;
};

export const getPaginator = (collection = {}, id, uri, entity, perPage) => {
  if (collection[id]) {
    return collection[id];
  }

  const paginator = new Paginator(uri.replace('{id}', id), entity, 1, perPage);
  collection[id] = paginator;

  return paginator;
};
