import { Component } from 'react';
import styled from 'styled-components';
import { camelizeKeys } from 'humps';
import { List } from '../../ui';
import { MdAttachFile } from 'react-icons/md';
import AttachmentNew from './AttachmentNew';
import MDSpinner from 'react-md-spinner';
import { withTranslation } from 'react-i18next';

const Label = styled.label`
  display: block;
  cursor: pointer;

  svg {
    margin-right: ${({ theme }) => theme.spacing(0.25)};
  }
`;

class Attachments extends Component {
  static defaultProps = {
    single: false,
    shouldShowAttachments: true,
  };

  state = { isFetching: false };

  componentDidMount() {
    this._isMounted = true;
  }

  handleAddAttachment = e => {
    e.preventDefault();

    const reader = new FileReader();
    const file = e.target.files[0];

    this.setState({ isFetching: true });
    reader.onloadend = () => {
      this.props
        .post('/v1/attachments', {
          image_base: reader.result,
          source_file_name: file.name,
        })
        .then(
          result => {
            if (this._isMounted) {
              this.setState({ isFetching: false });
              this.props.onAdd(camelizeKeys(result));
            }
          },
          err => console.error(err),
        );
    };

    reader.readAsDataURL(file);
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      attachments,
      onDelete,
      single,
      shouldShowAttachments,
      t,
    } = this.props;
    const { isFetching } = this.state;

    return (
      <div style={{ marginTop: 0, marginBottom: 16 }}>
        {shouldShowAttachments && (
          <List>
            {attachments.filter(Boolean).map(attachment => (
              <li key={attachment.id}>
                <AttachmentNew
                  {...attachment}
                  canBeRemoved
                  onRemove={() => onDelete(attachment.id)}
                />
              </li>
            ))}
          </List>
        )}

        {isFetching && (
          <div>
            <MDSpinner
              style={{ marginRight: 8 }}
              singleColor="currentColor"
              size={16}
            />
            {t('loading')}
          </div>
        )}

        {!isFetching && (!single || attachments.filter(Boolean).length === 0) && (
          <Label>
            <MdAttachFile /> {t('add')}
            <input
              id="profilePicture"
              type="file"
              onChange={this.handleAddAttachment}
              accept="image/*,application/pdf"
              style={{ display: 'none' }}
            />
          </Label>
        )}
      </div>
    );
  }
}

export default withTranslation('attachment')(Attachments);
