import capitalize from 'lodash/capitalize';
import camelCase from 'lodash/camelCase';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import queryString from 'query-string';
import keys from 'lodash/keys';
import omit from 'lodash/omit';
import trimStart from 'lodash/trimStart';
import { camelize, decamelize } from 'humps';

export const getPathSlug = url =>
  url
    .slice(1)
    .split('/')
    .map(camelCase)
    .join('_');

export const getCrumbName = ({ crumb, url, customTranslations = {}, t }) => {
  const pathSlug = getPathSlug(url);
  const customTranslation = customTranslations[pathSlug];
  const customCrumbTranslation = customTranslations[crumb];
  const pathTranslation = pathSlug !== t(pathSlug) && t(pathSlug);
  const crumbTranslation =
    camelCase(crumb) !== t(camelCase(crumb)) && t(camelCase(crumb));

  // If full path has specific translation, use it.
  // If not, return the crumb translation.
  // If it does not exist, return the crumb slug.
  return customTranslation
    ? customTranslation
    : pathTranslation
    ? pathTranslation
    : customCrumbTranslation
    ? customCrumbTranslation
    : crumbTranslation
    ? crumbTranslation
    : capitalize(crumb);
};

export const getURLWithParam = (url = '', search, param = {}) => {
  // pickBy removes null values from params object. It permits to
  // remove a key from URL by setting key: null.
  const params = pickBy(
    {
      ...queryString.parse(search),
      ...param,
    },
    identity,
  );

  const query = queryString.stringify(params);

  if (!Boolean(query)) {
    return url;
  }

  return `${url}?${query}`;
};

const getFilterValue = value => {
  switch (value) {
    case 'sergic':
      return 'sergic_partner';
    case 'residents':
      return 'resident';
    case 'cs':
      return 'coownership_board_member';
    default:
      return value;
  }
};

export const getApiKeyFromPaginationKey = (key, value) => {
  switch (key) {
    case 'unread':
      return 'with_unread_message';
    case 'categoryKind':
    case 'state':
    case 'place':
      return `by_${decamelize(key)}`;
    case 'search':
      return 'filter[search]';
    case 'q':
      return 'filter';
    case 'filter':
      return getFilterValue(value);
    default:
      return decamelize(key);
  }
};

export const getApiValueFromPaginationValue = (key, value) => {
  switch (key) {
    case 'unread':
      return true;
    case 'search':
    case 'q':
      return value;
    case 'filter':
      return true;
    default:
      return decamelize(value);
  }
};

export const getPaginationKeyFromApiKey = key => {
  switch (key) {
    case 'with_unread_message':
      return 'unread';
    case 'filter[search]':
      return 'search';
    default:
      return camelize(trimStart(key, 'by_'));
  }
};

export const getPaginationValueFromApiValue = (key, value) => {
  switch (key) {
    case 'with_unread_message':
      return true;
    case 'filter[search]':
      return (value || '').toLowerCase();
    default:
      return `${value.charAt(0) === '-' ? '-' : ''}${camelize(value)}`;
  }
};

export const getApiQueryFromPaginationQuery = input => {
  const paginationQuery = queryString.parse(input);
  let apiQuery = {};
  keys(paginationQuery).forEach(key => {
    apiQuery = {
      ...apiQuery,
      [getApiKeyFromPaginationKey(
        key,
        paginationQuery[key],
      )]: getApiValueFromPaginationValue(key, paginationQuery[key]),
    };
  });

  return queryString.stringify(apiQuery);
};

export const getPaginationQueryFromApiQuery = input => {
  const apiQuery = omit(input, ['per_page', 'page']);

  let paginationQuery = {};
  keys(apiQuery).forEach(key => {
    paginationQuery = {
      ...paginationQuery,
      [getPaginationKeyFromApiKey(key)]: getPaginationValueFromApiValue(
        key,
        apiQuery[key],
      ),
    };
  });

  return `?${queryString.stringify(paginationQuery)}`;
};

export const getPageFromQuery = query => queryString.parse(query).page || 1;

export const getQueryWithoutPage = input => {
  const { page: omit, ...query } = queryString.parse(input);

  return `?${queryString.stringify(query)}`;
};
