import { createAction } from 'redux-actions';
import { getPlace } from './places';

export const setTitle = createAction('UPDATE_TITLE');

const syncSelectPlace = createAction('SELECT_PLACE');

export const selectPlace = id => dispatch => {
  dispatch(syncSelectPlace(id));
  dispatch(getPlace(id));
};

export const selectContactsFilters = createAction('SELECT_CONTACTS_FILTERS');
export const updateContactSearch = createAction('UPDATE_CONTACTS_SEARCH');

export const selectPlaceFiscalYear = createAction('SELECT_PLACE_FISCAL_YEAR');
export const updateTopicsSorting = createAction('UPDATE_TOPICS_SORTING');
export const updateTopicPostsSorting = createAction(
  'UPDATE_TOPICS_POSTS_SORTING',
);
export const updateReportsSorting = createAction('UPDATE_REPORTS_SORTING');
export const toggleOnlyShowWorkOrders = createAction(
  'TOGGLE_ONLY_SHOW_WORK_ORDERS',
);

export const createNewConversationPlaceholder = createAction(
  'CREATE_NEW_CONVERSATION_PLACEHOLDER',
);
export const updateNewConversationPlaceholderMembers = createAction(
  'UPDATE_NEW_CONVERSATION_PLACEHOLDER_MEMBERS',
);
export const updateNewConversationPlaceholderDistributionLists = createAction(
  'UPDATE_NEW_CONVERSATION_PLACEHOLDER_DISTRIBUTION_LISTS',
);
export const updateNewConversationPlaceholderEntities = createAction(
  'UPDATE_NEW_CONVERSATION_PLACEHOLDER_ENTITIES',
);
export const destroyNewConversationPlaceholder = createAction(
  'DESTROY_NEW_CONVERSATION_PLACEHOLDER',
);
export const updateConversationsSearch = createAction(
  'UPDATE_CONVERSATIONS_SEARCH',
);

export const skipCheck = createAction('SKIP_CHECK');
export const openPlaceSwitcher = createAction('OPEN_PLACE_SWITCHER');
export const closePlaceSwitcher = createAction('CLOSE_PLACE_SWITCHER');
