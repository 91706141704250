import styled, { css } from 'styled-components';
import { Box, Stack } from './base';

export const Card = styled(Box)`
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.separator};
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: #fff;
`;

Card.defaultProps = {
  padding: 1,
  direction: 'column',
};

export const CardTitle = styled(Stack)`
  font-size: 1.189rem;
  font-weight: 600;

  ${({ variant }) =>
    variant === 'primary' &&
    css`
      color: ${({ theme }) => theme.primary};
    `}
`;

CardTitle.defaultProps = {
  as: 'h3',
  alignY: 'center',
  marginBottom: 0.25,
  marginTop: '0',
  gutterSize: 'tiny',
};
